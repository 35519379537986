import { apiBase, apiBasePublic } from 'api/api';
import {
    GetListOfParkingLotsResponse,
    GetConnectedUsersForParkingLotRequest,
    GetConnectedUsersForParkingLotResponse,
    GetListOfParkingsRequest,
    GetListOfParkingsResponse,
    GetParkingRequest,
    GetParkingLotResponse,
    GetParkingLotResponseBackoffice,
    GetParkinglotTicketRulesRequest,
    ParkingTicketRulesResponseV2,
    GetTicketRuleByIdRequest,
    GetTicketRuleResponse,
    GetParkinglotTicketRuleResponse,
    TicketRulesv2,
} from 'api/interface/parking';

const getListOfParkingLots = async (
    req: GetListOfParkingsRequest
): Promise<GetListOfParkingsResponse> => {
    const res = await apiBase.get(
        `/parkinglots?limit=${req.limit}&offset=${req.offset}&sort_order=${req.sort_order}&sort_col=${req.sort_col}&search_term=${req.search_term}&show_active=${req.show_active}&organisation_ids=${req.organisation_ids}`
    );

    if (res.status !== 200) {
        return {
            statusCode: res.status,
            error: 'bad request',
            payload: {
                total: 0,
                data: [],
            },
        };
    }

    return {
        statusCode: res.status,
        error: null,
        payload: {
            data: res.data.parking_lots,
            total: res.data.total,
        },
    } as GetListOfParkingsResponse;
};

const getParkingLot = async (
    req: GetParkingRequest
): Promise<GetParkingLotResponse> => {
    const res = await apiBase.get(`/parkinglot/${req.id}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.parking_lot,
    } as GetParkingLotResponseBackoffice;
};

const getParkingLotsForUserOrganisation =
    async (): Promise<GetListOfParkingLotsResponse> => {
        const res = await apiBase.get(`/organisation/parkingLots`);

        if (res.status !== 200) {
            return {
                error: 'bad request',
                statusCode: res.status,
                payload: [],
            };
        }
        return {
            error: null,
            statusCode: res.status,
            payload: res.data.parking_lot,
        } as GetListOfParkingLotsResponse;
    };

const getConnectedUsersForParkingLot = async (
    req: GetConnectedUsersForParkingLotRequest
): Promise<GetConnectedUsersForParkingLotResponse> => {
    const res = await apiBase.get(`/parkinglot/${req.id}/users`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.connected_users,
    };
};

const getParkinglotTicketRule = async (req: {
    id: string;
}): Promise<GetParkinglotTicketRuleResponse> => {
    const res = await apiBasePublic.get(`/public/parkinglot/${req.id}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.parking_lot,
    } as GetParkinglotTicketRuleResponse;
};

const getTicketRulesById = async (
    req: GetTicketRuleByIdRequest
): Promise<GetTicketRuleResponse> => {
    const res = await apiBase.get(`/v2/tickets/rules/${req.ID}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            data: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        data: res.data.data,
    } as GetTicketRuleResponse;
};

const getListOfTickets = async (
    req: GetParkinglotTicketRulesRequest
): Promise<ParkingTicketRulesResponseV2> => {
    const res = await apiBase.get(
        `/v2/tickets/rules?offset=${req.offset}&limit=${req.limit}&sort_col=${req.sort_col}&sort_order=${req.sort_order}&search_term=${req.search_term}&parkinglot_ids=${req.parkinglot_ids}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad requests',
            statusCode: res.status,
            data: [],
            total: 0,
        } as ParkingTicketRulesResponseV2;
    }

    const mappedData: ParkingTicketRulesResponseV2[] = res.data.data.map(
        (item: TicketRulesv2) => ({
            ticket: {
                id: item.ticket.id,
                url_id: item.ticket.url_id,
                type: item.ticket.type,
            },
            address: {
                address_name: item.address.address_name,
                city: item.address.city,
            },
        })
    );

    return {
        error: null,
        statusCode: res.status,
        data: mappedData,
        total: res.data.total,
    } as unknown as ParkingTicketRulesResponseV2;
};

export const frontofficeParkingApi = {
    getListOfParkingLots,
    getParkingLot,
    getParkingLotsForUserOrganisation,
    getConnectedUsersForParkingLot,
    getListOfTickets,
    getParkinglotTicketRule,
    getTicketRulesById,
};
