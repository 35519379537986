const norsk = {
    // Generic
    Update: 'Oppdater',
    Cancel: 'Avbryt',
    Create: 'Skape',
    Delete: 'Slett',
    Upload: 'Laste opp',
    Close: 'Lukk',
    Read: 'Lese',
    'Generate Report': 'Generer rapport',
    'Search the table': 'Søk i tabellen',
    Page: 'Side',
    of: 'av',
    Images: 'Bilder',
    Dates: 'Datoer',
    Date: 'Dato',
    Note: 'Merk',
    Status: 'Status',
    Country: 'Land',
    'Select all': 'Velg alle',
    Allow: 'Tillat',
    Business: 'Virksomhet',
    Search: 'Søk',
    More: 'Mer',
    Hide: 'Skjul',
    Other: 'Annet',
    Save: 'Lagre',
    Unknown: 'Ukjent',
    Choose: 'Velg',
    Website: 'Nettsted',
    more: 'mer',
    'Created date': 'Opprettet',
    AS: 'SOM',
    Return: 'Tilbake',
    Percentage: 'Prosent',
    Count: 'Antall',
    Extra: 'Ekstra',
    // User
    Username: 'Brukernavn',
    Password: 'Passord',
    'Created By': 'Opprettet Av',
    'Created by': 'Opprettet av',
    'Created Date': 'opprettet dato',
    'Last login web': 'Siste pålogging web',
    'Last login app': 'Siste pålogging app',
    Admin: 'Admin',

    // Menu
    Home: 'Hjem',
    Dashboard: 'Dashboard',
    'Parking Lots': 'Parkeringsplasser',
    'Parking lots': 'Parkeringsplasser',
    'Map of Parking Lots': 'Kart over parkeringsplasser',
    Users: 'Brukere',
    Permits: 'Tillatelser',
    Fines: 'Avgifter',
    Customers: 'CusKundertomers',
    Backoffice: 'Backoffice',
    Map: 'Kart',
    Statistics: 'Statistikk',
    Subscriptions: 'Abonnementer',
    'Ticket Service': 'Kjøp en P-billett',
    'Buy a permit': 'Kjøp en P-billett',
    'Get a permit': 'Få en P-billett',
    'Private Ticket Service': 'Få en p-billett',
    Reports: 'Rapporter',
    Rent: 'Leie',
    Prices: 'Priser',

    // Settings
    Settings: 'Innstillinger',
    'User Details': 'Brukerdetaljer',
    Language: 'Språk',
    'Parking Lot': 'Parkeringsplass',
    'Parking lot': 'Parkeringsplass',
    Street: 'Gate',
    'Street Number': 'Gatenummer',
    ZipCode: 'Post kode',
    City: 'By',
    'Notification settings': 'Varslingsinnstillinger',
    'WELCOME TO': 'VELKOMMEN TIL',

    // Login
    Login: 'Logg Inn',
    'Forgot Password': 'Glemt passord',
    Email: 'Email',
    'Reset Password': 'Tilbakestille passord',
    'Password reset email sent. Please check your email.':
        'Email for tilbakestilling av passord er sendt. Vennligst sjekk din email',
    'Something went wrong': 'Noe gikk galt',
    'Wrong username or password': 'Feil brukernavn eller passord',
    'Error resetting password, please retry':
        'Feil ved tilbakestilling av passord. Prøv på nytt',
    'Digital parking solution for every need':
        'Digital parkeringsløsning for alle behov',

    // Search bar
    'None elected': 'Ingen valgte',
    'All selected': 'Alle valgt',
    'Only active': 'Kun aktive',
    'Show cancelled': 'Vis kansellert',

    // Frontoffice
    'Latest fines': 'Nyeste avgift',
    'No data available!': 'Ingen data tilgjengelig!',
    'There were no datapoints to show on this graph':
        'Det var ingen datapunkter å vise på denne grafen',
    'Good fines': 'Gode ​​avgifter',
    Annulled: 'Annullert',
    'Could not fetch fines - please refresh and try again':
        'Kunne ikke hente avgifter - vennligst oppdatere og prøv igjen',
    'Something went wrong, could not find any users - Please refresh and try again.':
        'Noe gikk galt, kunne ikke finne noen brukere - vennligst oppdatere og prøv igjen.',
    Activated: 'Aktivert',
    Customer: 'Kunde',
    'User list': 'Brukerliste',
    'Create User': 'Opprett bruker',
    Yes: 'Ja',
    No: 'Nei',
    'Permit list': 'Tillatelses liste',
    'Create Permit': 'Skape tillatelse',
    'Start date': 'Startdato',
    'Start Date': 'Startdato',
    'End date': 'sluttdato',
    'End Date': 'sluttdato',
    Type: 'Type',
    'Could not find any parking lots - Please refresh and try again.':
        'Kunne ikke finne noen parkeringsplasser - vennligst oppdatere og prøv igjen.',
    'Parking Lots List': 'Parkeringsplass liste',
    Address: 'Adresse',
    'Parking Map': 'Parkeringsark Kart',
    'Could not find any hourly data - Please refresh and try again.':
        'Kunne ikke finne noen timevis data - vennligst oppdatere og prøv igjen.',
    'Something went wrong, could not find any daily data - Please refresh and try again.':
        'Noe gikk galt, kunne ikke finne noen daglige data - vennligst oppdatere og prøv igjen.',
    'Something went wrong, could not find any look up data - Please refresh and try again.':
        'Noe gikk galt, kunne ikke finne noen kikkdata - vennligst oppdatere og prøv igjen.',
    'Hourly visits count': 'Timebesøk teller',
    'Daily visits count': 'Daglige besøk teller',
    'Fine list': 'Avgiftsliste',
    'Could not generate the report - please refresh and try again':
        'Kunne ikke generere rapporten - vennligst oppdatere og prøv igjen',
    'Country code': 'Landskode',
    cancelFine: 'Hvorfor kansellerer du denne avgift? (min. 20 tegn)',
    Comments: 'Kommentarer',
    'Cancelled fines': 'Annulerte avgift',
    'Active fines': 'Aktive afgifter',
    'Total fines': 'Total fines',
    'Parking Lot list': 'Parkeringsplass liste',
    'Reg. nr.': 'Reg. nr.',
    'Your Cars': 'Dine biler',
    'Location Name': 'Stedsnavn',
    'Country and reg. no.': 'Country and reg. no.',

    // Backoffice
    'Customer list': 'Kundeliste',
    'Something went wrong in searching for customers':
        'Noe gikk galt i å søke etter kunder',
    'Create Customer': 'Skape kunde',
    Name: 'Navn',
    'Create Fine': 'Opprett Avgift',
    'Generate DMR report': 'Generer DMR-rapport',
    'Advanced Report': 'Avansert rapport',
    'Mass change': 'Masseendring',
    'Customers info': 'Kunder INFO',
    'Accounting info': 'Regnskapsinformasjon',
    'Create p-lot': 'Opprett p-lott',
    'Organization list': 'Organisasjonsliste',
    Add: 'Legg til',
    'Parking Lot Info': 'Parkeringsplass Info',
    'Add ruleset': 'Legg til regelsett',
    'Show Spots and Groups': 'Vis plasser og grupper',

    // Modals
    'Send Password': 'Send passord',
    'Settlement dates': 'Oppgjørsdatoer',
    Offence: 'Fornærmelse',
    Contractor: 'Håndverker',
    'Incident information': 'Hendelsesinformasjon',
    'Incident Information': 'Hendelsesinformasjon',
    'Leave a comment...': 'Legg igjen en kommentar...',
    'House.No': 'hus nr',
    'Street Name': 'Gatenavn',
    'Profile Image': 'Profilbilde',
    'Postal Code': 'postnummer',
    'Parking Lot Details': 'Parkeringsplass detaljer',
    'Parking Terms': 'Parkeringsvilkår',
    'Fine Price': 'bøtelegge pris',
    '% for PK': '% til OPARKO',
    Latitude: 'Breddegrad',
    'Loading...': 'Laster ...',
    'Could not find the parking lot you were looking for.':
        'Kunne ikke finne parkeringsplassen du lette etter.',
    'Successfully updated the parking lot!':
        'Vellykket oppdatert parkeringsplassen!',
    'Successfully created new parking lot!':
        'Vellykket opprettet ny parkeringsplass!',
    'You can now close the modal.': 'Du kan nå lukke modalen.',
    'Geographic coordinate': 'Geografisk koordinat',
    Longitude: 'Lengdegrad',
    'Camera Fines': 'Kameraavgifter',
    'Rules for parking': 'Regler for parkering',
    'Rules for guest parking': 'Regler for gjesteparkering',
    'Show customer': 'Vis kunde',
    'Number of active permits created by one user at the same time':
        'Antall aktive tillatelser lagret av én bruker samtidig',
    'Maximum length for created permits in app':
        'Maksimal lengde for opprettede tillatelser i ca',
    'Note for app': 'Merk for App.',
    'Control Type': 'Kontrolltype',
    'Camera control': 'Kamerakontroll',
    'Camera Control': 'Kamerakontroll',
    'Guard control': 'Vaktkontroll',
    'Guard Control': 'Vaktkontroll',
    'Self control': 'Selvkontroll',
    'Self Ticketing': 'Selvbillettering',
    'Need for collection': 'Behov for innhenting',
    'Parking Spots': 'Parkeringsboder',
    'Rent?': 'Leie?',
    Distribution: 'Fordeling',
    'Amount of Duty': 'Tollbeløp',
    'Why are you canceling this Fine? - Minimum 20 characters':
        'Hvorfor avbryter du dette avgift? - Minimum 20 tegn',
    'Send and Cancel Fine': 'Send og avbryt avgift',
    'Cancel Fine': 'Avbryt Avgift',
    'Could not find the fine you were looking for.':
        'Kunne ikke finne den avgift du lette etter.',
    'Could not find the permit you were looking for.':
        'Kunne ikke finne tillatelsen du lette etter.',
    'Belongs to': 'Tilhører',
    'Internal note': 'Internt notat',
    'External note': 'Eksternt notat',
    'Could not create the permit - close and try again please':
        'Kunne ikke skape tillatelsen - lukk og prøv igjen, vær så snill',
    'Could not update the permit - close and try again please':
        'Kunne ikke oppdatere tillatelsen - lukk og prøv igjen, vær så snill',
    'User successfully created, you can close the modal again':
        'Brukeren opprettet, du kan lukke modalen igjen',
    'User successfully updated, you can close the modal again':
        'Brukeren oppdateres, kan du lukke modalen igjen',
    'User credentials': 'Brukerens legitimasjon',
    'Confirm password': 'Bekreft passord',
    'The password matches': 'Passordet samsvarer',
    'The password does not matches': 'Passordet stemmer ikke overens',
    Permissions: 'Tillatelser',
    'Users to manage': 'Brukere å administrere',
    'Activate User': 'Aktiver Bruker',
    'Access to Website': 'Tilgang til Nettsiden',
    'Access to App': 'Tilgang til App',
    'Access to API': 'Tilgang til API',
    'Handle Fines': 'Administrer Avgifter',
    'Handle Permits': 'Administrer Tilladelser',
    User: 'Bruker',
    Permit: 'Tillate',
    Fine: 'Avgift',
    'Update customer': 'Oppdater kunden',
    'Customer Type': 'Kundetype',
    Created: 'Opprettet',
    Updated: 'Oppdatert',
    'Connected Users': 'Tilkoblede brukere',
    'Connected parking lots': 'Tilhørende parkeringsplasser',
    'Total amount of users:': 'Totalt antall brukere:',
    'Fine successfully updated, you can close the modal again':
        'Avgift oppdatert, du kan lukke modalen igjen',
    'Fine successfully created, you can close the modal again':
        'Avgift opprettet, du kan lukke modalen igjen',
    'Update Fine': 'Oppdater Avgift',
    'New Fine': 'Ny Avgift',
    Owner: 'Eieren',
    'Parking guard': 'Parkeringsvakt',
    'House number': 'Husnummer',
    'House nr.': 'Husnummer',
    'Open Parking Lot': 'Åpent parkeringsplass',
    'Update parking lot': 'Oppdater parkeringsplassen',
    'External Customers': 'Eksterne kunder',
    'Back to Fine': 'Tilbake til Avgift',
    'Update user': 'Oppdater brukeren',
    'Update User': 'Oppdater Bruker',
    'New user': 'Ny bruker',
    'Update subscription': 'Oppdater abonnement',
    'New subscription': 'Nytt abonnement',
    'Create subscription': 'Opprett abonnement',
    'Create Subscription': 'Opprett Abonnement',
    'Creating subscription...': 'Oppretter abonnement...',
    'Please select a company': 'Vennligst velg et selskap',
    "Customer's parking lots": 'Kundens parkeringsplasser',
    'User Identity': 'Brukeridentitet',
    Identity: 'Identitet',
    'User Identities': 'Brukeridentifikasjoner',
    'No identities for user': 'Ingen identiteter for brukeren',
    'Fine Details': 'Avgift Detaljer',
    'No one has commented or changed status yet.':
        'Ingen har kommentert eller endret status ennå.',
    'Leave a note on the incident': 'Legg igjen et notat på hendelsen',
    'Update Fines': 'Oppdater Avgifter',
    'This will reset the password and email the new password. Do you want to continue?':
        'Dadurch wird das Passwort zurückgesetzt und das neue Passwort per E-Mail gesendet. Möchtest du weiter machen?',
    'Parking Info': 'Parkeringsinfo',
    Organizations: 'Organisasjoner',
    Organization: 'Organisasjon',
    'Payout Details': 'Utbetalingsdetaljer',
    'Bank Registration Number': 'Bankregistreringsnummer',
    'Bank Account Number': 'Bankkontonummer',
    '% for parking guard': '% for parkeringsvakt',
    '% for customer': '% til kunde',
    'Charge Distributions': 'Avgiftsfordelinger',
    'There are no Parking Lots': 'Det er ingen parkeringsplasser',
    'There are no Users': 'Det er ingen brukere',
    'External Organizations': 'Eksterne organisasjoner',
    'Select image': 'Velg bilde',
    'Outside booth': 'Outside booth',
    'No permit to the parking area': 'Ingen tillatelse til parkeringsplassen',
    'P-disc exceeded': 'P-skive overskredet',
    'Parking or stopping in prohibited area':
        'Parkering eller stopp i forbudt område',
    'Parking in fire lane': 'Parkering på brannvei',
    'Handicap booth without permission': 'Handicapbod uten tillatelse',
    "Organization's Parking Lots": 'Organisasjonens parkeringsplasser',
    'Choose Parking bays': 'Velg Parkeringsplasser',
    'Permanent user': 'Permanent bruker',
    'New Permit': 'Ny tillatelse',
    'Update Permit': 'Oppdater Tillatelse',
    'Created for user': 'Opprettet for bruker',
    Notes: 'Notater',
    'Permit Information': 'Tillatelsesinformasjon',
    'Date not assigned': 'Dato ikke tildelt',
    'Note from creation': 'Notat fra opprettelsen',
    'Created at': 'Opprettet',
    'Updated at': 'Oppdatert',
    'Address name': 'Adressenavn',
    'Address Name': 'Adressenavn',
    'Arrival date': 'Ankomstdato',
    'Arrival Date': 'Ankomstdato',
    'Depart. Date': 'Avgangsdato',
    'Depart. date': 'Avgangsdato',
    Camera: 'Kamera',
    'Other - Leave a note': 'Annet - Lage et notat',
    'Parking time exceeded': 'Parkeringstid overskredet',
    'Total parking time': 'Samlet parkeringstid',
    Approve: 'Godkjenn',
    Decline: 'Avvis',
    Back: 'Tilbake',
    'Employee Paid Ticket': 'Employee Paid Ticket',
    Details: 'Detaljer',
    'Street name': 'Gatenavn',
    'Postal code': 'Postnummer',
    'Fine price': 'Avgiftspris',
    'No results found': 'Ingen resultater',
    'Buffer time in minutes': 'Buffertid i minutter',
    'Active permits': 'Aktive tillatelser',
    'Duration of permits': 'Varighet av tillatelser',
    selected: 'valgte',
    Distance: 'Avstand',
    'Organization type': 'Organisasjonstype',
    'Organization address': 'Organisasjon adresse',
    'Organization details': 'Organisasjonsdetaljer',
    'Create Organization': 'Opprett Organisasjon',
    'The permit will be made for every parking lot chosen, and based on the users start and end date.':
        'The permit will be made for every parking lot chosen, and based on the users start and end date.',
    'Comment added by': 'kommentar lagt til av',
    'Status changed from': 'Status endret fra',
    'Status changed by': 'Status endret av',
    'Not defined': 'Ikke definert',
    'Not Defined': 'Ikke definert',
    'Self ticketing': 'Selvbillettering',
    Combination: 'Kombinasjon',
    'P-guard': 'P-vakt',
    'Registered before': 'Tidligere registreringer',
    'You already have a user': 'Du har allerede en bruker',
    'Login to purchase an extra subscription':
        'Logg inn for å kjøpe et ekstra abonnement',
    'Missing payment in min': 'Manglende betaling i min',
    'Open Subscriber': 'Åpne abonnent',
    'Every permit row is clickable and show data in Permit Modal':
        'Hver tillatelsesrad er klikkbar og viser data i Permit Modal',
    'Choosing multiple parking lots creates a singular permit for each address selected':
        'Å velge flere parkeringsplasser skaper en enkelt tillatelse for hver adresse som er valgt',
    'These rules only affects the permits created from the app, not permits created by admin from Web':
        'Disse reglene påvirker kun tillatelsen opprettet fra appen, ikke fra opprettet av admin fra nettet',
    '0 = none and empty = unlimited': '0 = none and empty = unlimited',
    VAT: 'CVR',
    // Finance
    DKK: 'DKK',
    pcs: 'stk',
    k: 't',
    Revenue: 'Inntekter',
    'Paid Permits': 'Betalte tillatelser',
    'Coming soon': 'Coming soon',
    'Total revenue': 'Totale inntekter',
    'Total paid out': 'Totalt utbetalt',
    'Total unsettled': 'Samlet uoppgjort',
    'Permit revenue sources in DKK': 'Tillat inntektskilder i DKK',
    'Number of permits per parking operator':
        'Antall tillatelser per parkeringsoperatør',
    'Number of parking tickets made through each parking operator.':
        'Antall parkeringsbøter gjort gjennom hver parkeringsoperatør. ',
    'You have no permits to show': 'Du har ingen tillatelser å vise frem',
    'Revenue by type in DKK': 'Omsetning etter type i DKK',
    'Fines by status': 'Bøter etter status',
    'Post collections': 'Påfølgende samlinger',
    'Post collection': 'Påfølgende samlinger',

    'Active: status explained':
        'Aktiv: En bot som er opprettet, men fortsatt er under behandling',
    'Cancelled: status explained':
        'Kansellert: Den er avlyst, sjekk kommentaren eller notatet for hvorfor',
    'Paid: status explained': 'Betalt: Boten er betalt til Parkingskompagniet',
    'Settled: status explained': 'Avgjort: Boten er betalt til kunden',
    'Protected: status explained': 'Beskyttet: Den er midlertidig satt på vent',
    'Debt collection/Collectia: status explained':
        'Inkasso/Collectia: Gjeldsinndrivelsen pågår.',

    revenue_by_type_explanation:
        'Inntekter er basert på inntektene generert fra parkeringsplassene som organisasjonen din har eierskap til.',
    'Fines: Every fine that is paid or settled':
        'Bøter: Hver bot som betales eller gjøres opp',
    'Permits: Every permit that is paid':
        'Tillatelse: Hver tillatelse som betales',
    'Subscriptions: Every payment on all subscriptions':
        'Abonnement: Hver betaling på alle abonnementer',
    'Post Collection: Coming soon': 'Efteropkrævning: Kommer snart',

    permit_revenue_sources_explanation:
        'Inntektsbeløpet i kr, som er gjort på tillatelser for hver organisasjon.',
    number_of_permits_per_parking_operator_explained:
        'Antallet tillatelser som er utstedt av hver parkeringsoperatør.',

    fine_status_distribution_explanation:
        'Den prosentvise fordelingen av bøtestatuser.',

    // Statistic
    fee_search: 'Gammel App',
    fine_search: 'App - bot opprettet',
    permit_search: 'App - tillatelse opprettet',
    scanner_used: 'App - skanner brukt',
    'Shows a graph of app events for multiple days, sorted by hours.':
        'Viser en graf over apphendelser for flere dager, sortert etter timer.',
    'Shows a graph of app events, sorted by dates.':
        'Viser en graf over apphendelser, sortert etter datoer.',
    'The use of the old app.': '- Bruk av den gamle appen.',
    'Creation of permits in the oparko app.':
        '- Opprettelse av tillatelser i oparko-appen.',
    'Creation of Fines in the oparko app':
        '- Opprettelse av bøter i oparko-appen.',
    'Scanning of license plates in the oparko app.':
        '- Skanning av registreringsskilt i oparko-appen.',
    'Permits and Subscriptions per month':
        'Tillatelser og abonnementer per måned',
    'Occupancy by permits': 'Belastning etter tillatelser',
    'Total parking spots': 'Totalt antall parkeringsplasser',
    'Permits: Shows the amount of permits by period. If more than one week is chosen, the sum of all weeks will be shown on a give period on a weekday.':
        'Tillatelser: Viser antall tillatelser etter periode. Hvis mer enn én uke velges, vises summen av alle ukene for en gitt periode på en ukedag.',
    'Total parking spots: Shows the total amount of parking spots':
        'Totalt antall parkeringsplasser: Viser det totale antallet av parkeringsplasser',
    'Lowest avg for the days in the period.':
        '- Laveste gjennomsnitt for dagene i perioden.',
    'Highest avg for the days in the period.':
        '- Høyeste gjennomsnitt for dagene i perioden.',
    'Avg for the days in the period.': '- Gjennomsnitt for dagene i perioden.',
    'Average for the chosen period': 'Gjennomsnitt for valgt periode',
    // Date Picker
    Between: 'Mellom',
    From: 'Fra',
    To: 'Til',
    Unit: 'Enhet',
    Max: 'Maks antall',
    Min: 'Minimum antall',
    Prior: 'Før',
    Custom: 'Tilpass',
    Today: 'I dag',
    'Since Yesterday': 'Fra i går',
    'This Week': 'Denne uka',
    Anytime: 'Enhver tid',
    'Last month': 'Forrige md',
    'Last 3 months': 'Siste 3 mdr',
    'Last 12 months': 'Siste 12 md',
    Ticket_Rule: 'Billettregel',
    Rule_sets: 'Regelsett',
    InformationGuide: 'Informasjonsguide',
    RuleSetsDescription:
        "Under 'Regelsett' kan du definere unike billettregler. Du kan lage så mange du trenger:",
    PickDays: 'Velg hvilke dager reglene gjelder for',
    PickTime: 'Velg tidspunkt på dagen',
    PickUnit:
        'Velg enheten etterfulgt av “minimum” og “maksimum” antall timer/dager som kan kjøpes med denne regelen',

    // Table Values
    company: 'selskap',
    private: 'privat',
    Inactive: 'Inaktiv',
    Active: 'Aktiv',
    Paid: 'Betalt',
    Settled: 'Avgjort',
    Hidden: 'Kansellert',
    Protected: 'Beskyttet',
    'Guest parking': 'Gjesteparkering',
    Ticket: 'Billett',
    'Paid Ticket': 'Betalt billett',
    'Only for backoffice': 'Kun for backoffice',
    'Editable permit': 'Redigerbar tillatelse',
    Employee: 'Ansatt',
    'Permanent permit': 'Permanent tillatelse',
    'Debt collection': 'Inkasso',
    Collectia: 'Collectia',
    'Active - handled manually': 'Aktiv - håndteres manuelt',
    'Active - System': 'Aktiv - System',

    // Validation
    'Issues: ': `Problemer: `,

    'no Name chosen, ': 'ingen Navn valgt, ',
    'no Organization chosen, ': 'ingen Organisasjon valgt, ',
    'no Street Name chosen, ': 'ingen Gatenavn valgt, ',
    'no Street Number chosen, ': 'ingen Gatenummer valgt, ',
    'no Postal Code chosen, ': 'ingen Postnummer valgt, ',
    'no City chosen, ': 'ingen By valgt, ',
    'no Latitude chosen, ': 'ingen Breddegrad valgt, ',
    'Latitude must be above -90, ': 'Breddegrad må være over -90, ',
    'Latitude must be below 90, ': 'Breddegrad må være under 90, ',
    'no Longitude chosen, ': 'ingen Lengdegrad valgt, ',
    'Longitude must be above -90, ': 'Lengdegrad må være over -90, ',
    'Longitude must be below 90, ': 'Lengdegraden må være under 90, ',
    'no type chosen, ': 'ingen Type valgt, ',
    'parking terms do not accept more than 255 character':
        'parkeringsvilkår aksepterer ikke mer enn 255 tegn',

    Reset: 'Nullstille',
    'Accept our terms': 'Godta våre vilkår',
    'Enter your registration no.': 'Skriv inn registreringsnr.',
    'phone number': 'telefonnummer',
    email: 'e-post',
    'then press ok': 'trykk deretter ok',
    'Your permit ends': 'Tillatelsen din avsluttes',
    'by today 23:59': 'innen i dag 23:59',
    after: 'etter',
    'minutes from the create Parking': 'minutter fra opprette parkering',
    'Reg. no.': 'Reg. nr.',
    'Phone number': 'Telefonnummer',
    Subscription_percentage_not_within_range: `Abonnementet '% for OPARKO' må være innenfor området 0% til 100%`,
    Permit_percentage_not_within_range: `Tillate '% for OPARKO' må være innenfor området 0% til 100%`,
    Camera_control_percentage_not_within_range: `Kamerakontroll '% for OPARKO' må være innenfor området 0% til 100%`,
    Selfticketing_percentage_not_within_range: `Selvbillettering '% for OPARKO' må være innenfor området 0% til 100%`,
    Guard_control_percentage_not_within_range: `Vaktkontroll '% for OPARKO' må være innenfor området 0% til 100%`,

    // Ticket Service validation
    'must be a positive integer': 'må være et positivt heltall',
    'Reg. no. have not been specified': 'Reg. nr. er ikke spesifisert',
    'E-mail has not been specified': 'E-mail er ikke angitt',
    'Terms and conditions must be accepted':
        'Vilkår og betingelser må aksepteres',
    Issues: 'Problemer',

    // Terms
    Terms_titles: 'Vilkår og betingelser',
    Terms_para_1:
        'Vi lagrer og overfører brukerinformasjon kryptert. De oppgitte personopplysningene registreres som beskrevet i vår datapolicy. Ved å akseptere disse vilkårene godtar du også å være kjent med og å ha akseptert disse vilkårene for bruk av Parkeringskompagniets produkter. For spesielle regler om Oparko abonnementsparkering, se nedenfor under "Oparko Abonnementsparkering". Parkeringskompagniet kan samle inn informasjon og utarbeide statistikk og rapporter for å forbedre og levere det beste produktet og tjenesten til Brukeren.',
    Terms_main_point_1: {
        main_title: 'Applikasjon',
        sub_point_1:
            'Disse generelle vilkårene og betingelsene ("Generelle vilkår og betingelser") gjelder levering av tjenester fra Parkeringskompagniet ApS, CVR-nr. 37127385, Trindsøvej 4, 8200 Aarhus, ("Parkeringskompagniet"), til brukere ("Bruker") i Danmark .',
        sub_point_2:
            'MERK! Å bruke parkeringsselskapstjenestene krever at du er registrert eier eller bruker av kjøretøyet eller at du ellers er autorisert til å bruke og registreres kjøretøyet.',
        sub_point_3: {
            sub_point_3_title:
                'Alle tjenester leveres i samsvar med gjeldende lov og i samsvar med:',
            sub_point_3_1:
                'eventuelle individuelt avtalte vilkår og betingelser, og',
            sub_point_3_2: 'disse generelle vilkårene.',
            sub_point_3_3:
                'Ved uoverensstemmelser mellom ovennevnte bestemmelser har de prioritet i ovennevnte rekkefølge',
        },
        sub_point_4:
            'Paragraf 5.1.4, 6.4 og 7.7 inneholder spesielle bestemmelser om riktig og gyldig oppføring av registreringsnummer og retningsnummer.',
        sub_point_5:
            'Punkt 5.3. inneholder spesielle bestemmelser om bruk av Oparko-avgiften.',
        sub_point_6:
            'Ved å registrere seg hos Oparko (se punkt 4), godtar brukeren å være underlagt disse generelle vilkårene. En bindende avtale («Avtalen») inngås når Parkeringskompagniet har bekreftet Brukerens registrering og Brukeren får tilgang til Parkeringskompagniets Tjenester.',
        sub_point_7:
            'Disse generelle vilkårene utgjør avtalen mellom parkeringsselskapet og brukeren.',
        sub_point_8:
            'Disse generelle vilkårene gjelder for alle transaksjoner og registreringer via Parkeringskompagniet, inkludert *service2* og Oparko Abonnementparkering.',
    },
    Terms_main_point_2: {
        main_title: 'Definisjoner',
        sub_point_1: {
            sub_point_1_title:
                'I disse generelle vilkårene og i forbindelse med tjenestene gjelder følgende definisjoner:',
            sub_point_1_1:
                'Med «Oparko» menes den digitale parkeringstjenesten som kan benyttes i forbindelse med betaling for parkering, via Appen og nettsiden;',
            sub_point_1_2:
                '"App" betyr parkeringsselskapets mobiltelefonapplikasjon;',
            sub_point_1_3:
                '"Oparchy System" er Oparko Digital System for moderne parkering som brukeren får tilgang til via, appen og/eller via nettstedet;',
            sub_point_1_4:
                '«Hjem» viser til Parkeringskompagniets nettside <a class="text-pk-blue" href="https://parkeringskompagniet.dk/">https://parkeringskompagniet.dk/</a>.',
            sub_point_1_5:
                '"Parkeringstjeneste" er brukerens mulighet til å administrere parkeringsplasser i appen;',
            sub_point_1_6:
                'En «Parkeringsplass» er et fysisk område som samsvarer med en parkeringsplass merket på oversiktskartet (parkeringsplass betyr ikke en merket parkeringsbod);',
            sub_point_1_7:
                '"Parter" og "Part" betyr parkeringsselskapet og/eller brukeren;',
            sub_point_1_8:
                '"Bruker" brukes i disse generelle vilkårene for Parkerant som bruker parkeringsselskapet i forbindelse med betaling for parkering.',
            sub_point_1_9:
                '"Partner" betyr partnere som Parkeringskompagniet samarbeider med;',
            sub_point_1_10:
                '"Tjeneste(r)" har betydningen angitt i punkt 3.1.1.',
            sub_point_1_11:
                '"Parkeringsavgiften" er prisen på parkering som er utført.',
            sub_point_1_12:
                'Med «aktivt kjøretøy» menes et kjøretøy som brukeren har valgt automatisk å betale for i forbindelse med parkering på såkalte ANPR-parkeringsplasser. Betaling skjer via betalingsmåten som brukeren har angitt som foretrukket.',
            sub_point_1_13:
                '«ANPR» står for Automatic Number Plate Registration, som betyr at kjøretøyets nummerskilt avleses automatisk og registreringsnummeret registreres i parkeringssystemet. Parkeringsplasser med ANPR-anlegg er merket med kamera i Oparko og det fremgår tydelig av skiltingen på parkeringsplassen at nummerskiltet kan leses.',
            sub_point_1_14:
                'Med «Oparko Charge» menes Parkeringskompagniets egne og tilhørende systemer og apper fra 3. part som kan benyttes i forbindelse med lading av el- og hybridbiler. Dette er både ladestasjoner, betalingssystemer og apper.',
        },
        sub_point_2:
            'Definisjoner kan gis andre steder i disse generelle vilkårene.',
    },
    Terms_main_point_3: {
        main_title: 'Generelle vilkår og betingelser for tjenestene',
        sub_point_1: {
            sub_point_1_title: 'Generelt',
            sub_point_1_1:
                'Parkeringskompagniet tilbyr et digitalt system for parkering ("Oparko"), der Parkeringskompagniet, (i samarbeid med andre Partnere,) gjør det mulig for brukeren å administrere parkering av kjøretøy ("Parkeringstjeneste") og lading av elektriske kjøretøy. Parkeringstjenesten og de andre tjenestene som til enhver tid tilbys av Parkeringskompagniet omtales samlet som "tjenestene".',
        },
        sub_point_2:
            'For en detaljert beskrivelse av tjenestene, vennligst se informasjonen som til enhver tid er gitt på nettstedet og <a href="https://parkeringskompagniet.dk/" class="text-pk-blue">https://parkeringskompagniet.dk/.</a>',
    },
    Terms_main_point_4_new: {
        main_title: 'Innsamling og behandling av personopplysninger',
        sub_point_1: {
            sub_point_title: 'Innsamling og behandling av personopplysninger',
            sub_point_1_1:
                'Når Brukeren benytter Parkeringskompagniets tjenester, samtykker Brukeren samtidig til at Parkeringskompagniet samler inn og behandler personopplysningene og personopplysningene oppgitt av Brukeren i samsvar med Parkeringskompagniets gjeldende datapolicy. (<a href="https://parkeringskompagniet.dk/datapolitik/" class="text-pk-blue">https://parkeringskompagniet.dk/datapolitik/</a>).',
            sub_point_1_2:
                'Personopplysninger lagres kun dersom de er frivillig gitt og kun så lenge det er relevant for Parkeringskompagniet. De aktuelle dataene lagres på Parkeringskompagniets interne nettverk og kan kun nås med spesielle rettigheter.',
        },
        sub_point_2: {
            sub_point_title: 'Brukerrettigheter',
            sub_point_2_1:
                'Brukeren har rett til å få innsyn i personopplysningene som er lagret om ham av Parkeringskompagniet, og til å få rettet de aktuelle opplysningene.',
            sub_point_2_2:
                'Bruker har rett til å få slettet sine opplysninger fra Parkeringskompagniets systemer.',
            sub_point_2_3:
                'Brukeren har også rettighetene gitt til enkeltpersoner av EUs generelle databeskyttelsesforordning.',
        },
        sub_point_3: {
            sub_point_title: 'Brukerens plikter',
            sub_point_3_1:
                'Når brukeren gir opplysninger om noen andre, garanterer brukeren at denne personen har rett til å gi opplysningene til Parkeringskompagniet.',
        },
        sub_point_4: {
            sub_point_title: 'Overføring av data',
            sub_point_4_1:
                'Brukeren aksepterer at dersom Parkeringsselskapet anser det som nødvendig å flytte brukerens informasjon til en annen plattform, opprettet med samme formål som inneholdt i denne avtalen, vil dette skje uten forvarsel og i samsvar med gjeldende databeskyttelseslovgivning.',
        },
        sub_point_5: {
            sub_point_title: 'Utlevering av informasjon',
            sub_point_5_1:
                'Brukerens opplysninger videreformidles kun dersom det er nødvendig for tjenesten som tilbys av Parkeringskompagniet.',
        },
    },
    Terms_main_point_5_new: {
        main_title: 'Spesielle vilkår og betingelser for bruk av Oparko',
        sub_point_1: {
            sub_point_1_title: 'Vilkår for bruk',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Parkeringstjenesten kan bare brukes på parkeringsplasser og i parkeringsområder/soner (separat en "parkeringsplass"), for eksempel:',
                sub_point_1_1_1:
                    'hvor Parkeringskompagniets skilting er oppsatt,',
                sub_point_1_1_2:
                    'og har salgsautomater med Oparko-etiketter, eller på annen måte bærer Oparko-merker som indikerer at Oparko kan brukes.',
            },
            sub_point_1_2:
                'Når Parkeringstjenesten benyttes, må Brukeren starte en parkering ved å aktivere enten; Appen; eller et tilknyttet og godkjent betalingssystem',
            sub_point_1_3:
                'For å sikre at parkering har startet riktig, må Bruker kontrollere at det er mottatt bekreftelse på riktig og gyldig påbegynt parkering pr.',
            sub_point_1_3_1: 'appen, hvis appen brukes; eller',
            sub_point_1_3_2: 'E-postbekreftelse',
            sub_point_1_4:
                'En parkering er bare gyldig hvis appen har bekreftet parkeringen og viser en pågående riktig registrert parkering/betaling.',
            sub_point_1_5:
                'Når brukeren bruker parkeringstjenesten, må brukeren, i forbindelse med dette, spesifisere det parkerte kjøretøyets registreringsnummer og retningsnummeret på den aktuelle parkeringsplassen.',
            sub_point_1_6:
                'Det er Brukerens ansvar at kjøretøyets registreringsnummer og retningsnummer angis riktig. Feil ved påmelding og påmelding vil medføre kontrollgebyr.',
            sub_point_1_7:
                'Bruker skal sørge for at retningsnummeret tilsvarer retningsnummeret til den aktuelle parkeringsplassen eller E-ladestanderen, som fremgår av Oparko-skiltingen på parkeringsplassen eller i nærheten av Parkeringsplassens maskiner/E-ladestander. Brukeren er ansvarlig for å taste inn riktig retningsnummer og registreringsnummer. Oparko påtar seg intet ansvar i forbindelse med feil oppgitte registreringsnummer og/eller retningsnummer.',
            sub_point_1_8:
                'Ved parkering på parkeringsplasser hvor kjøretøyets nummerskilt avleses, skal retningsnummer ikke tastes inn. Registreringsnummeret som brukes til parkering må angis riktig.',
            sub_point_1_9:
                'Det er et gyldighetsvilkår for parkeringsregistreringen at registreringsnummer og retningsnummer er korrekt oppgitt.',
            sub_point_1_10: {
                sub_point_1_10_title:
                    'Registrering av parkering i Oparko kan gjøres på en av følgende måter:',
                sub_point_1_10_1:
                    'Ved manuell registrering skal Bruker oppgi ønsket parkeringstid, og vil i den forbindelse bli informert om pris for ønsket parkering. Ved Brukerens bekreftelse av innført registrering reserveres oppgitt beløp. Når Bruker stopper parkeringen, endres det reserverte beløpet slik at kun registrert parkeringstid betales.',
                sub_point_1_10_2: {
                    sub_point_1_10_2_title:
                        'Ved automatisk registrering (ved parkering på ANPR-plasser) må Bruker ha aktivert kjøretøyet under "kjøretøy" før parkering. Ved parkering på ANPR parkeringsplass starter parkeringen ved inngangen og slutter og avregnes ved utkjørselen. Det betales for brukt parkeringstid.',
                    sub_point_1_10_2_1:
                        'Når brukeren aktiverer kjøretøyet, godtar brukeren at automatisk betaling vil skje via den foretrukne betalingsmetoden ved parkering på en ANPR-parkeringsplass.',
                },
            },
            sub_point_1_11:
                'Oparko kan ikke holdes ansvarlig for nøyaktigheten av brukerens telefons GPS-posisjon, og Oparko kan heller ikke holdes ansvarlig for mobildekning/signal på/på parkeringsplassen. Brukeren er ansvarlig for den angitte posisjonen som tilsvarer posisjonen til brukerens parkerte kjøretøy.',
            sub_point_1_12:
                'Informasjon om parkering gjort av Bruker vil være tilgjengelig for Bruker via "Parkering", og slik informasjon vil generelt være tilgjengelig i minst 12 måneder.',
        },
        sub_point_2: {
            sub_point_1_title: 'Generelt',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Parkeringstjenesten gjør det mulig for Bruker å starte en parkering, og ved manuell oppstart angi foreløpig parkeringstid, stoppe og avslutte påbegynt parkering. Videre er det mulig for Bruker å forlenge den foreløpig angitte parkeringstiden.',
                sub_point_1_2_1:
                    'Påminnelse: Det er mulig å få en påminnelse før den manuelt initierte parkeringen utløper, forutsatt at pushvarslingstillatelser er gitt i appen. Det er brukers ansvar å sørge for at parkering gjennomføres på riktig måte, uavhengig av om påminnelsen ikke fremkommer.',
                sub_point_1_2_2: {
                    sub_point_1_2_2_title:
                        'Stopp parkering: Å forlate parkeringsplassen før den valgte parkeringstiden utløper, er det mulig å aktivt stoppe den pågående parkering, og dermed betale bare for den perioden parkeringen har vært aktiv. Brukeren henvises til alltid å forholde seg til parkeringsprisen som vises i skiltingen og/eller minibanken. Avhengig av prisstrukturen på parkeringsplassen, vil det være mulig på de fleste parkeringsplasser å stoppe parkeringen slik at det ikke blir betalt noen "ubrukt tid". NB! Noen parkeringsplasser krever betaling per. startet tidsperiode og muligens På forhånd, noe som betyr at i tilfelle slik parkering blir stoppet, belastes mengden godkjent beløp uavhengig av stopp. Hvis parkeringen ikke blir stoppet, vil ingen tilbakebetaling av betaling for "overskytende tid" være mulig.',
                    sub_point_1_2_2_1:
                        'På et overtredelse må det forstås, perioden hvor det er gyldig registrering av parkering på parkeringsplassen, men der kjøretøyet ikke har vært på parkeringsplassen.',
                },
                sub_point_1_2_3:
                    'Forlengelse: Forlengelse av en parkering gjøres ved å trykke "forleng" og deretter oppgi ny sluttid for parkeringen, så godkjennes beløpet. Etter dette vil nedtellingen av parkeringsperioden for kjøretøyet oppdateres basert på forlenget parkeringstid. Forlengelse innebærer at ny parkering igangsettes når den opprinnelige parkeringen utløper.',
                sub_point_1_2_4:
                    'Dersom det ikke er mulig å foreta et kjøp på et gitt tidspunkt ved bruk av Oparko-programvaren, er Bruker ansvarlig for å betale for parkeringen på annen måte, f.eks. ved å kjøpe gyldig P-billett i betalingsautomat og ellers overholde oppslåtte regler og vilkår for parkering. Årsaker til at Oparko ikke kan brukes inkluderer, men er ikke begrenset til, for eksempel: dårlig/ingen internettilgang, servernedetid på grunn av vedlikehold, oppdateringer osv.',
            },
            sub_point_1_2:
                'Hvis brukeren ikke var føreren av kjøretøyet ved parkeringstidspunktet, men brukeren har angitt kjøretøyet som aktivt, har brukeren akseptert at betalingen skjer automatisk via den foretrukne betalingsmåten. Det interne forholdet mellom brukeren og enhver tredjepart er utenfor Oparkos kontroll.',
            sub_point_1_3: {
                sub_point_1_3_title:
                    'Dersom det pålegges kontrollgebyr for det parkerte kjøretøyet, for parkering i strid med de regler og vilkår som fremgår av skiltingen på parkeringsplassen, er føreren ansvarlig for betaling av slik kontrollavgift, uavhengig av hvem som har betalt for parkeringen. .',
                sub_point_1_3_1:
                    '«Kontrollgebyr» brukes i denne sammenheng som en samlebetegnelse for hhv kontrollgebyr, kontrollgebyr, parkeringsbot mm.',
            },
            sub_point_1_4:
                'For det tilfelle at parkeringen utføres med kjøretøy hvor registrert eier/bruker er et selskap, og kjøretøyet er belagt med kontrollavgift, er det Brukers ansvar å i tilstrekkelig grad dokumentere at utført parkering ikke er utført for næring. formål. Dersom Bruker ikke kan ivareta denne bevisbyrden, anses registrert eier/bruker som riktig debitor og ved forsinket betaling vil det i tillegg til eventuelle purringer bli belastet et erstatningsbeløp på DKK 310,00 pr. i henhold til krav 31.01.2013 nr. 105 § 2, stk. 1.',
            sub_point_1_5:
                'Hvis brukeren ikke var føreren av kjøretøyet på parkeringstidspunktet, samtykker brukeren herved til å måtte opplyse hvem føreren var. Ved mangelfulle eller uriktige opplysninger skal kontrollavgiften, samt evt purregebyrer i sin helhet holdes mot brukeren.',
            sub_point_1_6:
                'Brukerens betaling av den aktuelle parkeringsavgiften (inkludert mva) fastsettes ut fra gjeldende satser ("Parkeringsavgiften") i forbindelse med at Bruker avslutter en parkeringsplass, alternativt starter en fast parkeringstid gjennom Parkeringstjenesten.',
            sub_point_1_7:
                'Bruker kan ikke fremsette krav om reduksjon av kontrollgebyret på grunnlag av gjennomført betaling i Oparko. Dette vilkåret gjelder uavhengig av om kontrollavgiften ilegges på bakgrunn av Brukerens feilregistrering i Oparko eller Brukerens parkering i strid med de øvrige oppslåtte regler og vilkår på parkeringsplassen.',
            sub_point_1_8:
                'Brukeren har heller ikke krav på refusjon av betaling foretatt via Oparko, med mindre belastningen skyldes en feil fra Oparkos side, som Brukeren ikke hadde eller kunne påvirke.',
        },
        sub_point_3: {
            sub_point_1_title: '5.3. Oparko-ladningsbetingelser',
            sub_point_1_1:
                'Betaling for kjøretøylading kan gjøres gjennom apper i forhold til ladestasjonene som er satt opp på siden. Betaling for parkering skal skje separat gjennom Oparko (se punkt 5).',
            sub_point_1_2:
                'MERK: Ved parkering i parkeringsplasser reservert for elbiler under lading, må ladingen registreres i appen som brukes før parkering og lading starter. I tillegg skal parkering registreres i Oparko-appen, som spesifisert i punkt 5.',
            sub_point_1_3:
                'Appen som brukes må brukes til å betale for lading via installerte ladestasjoner.',
            sub_point_1_4:
                'Ved bruk av Oparko i forbindelse med bruk av installert ladestativ gir Bruker melding til Parkeringskompagniet om at lading av parkert kjøretøy er påbegynt, foreløpig ladetid, fullføring av lading og om nødvendig om forlenget ladetid.',
            sub_point_1_6: {
                sub_point_1_6_title:
                    'Ved betaling og bruk av installert ladestativ betales et ladegebyr.',
                sub_point_1_6_1: 'Beløpsavgiften er variabel.',
                sub_point_1_6_2:
                    'Betaling for ladegebyret gjøres separat og kravet fremkommer i appen som brukes før registrering og betaling gjennomføres.',
            },
            sub_point_1_7:
                'Ved registrering er de andre vilkårene og betingelsene for bruk av Oparko -appen også aktuelt, inkludert den riktige og gyldige registrering og betaling krever riktig oppføring av retningsnummer og det parkerte kjøretøyets registreringsnummer. Det er brukerens ansvar at informasjonen som er lagt inn er riktig og kontrollerbar og at betaling kan skje.',
            sub_point_1_8: {
                sub_point_1_8_title:
                    'Områdekoden som må brukes i forbindelse med betaling for lading og parkering vises i skiltingen som er satt av boder, som er forbeholdt elektriske kjøretøyer. Det er brukerens ansvar at riktig retningsnummer legges inn.',
                sub_point_1_8_1:
                    'Når du betaler til retningsnummeret tilknyttet oparko-ladingstativer, men uten en parkeringsbukt som er reservert for Oparko-lading, vil ingen innskudd bli refundert.',
            },
            sub_point_1_9: {
                sub_point_1_9_title:
                    'Det er til enhver tid brukers ansvar å følge de skiltede regler og forhold som fremkommer på parkeringsplassen. Ved uoverensstemmelser mellom skiltingen og disse vilkårene har ordlyden i skiltingen forrang. Dersom parkering ikke gjennomføres i henhold til oppslåtte regler og vilkår, kan det pålegges kontrollgebyr, som ikke senere kan kanselleres.',
                sub_point_1_9_1:
                    'Parkering i boder forbeholdt elbiler kan kun gjøres mot betaling og kjøretøyet skal belastes for hele perioden parkeringsboden er i bruk. Dersom kjøretøyet ikke blir belastet og/eller parkeringen ikke er registrert i Oparko, vil det bli pålagt en kontrollavgift som ikke kan kanselleres.',
                sub_point_1_9_2:
                    'Hvis det er problemer i forbindelse med bruk av oparko-lading av ladestativ, må brukeren kontakte parkeringsselskapet på telefonnummeret som er angitt på ladestativet.',
            },
            sub_point_1_10:
                'Informasjon som Bruker har registrert i forbindelse med registrering og betaling skjer på samme måte som nevnt i pkt 4.',
        },
    },
    Terms_main_point_6: {
        main_title: 'Tilgang til tjenestene, registrering av informasjon mv.',
        main_text:
            'Tilgang til Tjenestene, opprettelse og betaling for parkering via Oparko kan gjøres ved at Brukeren opprettes som bruker. Bruk av Oparko krever at et gyldig betalingsmiddel er knyttet til brukerens Oparko-app. Betalingsmidlene som brukeren gyldig kan tilknytte som betalingsmiddel er; Bankkort Det er en betingelse for gyldighet at det er dekning på tilhørende og utvalgte betalingsmidler.',
        sub_point_1: {
            sub_point_1_title:
                'Ved opprettelse og senere bruk av Oparko er det en forutsetning at brukeren oppgir korrekt og gyldig informasjon:',
            sub_point_1_1: {
                sub_point_1_1_1: 'Navn',
                sub_point_1_1_2: 'Epostadresse',
                sub_point_1_1_3: 'Mobilnummer',
                sub_point_1_1_4: 'Passord',
                sub_point_1_1_5: 'Registreringsnummer, på minst ett kjøretøy',
                sub_point_1_1_6: {
                    sub_point_1_1_6_title: 'Betalingsmåter',
                    sub_point_1_1_6_1: {
                        sub_point_1_1_6_1_title:
                            'Grupper av ansatte med tilgang til den registrerte informasjonen:',
                        sub_point_1_1_6_1_1:
                            'Underleverandører (mens de utfører support)',
                        sub_point_1_1_6_1_2:
                            'Parkeringskompagniets ansatte (mens de yter støtte i forbindelse med brukerhenvendelser)',
                    },
                },
            },
        },
        sub_point_2:
            'En parkering igangsettes via App eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>',
        sub_point_3: {
            sub_point_3_title:
                'For å bruke Oparko må en gyldig betalingsmetode være tilkoblet og valgt i appen eller på <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a> før parkeringen registreres.',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'OBS: Det er ikke mulig å endre betalingsmiddel i forbindelse med en pågående parkering, hvorfor det er Brukers ansvar at riktig og gyldig betalingsmiddel velges før parkering.',
            },
        },
        sub_point_4: {
            sub_point_4_title:
                'Instruksjonene som vises på skjermen må følges:',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'For at Bruker skal bruke Oparko til å betale for parkering er det et gyldighetsvilkår at Bruker registrerer korrekt og gyldig registreringsnummer (nummerskilt), retningsnummer, betalingsmiddel og parkeringstid.',
                sub_point_1_1_2:
                    'Det skal kontrolleres at registrert registreringsnummer og retningsnummer er korrekt oppgitt av Bruker.',
                sub_point_1_1_3:
                    'Oparko forbeholder seg retten til å avslå søknad om opprettelse og betaling for parkering.',
                sub_point_1_1_4:
                    'Det presiseres at det kun er foretatt gyldig betaling for parkeringen dersom registreringsnummeret til kjøretøyet som parkeres er korrekt oppgitt og at oppgitt retningsnummer samsvarer med retningsnummeret som fremgår av Oparko-skiltet gjeldende for den aktuelle parkeringsplassen. , hvor kjøretøyet befinner seg.',
            },
        },
        sub_point_5:
            'Dersom parkeringen ikke er gyldig registrert, risikerer kjøretøyet å bli belastet med kontrollavgift.',
        sub_point_6:
            'Ved vellykket kjøp/reservasjon sendes en bekreftelse via e-post til e-postadressen oppgitt av brukeren.',
        sub_point_7:
            'Ved kjøp gjennom Oparko stilles en uspesifisert parkeringsbod til disposisjon på valgt parkeringsplass/parkeringssted og i valgt periode. Parkeringskompagniet kan ikke holdes ansvarlig dersom det ikke er ledige parkeringsplasser (da dette ikke er en bestilling). Parkering bør derfor ikke kjøpes før kjøretøyet er forsvarlig parkert på parkeringsplassen.',
        sub_point_8:
            'Bruker har ikke krav på refusjon av ubrukt parkering. Se punkt 5.2.1.2.',
    },

    Terms_main_point_7: {
        main_title: 'Brukerens forpliktelser og ansvar',
        sub_point_1: {
            sub_point_1_1: `Bruker er til enhver tid ansvarlig for å gi korrekt informasjon om Bruker og at aktuelle kjøretøy er korrekt registrert hos Oparko. Brukeren må logge på "Min konto" umiddelbart etter opprettelse/registrering for å sikre at den registrerte informasjonen er korrekt. Oparko kan aldri være eller bli ansvarlig for feilregistreringer/innføringer, uavhengig av registreringsmetode.`,
            sub_point_1_2: `Bruker er ansvarlig for løpende å sikre at betalingsmåten som registreres og brukes via Oparko ikke er sperret, er gyldig og at det er tilstrekkelig dekning på registrert betalingsmåte. Ved utløp av et registrert betalingsmiddel må Brukeren gi Oparko informasjon (ved å oppdatere Appen under "betalingsmetoder") om et nytt betalingsmiddel. Opplysningene om nytt betalingsmiddel skal gis senest ved utgangen av kalendermåneden før den kalendermåneden det registrerte betalingsmidlet ikke lenger er brukbart.`,
            sub_point_1_3: `Sikker og forsvarlig lagring av påloggingsinformasjonen er brukerens ansvar.`,
            sub_point_1_4: `Videre er det Brukerens ansvar at påloggingsinformasjonen ikke videreformidles eller på annen måte gjøres tilgjengelig for uvedkommende.`,
            sub_point_1_5: {
                sub_point_1_5_title: `Det er utelukkende brukerens ansvar at brukerens Oparko-konto brukes og kun brukes av de rette personene.`,
                sub_point_1_5_1: {
                    sub_point_1_5_1_title: `Brukeren må umiddelbart informere Oparko (f.eks. ved å kontakte Oparko kundeservice eller ved å oppdatere informasjonen i "Min konto") hvis:`,
                    sub_point_1_5_1_1: `Brukeren har grunn til å tro at en uautorisert person har fått tilgang til eller kjennskap til påloggingsinformasjonen;`,
                    sub_point_1_5_1_2: `registrert informasjon om brukeren er endret og/eller bør oppdateres; eller`,
                    sub_point_1_5_1_3: `Brukeren har ikke lenger registrert kjøretøy eller må ikke lenger betale for parkering med registrert kjøretøy,`,
                },
                sub_point_1_5_2: `Informasjonen må i utgangspunktet gis ved at Bruker selv oppdaterer informasjonen i Oparko. Det er Brukerens ansvar at det ikke registreres kjøretøy som Brukeren ikke må betale for. Oparko kan dermed ta nødvendige forholdsregler, for eksempel å endre/blokkere påloggingsinformasjonen og/eller brukerens konto eller be brukeren oppdatere brukerens registrerte informasjon. Brukeren er også forpliktet til å informere Oparko om andre forhold av betydning for Avtalen og/eller leveransen av Tjenestene.`,
                sub_point_1_5_3: `Brukeren er ansvarlig for at appen til enhver tid er oppdatert. Riktig funksjonalitet til mobiltelefoner eller annet teknisk utstyr er Brukerens ansvar til enhver tid. Det er brukerens ansvar at mediene som brukes er kompatible med tjenestene og appen. De gjeldende funksjonskravene for tjenestene og appen kan til enhver tid fås ved å kontakte kundeservice.`,
            },
            sub_point_1_6: `Bruker plikter til enhver tid å rette seg etter gjeldende lover og regler samt de regler og vilkår som fremgår av skiltingen på den aktuelle parkeringsplassen der Brukeren parkerer kjøretøyet sitt.`,
            sub_point_1_7: `Brukeren er ansvarlig for å starte parkeringen riktig ved å oppgi det parkerte kjøretøyets registreringsnummer og retningsnummer som gjelder parkeringsplassen som benyttes. Brukeren er ansvarlig for å sikre at retningsnummeret oppgitt av brukeren er korrekt, selv om retningsnummeret er foreslått i Appens plasseringsfunksjon. MERK: Dersom riktig registreringsnummer og/eller retningsnummer ikke er oppgitt, er det ikke foretatt gyldig betaling for parkeringen, hvorfor det parkerte kjøretøyet risikerer å bli belastet med kontrollavgift.`,
            sub_point_1_8: `Bruker er ansvarlig for at en påbegynt parkering gjennomføres. Dersom Bruker ikke spesifiserer et foreløpig sluttid eller total parkeringstid når parkeringen starter, er Bruker ansvarlig for å avslutte parkeringen manuelt. Avslutning av parkering kan gjøres via Appen, eller ved å kontakte Oparko ved å bruke kontaktinformasjonen som vises på Appen, nettsiden og Oparko-skiltene.`,
            sub_point_1_9: {
                sub_point_1_9_title: `Dersom parkeringstjenesten ikke er tilgjengelig eller er ute av drift når brukeren trenger å parkere eller mens brukeren parkerer (f.eks. av årsaker som kan tilskrives telefon, mobiltelefon eller annet teknisk utstyr som brukeren bruker, eller på grunn av feil, avbrudd eller forsinkelse av telefon, internett eller andre kommunikasjonsnettverk), er Bruker ansvarlig for at betaling skjer på annen måte og som angitt av oppsatt skilting på parkeringsplassen (for eksempel ved betaling i den aktuelle automaten).`,
                sub_point_1_9_1: `Brukeren er ansvarlig for betaling av eventuelle inspeksjonsgebyrer eller gebyrer for feilparkering (varslet eller innkrevd i forbindelse med brudd på parkeringsrestriksjoner).`,
                sub_point_1_9_2: `Dersom det parkerte kjøretøyet er firmaregistrert anses parkeringen for utført i arbeidssammenheng, hvorfor Selskapet i denne situasjonen vil anses som rett debitor.`,
            },
            sub_point_1_10: `Brukeren har mulighet til å se parkeringshistorikken for de parkeringsplassene brukeren har betalt for via Oparko.`,
            sub_point_1_11: `All parkering som kjøpes/reserveres via Oparko er på egen risiko, med mindre annet er spesifikt angitt i disse vilkårene.`,
            sub_point_1_12: `Parkeringskompagniet påtar seg intet ansvar i forbindelse med ulykker, tyveri, skader på personer, kjøretøy, utstyr og effekter, i forbindelse med at kjøretøyet parkeres på parkeringsplass. Parkeringskompagniet kan ikke holdes ansvarlig for noen form for indirekte tap eller følgeskader.`,
        },
    },
    Terms_main_point_8: {
        main_title: 'Tilgang til klager',
        sub_point_1: `Ved mangelfull Tjeneste eller feillading skal Bruker umiddelbart og senest 30 dager etter at den aktuelle tjenesten ble levert eller etter at Bruker konstaterte eller burde ha konstatert den aktuelle feilbelastningen sende inn en skriftlig klage til Parkeringskompagniet kundeservice. Klagen skal tydelig angi mangelen eller feilen. Bruker skal yte rimelig bistand til Parkeringskompagniet i forbindelse med eventuelle undersøkelser i forbindelse med klagen.`,
        sub_point_2: `Klage på pålagt kontrollgebyr skal rettes til utsteder av pålagt kontrollgebyr.`,
    },
    Terms_main_point_9: {
        main_title: 'Vilkår og priser',
        sub_point_1: `Brukeren er til enhver tid ansvarlig for alle betalinger gjort via Oparko.`,
        sub_point_2: `Betalinger skjer ved at Bruker registrerer et gyldig betalingsmiddel i Appen. Betaling vil bli belastet via betalingsmiddelet registrert av brukeren.
        Når et kjøretøy er merket som aktivt, har Bruker akseptert at betaling automatisk skjer via det angitte foretrukne betalingsmiddelet.
        Administrator vil bli fakturert for betalinger der Erhvervsparkering er registrert som betalingsmiddel. Bruker velger og godkjenner betalingsmåte i forbindelse med søknad om registrering.
        Det er Brukerens ansvar å angi riktig og gyldig betalingsmiddel før parkering startes.
        Feil bruk av kommersiell parkering er kun en sak mellom administrator og bruker.`,
        sub_point_3: `Ved forsinket betaling vil eventuelle purringer bli belastet i henhold til gjeldende lovverk.`,
        sub_point_4: `Alle priser er oppgitt i danske kroner og inkluderer mva. Prisen som skal betales for parkeringen avhenger av valgt parkeringsplass, valgt parkeringsperiode, og om en pågående parkering forlenges.`,
        sub_point_5: `Ved oppretting av parkeringsplass og ved utvidelse er det oppgitt hvor mye den aktuelle parkeringen eller tilbygget koster, og dette beløpet skal godkjennes før parkeringsplassen opprettes og/eller utvides.`,
        sub_point_6: `I noen tilfeller vil det bli belastet driftsgebyr i tillegg til timepris for parkering. Dette vil alltid vises på appen eller nettsiden ved kjøp av tillatelse.`,
        sub_point_7: `Parkeringskompagniet forbeholder seg retten til å endre avgifter og priser.`,
        sub_point_8: `MERK: Oparko forbeholder seg retten til å oppdatere og endre innholdet i appen og nettsiden uten forvarsel.`,
    },
    Terms_main_point_10: {
        main_title: 'Angrerett',
        sub_point_1: `I henhold til den danske forbrukeravtaleloven (lov nr. 1457 av 17. desember 2013) har brukeren rett til å trekke seg fra avtalen ved å varsle Oparko kundeservice innen 14 dager etter godkjenning av registreringen.`,
        sub_point_2: `Bruker samtykker i at kjøpsloven (lov nr. 1853 av 24.09.2021) normal angrerett på 14 dager kun gjelder parkering som ennå ikke har trådt i kraft (gyldig) på angretidspunktet. Dersom Bruker dermed bestiller parkering med gyldighetstid fra samme dag (straks), opphører også angreretten samme dag (straks) og den ellers gyldige 14-dagers angreretten fraskrives dermed av Bruker. Ved Brukers kjøp av parkering med oppstart av gyldighetsperioden senere enn 14 dager fra inngåelse av abonnementsavtale har Bruker 14 dagers angrerett.`,
        sub_point_3: {
            sub_point_3_title: `For å benytte angreretten må angremeldingen sendes til Oparko senest den 14. dagen den dagen betalingen er registrert hos Oparko.`,
            sub_point_3_1: `Dersom fristen går ut på en helligdag, lørdag, grunnlovsdag, julaften eller nyttårsaften, forlenges fristen til påfølgende ukedag.`,
            sub_point_3_2: `For at udøve fortrydelsesretten skal Brugeren sende Parkeringskompagniet en e-mail, hvor Brugeren skriver, at køb/reservation fortrydes (se punkt 12.2.8.).`,
        },
        sub_point_4: `Vær oppmerksom på at å gå inn på parkeringsplassen/godkjenne kjøpet i appen eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a> , anses aksept for igangsetting av parkering. Hvis du da angrer på kjøpet i appen eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>, vil refusjonen din bli redusert med en forholdsmessig del av totalprisen for parkeringen i kjøpsperioden, basert på antall dager/timer/minutter kjøretøyet har stått parkert frem til Oparko mottok registreringen av utøvelse av angreretten.`,
    },
    Terms_main_point_11: {
        main_title: 'Vilkår for kansellering',
        sub_point_1: `Brukeren kan velge å stenge Brukerens konto i Appen, eller ved å stenge Brukerens konto via nettsiden, hvorved Brukerens Oparko-konto blir ugyldig, slik at Brukeren ikke lenger har mulighet til å betale for parkering via Oparko.`,
        sub_point_2: `Ubetalte saker vil bli belastet i henhold til avtalen som er inngått, selv om Brukeren stenger Oparko-kontoen.`,
    },
    Terms_main_point_12: {
        main_title: 'Varighet, tidlig oppsigelse mv.',
        sub_point_1: `Denne avtalen er gyldig fra Oparkos godkjennelse av brukeren og til den sies opp i samsvar med vilkårene i avtalen eller inntil annet er skriftlig avtalt.`,
        sub_point_2: {
            sub_point_2_title: `Oparko har rett til umiddelbart å suspendere brukerens tilgang til tjenestene, kansellere brukerens konto og/eller si opp avtalen med umiddelbar virkning, dersom`,
            sub_point_2_1: `Brukeren er i vesentlig brudd på sine forpliktelser i henhold til denne avtalen;`,
            sub_point_2_2: `Brukeren oppfyller ikke, eller det er rimelig grunn til å anta at Brukeren ikke vil oppfylle sine betalingsforpliktelser i forhold til Oparko,`,
            sub_point_2_3: `Brukeren kan etter Oparkos rimelige vurdering forventes å bli ute av stand til å betale;`,
            sub_point_2_4: `Brukeren bruker Oparko-tjenester i strid med avtalen eller som på annen måte skader eller påvirker Oparko eller tredjeparter negativt;`,
            sub_point_2_5: `Brukeren har gjentatte ganger parkert et kjøretøy i strid med gjeldende lover, regler og/eller forskrifter spesifisert av den aktuelle parkeringsleverandøren;`,
            sub_point_2_6: `Brukeren har sendt inn feilaktig, ufullstendig eller villedende informasjon, eller`,
            sub_point_2_7: `Oparko finner det ut fra en samlet vurdering sannsynlig at brukeren kan være involvert i eller involvert i kriminelle aktiviteter.`,
            sub_point_2_8: `Oppsigelse av avtalen må gjøres skriftlig til <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a> eller via skjemaet på nettstedet: <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">https://parkeringskompagniet.dk/kontakt/</a>`,
        },
        sub_point_3: `Oppsigelse av avtalen (uansett grunn) påvirker ikke rettighetene og/eller forpliktelsene som en part har pådratt seg overfor den andre parten før datoen for oppsigelse av avtalen.`,
    },
    Terms_main_point_13: {
        main_title: 'Oparko ansvar',
        sub_point_1: `Oparkos samlede erstatningsansvar kan under ingen omstendigheter overstige et beløp tilsvarende DKK. 38.000,00, med mindre Oparko har handlet forsettlig eller har utvist grov uaktsomhet.`,
        sub_point_2: `Parkeringskompagniet er ikke ansvarlig for ulykker, tyveri, personskader, skader på kjøretøy, utstyr eller andre effekter montert på eller i kjøretøyet.`,
        sub_point_3: `Parkeringskompagniet er ikke ansvarlig for verken indirekte tap eller følgeskader. Parkeringskompagniet og Oparko er ikke erstatningsansvarlige i forhold til Brukerens forhold til evt tredjepart`,
        sub_point_4: {
            sub_point_4_title: `Parkeringskompagniet er ikke ansvarlig for skader eller tap påført av Administrator og/eller Bruker som følge av:`,
            sub_point_4_1: `Parkering på en parkeringsplass;`,
            sub_point_4_2: `Brukerens feil eller uaktsomhet, herunder dersom Bruker ikke har startet eller avsluttet en parkering korrekt (uansett om det er valgt å motta en påminnelse eller push-melding fra Oparko), eller om Bruker har tastet feil retningsnummer ift. til en Parkeringsplass og/eller registreringsnummer, ved starten av en parkering (uavhengig av om Brukeren har tastet inn slik retningsnummer manuelt eller dette er foreslått via plasseringsfunksjonen i Appen);`,
            sub_point_4_3: `at brukeren ikke har overholdt sine forpliktelser i henhold til denne avtalen og bruksvilkårene for Oparko eller at brukeren på annen måte ikke har fulgt Oparkos instruksjoner;`,
            sub_point_4_4: `at Brukeren har parkert kjøretøyet i strid med gjeldende lover, regler og/eller forskrifter angitt på oppsatt skilt på den aktuelle parkeringsplassen der Bruker parkerer kjøretøyet;`,
            sub_point_4_5: `feil eller utilstrekkelig funksjonalitet på brukerens mobiltelefon eller annet teknisk utstyr (som at utstyret er slått av eller at batteriet går tom for strøm);`,
            sub_point_4_6: `sammenbrudd, avbrudd eller forsinkelser i telefon-, Internett- eller andre kommunikasjonsnettverk, eller handlinger eller unnlatelser fra telekommunikasjonsoperatører, som påvirker funksjonaliteten eller tilgjengeligheten til tjenesten;`,
            sub_point_4_7: `feil eller mangel i en teknisk funksjon som angir prisen på den aktuelle parkeringsavgiften;`,
            sub_point_4_8: `at Tjenesten har blitt avbrutt av Oparko på grunn av forhold som viser seg å være feil, men som Oparko hadde grunn til å tro var korrekte på tidspunktet for avbruddet og som begrunnet avbruddet;`,
            sub_point_4_9: `avbrudd eller utilstrekkelig tilgang til en eller flere tjenester som Oparko ikke med rimelighet kunne ha forutsett;`,
            sub_point_4_10: `at en parkeringsplass foreslått av Oparko ikke er tilgjengelig når brukeren ankommer dit;`,
            sub_point_4_11: `uautorisert bruk av påloggingsinformasjon og/eller tjenester; eller`,
            sub_point_4_12: `force majeure (se punkt 16).`,
        },
        sub_point_5: `Under ingen omstendigheter er Oparko ansvarlig for indirekte skader som tap av drift og tap av data. Oparko er ikke ansvarlig for skader i forhold til administratorens og/eller brukerens forhold til tredjeparter.`,
    },
    Terms_main_point_14: {
        main_title: `Brukerens erstatningsansvar`,
        sub_point_1: `Brukeren skal holde Parkeringskompagniet skadesløs for erstatningskrav eller tap som følge av Brukerens feil eller uaktsomhet, Brukerens unnlatelse av å oppfylle sine forpliktelser etter denne Avtalen eller på annen måte unnlatelse av å følge Oparkos instruksjoner. Brukeren må også holde Oparko skadesløs for krav fra tredjeparter som er en direkte eller indirekte følge av brukerens bruk av en tjeneste.`,
    },
    Terms_main_point_15: {
        main_title: `Immaterielle rettigheter`,
        sub_point_1: `Brukeren aksepterer at programvare aldri kan testes i alle mulige situasjoner, og at det kan forekomme avvik fra avtalt funksjonalitet samt uforutsette feil og avbrudd. Oparko er generelt tilgjengelig 24 timer i døgnet, med unntak av planlagte avbrudd for oppgraderinger, endringer og vedlikehold. Retten til å oppdatere Oparko med nye funksjoner eller på annen måte gjøre endringer, for eksempel for å tilpasse systemet til ny teknologi, nye sikkerhetsstandarder eller nye administrative prosedyrer etc. forbeholdes Parkeringskompagniet. Parkeringskompagniet påtar seg intet ansvar for eventuelle skader eller tap i forbindelse med manglende tilgang til Oparko, Oparko er ute av drift eller inneholder feil.`,
        sub_point_2: `Alle opphavsrettigheter (inkludert rettigheter til kildekoder, dataprogrammer, algoritmer og objektkoder) og andre immaterielle rettigheter (inkludert patentrettigheter og varemerker) angående Oparko og dets innhold eies eller lisensieres av Parkeringskompagniet eller dets leverandører og partnere. Administratoren eller brukeren er ikke, i henhold til denne avtalen, tildelt noen av disse rettighetene. Det er ikke tillatt å bruke Oparko eller dets innhold til kommersielle formål. Brukeren har ingen rett til å kopiere, distribuere, selge, publisere, overføre, låne ut, underlisensiere, modifisere eller på annen måte disponere programvaren i Oparko. Brukeren har ingen rett til å demontere, reversere, dekompilere eller på annen måte forsøke å få tilgang til programvarekildekoden.`,
        sub_point_3: `At all informasjon og data som overføres til Oparko er fri for skadelige elementer, kildekoder eller skadelig programvare (som virus, ormer og trojanske hester) må sikres og garanteres av brukeren. Informasjon som brukeren laster opp til Mine sider eller gjør tilgjengelig via appen må ikke krenke tredjeparters immaterielle rettigheter eller på annen måte stride mot lover, forskrifter eller andres rettigheter. Det er Brukerens ansvar at de immaterielle rettighetene til tredjeparter eller andre ikke krenkes.`,
        sub_point_4: `Med mindre Oparko har opptrådt grovt uaktsomt eller forsettlig, er Oparko ikke ansvarlig for at data blir gjort tilgjengelig for tredjeparter. Oparko bruker vanlige sikkerhetstiltak for å beskytte data`,
    },
    Terms_main_point_16: {
        main_title: `Force majeure`,
        sub_point_1: `Oparko er ikke ansvarlig for skader eller ansvar for sammenbrudd eller forsinkelser i implementeringen av vilkårene i denne avtalen som følge av omstendigheter utenfor Oparkos rimelige kontroll.`,
    },
    Terms_main_point_17: {
        main_title: `konfidensialitet`,
        sub_point_1: `Brukeren aksepterer at Oparko rapporterer misbruk av tjenestene, ulovlige aktiviteter, ulovlig eller upassende oppførsel og/eller mistanker om dette til politiet eller andre kompetente myndigheter.`,
    },
    Terms_main_point_18: {
        main_title: `Lovvalg og tvister`,
        sub_point_1: `Denne avtalen er underlagt og må tolkes i samsvar med dansk lov. Enhver tvist, uenighet eller ethvert krav som oppstår på grunnlag av eller i forbindelse med denne avtalen, eller brudd, oppsigelse eller ugyldighet av denne, må i utgangspunktet løses ved frivillig avtale mellom partene. Dersom enighet ikke kan oppnås i første omgang, vil det bli avholdt en diskusjon mellom en representant valgt av Bruker og Parkeringskompagniet. Dersom partene ikke kan komme til enighet, kan tvisten behandles av de danske domstolene med tingretten i Århus som første instans. Denne klausulen 19.1 gjelder etter oppsigelse eller utløp av denne avtalen.`,
        sub_point_2: `Dersom en bestemmelse i denne avtalen settes til side som ugyldig, skal Avtalen ellers opprettholdes, og den ugyldige bestemmelsen erstattes med en gyldig bestemmelse, så langt det er mulig.`,
        sub_point_3: `Parkeringskompagniet forbeholder seg retten til å endre disse generelle vilkårene for bruk av Parkeringskompagniet. Dersom dette skjer, skal Parkeringskompagniet umiddelbart informere om dette og gjøre en ny versjon av de generelle vilkårene for bruk av Oparko tilgjengelig. Dersom Brukeren deretter fortsetter å bruke tjenestene, anses dette som en aksept av disse endringene. Parkeringskompagniet forbeholder seg uansett rett til å endre priser og avgifter/avgifter som følge av økninger i driftskostnader, andre kostnader og økninger i avgifter og avgifter mv. De til enhver tid gjeldende generelle vilkårene og betingelsene finner du i appen eller på www.parkeringskompagniet.dk under punktet "Kundeservice".`,
    },
    Subscription_terms_heading: `ABONNEMENTSBETINGELSER`,
    Subscription_terms_1: {
        main_title: `Når du oppretter og bruker Oparko Abonnementsparkering, aksepterer du i tillegg til nedenstående også de generelle vilkårene og betingelsene for bruk av Oparko. Disse betingelsene vises i Oparko-appen og på <a href="https://www.parkeringskompagniet.dk/oparko" class="text-pk-blue">www.parkeringskompagniet.dk/oparko</a>`,
        sub_point_1: `Ved inngåelse av avtale om abonnementsparkering oppnår Bruker en tidsbegrenset digital parkeringstillatelse.`,
    },
    Subscription_terms_2: {
        main_title: `Abonnementsparkeringsavtalen gjelder for valgt antall abonnementsparkeringsavtaler, som gir rett til å parkere på samme antall parkeringsplasser på valgt parkeringsplass. For abonnementsparkering er det et gyldighetsvilkår at kjøretøyet er korrekt registrert i Oparko. Riktig og gyldig registrering krever at registreringsnummeret er korrekt registrert og at kjøretøyet er korrekt registrert på abonnementsparkeringsproduktet. Det registrerte kjøretøyet kan parkere i parkeringsplasser som ellers ikke er reservert. Dette gjelder hele døgnet, alle dager i uken.`,
        sub_point_1: `Abonnementsparkering vil fremkomme under aktiv parkering, leide parkeringsplasser.`,
        sub_point_2: `Det er en gyldig betingelse for korrekt og gyldig registrering at det registrerte kjøretøyet er valgt som favorittbil og fremstår korrekt registrert på abonnementsparkeringsproduktet under leide parkeringsplasser.`,
    },
    Subscription_terms_3: {
        main_title: `Abonnementsparkeringsavtalen gjelder bruk av merket parkeringsbod etter «førstemann til mølla-prinsippet». Det er Med abonnementsparkeringsavtalen får brukeren kun «flytende rett» til en oppmerket parkeringsplass som ikke er reservert på annen måte. I tilfelle det ikke er ledig merket parkeringsplass kan Parkeringskompagniet ikke holdes ansvarlig for dette, likesom bruker ikke har krav på reduksjon av gebyret.`,
        sub_point_1: `At Bruker ikke benytter abonnementsparkering i hele den valgte perioden betyr ikke at Bruker har krav på reduksjon av vederlaget.`,
    },
    Subscription_terms_4: {
        main_title: `Abonnementsparkeringsavtalen trer i kraft fra og med dato valgt av Bruker, og løper i oppgitt periode. Abonnementsparkeringsavtalen kan sies opp umiddelbart av Parkeringskompagniet uten nærmere begrunnelse. Brukeren kan tidligst avbestille ved slutten av en kjøpt periode. Oppsigelse skjer dersom brukeren ikke fornyer eller forlenger abonnementsparkeringsavtalen. I tilfelle Bruker har valgt automatisk fornyelse, må Bruker stoppe automatisk fornyelse for å si opp abonnementsparkeringsavtalen.`,
        sub_point_1: `Brukeren kan kun si opp abonnementsparkeringsavtalen til enhver tid frem til slutten av den valgte perioden. Det vil i så fall ikke bli trukket penger fra Brukerens betalingskort før ny abonnementsparkeringsavtale er opprettet.`,
        sub_point_2: `Abonnementsparkeringen er gyldig fra valgt dato og for valgt periode. En abonnementsparkeringsavtale er derfor ikke nødvendigvis gyldig fra 1. til 1. i måneden, med mindre Bruker har valgt dette.`,
    },
    Subscription_terms_5: {
        main_title: `Bruker er til enhver tid ansvarlig for at det er gyldig abonnementsavtale på kjøretøyet som er parkert på parkeringsplassen. Det er også Brukerens eget ansvar at tilknyttede kjøretøy er registrert riktig og tilstrekkelig på profilen. Det kan ilegges kontrollgebyr dersom et parkert kjøretøy ikke er korrekt og tilstrekkelig registrert. Kontrollgebyret kan i ettertid ikke frafalles.`,
    },
    Subscription_terms_6: {
        main_title: `Innholdet i abonnementsparkeringsavtalen, priser og eventuelle spesielle betingelser for Produktpakkene vil til enhver tid bli angitt på Nettsiden og i Appen. Godtgjørelsen betales på forskudd. Første gang ved inngåelse av abonnementsparkeringsavtalen.`,
        sub_point_1: `I noen tilfeller vil brukeren få en unik produktkode, som må brukes under registreringen. I forbindelse med mottak av den unike produktkoden, informeres Bruker om hvordan brukeren må forholde seg i forbindelse med betalingsplikten og hvordan registrering av den unike produktkoden skal foregå.`,
        sub_point_2: `I tilfelle abonnementsparkeringsavtalen enten sies opp, oppheves, angres eller den leverte unike produktkoden kanselleres eller sperres, har Parkeringskompagniet rett til å benytte den registrerte informasjonen, herunder brukerens e-postadresse mv. i form av pt. 17., for å gi en melding om dette.`,
    },
    Subscription_terms_7: {
        main_title: `Pristallsgodtgjørelsen justeres hvert år 1. januar uten ytterligere varsel, hvor den justeres med nettoprisindeksen for oktober. i foregående år, dog med minimum 3 % (avrundet til nærmeste hele krone) i forhold til vederlagsutbetalingen i foregående år. Indeksregulering av godtgjørelsen skjer på grunnlag av godtgjørelsen gjeldende umiddelbart før reguleringen, uavhengig av om denne godtgjørelsen kan ha oppstått ved regulering på annen måte enn ved indeksregulering.`,
        sub_point_1: `I tillegg til ovennevnte indeksregulering forbeholder Parkeringskompagniet seg retten til å endre priser og avgifter/gebyrer som følge av økning i driftskostnader, andre kostnader og økninger i gebyrer og avgifter mv. Slike endringer trer i kraft tidligst ved slutten av inneværende måned + 1 måned etter at brukeren har blitt informert om slike endringer.`,
    },
    Subscription_terms_8: {
        main_title: `Parkeringskompagniet tar forbehold om prisfeil, prisendringer og oppdateringsfeil.`,
    },
    Subscription_terms_9: {
        main_title: `Dersom Bruker har valgt automatisk fornyelse er det Brukers eget ansvar å "stoppe" den automatiske fornyelsen ved å si opp Abonnementsparkeringsavtalen. At betaling stanses er ikke å anse som en oppsigelse. Betalingen kreves inn til Abonnementsparkeringsavtalen sies opp.`,
    },
    Subscription_terms_10: {
        main_title: `All trafikk og parkering skjer på brukers eget ansvar. Parkeringskompagniet er ikke ansvarlig for tyveri eller skade som måtte påføres brukers kjøretøy, uavhengig av årsaken til dette.`,
    },
    Subscription_terms_11: {
        main_title: `Abonnementsparkeringsavtalen kan ikke uten skriftlig samtykke fra Parkeringskompagniet benyttes til annet enn parkering av korrekt registrert kjøretøy, med registrert totalvekt på maks. 3.500 kg.`,
    },
    Subscription_terms_12: {
        main_title: `Med denne Abonnementsparkeringsavtalen er brukeren uttrykkelig gjort oppmerksom på at inngåelse av denne Abonnementsparkeringsavtalen ikke gir brukeren rett til å kansellere pålagte kontrollavgifter. Abonnementsparkeringsavtalen er først gyldig fra det tidspunkt registreringsnummeret er korrekt registrert. Abonnementsparkeringsavtalen gjelder kun for kjøretøyet som er korrekt registrert.`,
        sub_point_1: `Abonnementsparkeringsavtalen gir kun rett til å parkere på området som fremgår av abonnementsparkeringsavtalen, i de oppmerkede parkeringsplassene hvor det ikke fremgår at det er spesielle regler som gjelder for parkering i disse parkeringsplassene. Det gjøres oppmerksom på at abonnementsparkeringsavtalen ikke er en gyldig parkeringstillatelse for parkering i parkeringsboder som er reservert.`,
    },
    Subscription_terms_13: {
        main_title: `Ved brukers vesentlige mislighold av avtalen kan Parkeringskompagniet si opp Abonnementsparkeringsavtalen med umiddelbar virkning. Vesentlig mislighold omfatter, men er ikke begrenset til, manglende betaling av avtalt vederlag, brudd på bestemmelsene i denne avtalen samt eventuelle brudd på parkeringsbestemmelsene. Ved vesentlig mislighold har Parkeringskompagniet rett til å ilegge kontrollgebyr og eventuelt fjerne eller beslaglegge brukerens kjøretøy inntil forfalte gebyrer og omkostninger er betalt. Bruker har ikke adgang til motregning, selv om det er/er uenigheter om kravet.`,
        sub_point_1: `Parkeringskompagniet kan si opp abonnementsparkeringsavtalen skriftlig med 1 måneds varsel i tilfelle det må utføres vedlikehold, ombygging, renovering mv. forhold som medfører at Parkeringskompagniet varig ikke kan bruke parkeringsplassen lenger.`,
    },
    Subscription_terms_14: {
        main_title: `I henhold til den danske forbrukeravtaleloven (lov nr. 1457 av 17. desember 2013) har Bruker rett til å trekke seg fra Avtalen ved å melde fra til Parkeringskompagniets kundeservice innen 14 dager etter godkjenning av registreringen. For å benytte angreretten må angremeldingen sendes til Parkeringskompagniet senest den 14. dagen den dagen betalingen er registrert hos Oparko.`,
        sub_point_1: `Dersom fristen går ut på en helligdag, lørdag, grunnlovsdag, julaften eller nyttårsaften, forlenges fristen til påfølgende ukedag`,
        sub_point_2: `For å benytte angreretten må Bruker sende oss en e-post eller et brev der Bruker skriver at kjøpet/reservasjonen er kansellert. Vær oppmerksom på at det å gå inn på parkeringsplassen/godkjenne kjøpet i appen eller www.parkeringskompagniet.dk regnes som aksept av at parkering er påbegynt og aksept av at 14-dagers angrerett fravikes. Hvis du da angrer på kjøpet i appen eller www.parkeringskompagniet.dk, vil refusjonen reduseres med en forholdsmessig del av totalprisen for parkeringen i den kjøpte perioden, basert på antall dager/timer/minutter kjøretøyet har stått parkert inntil Parkeringskompagniet mottok registreringen på utøvelse av angreretten`,
    },
    Subscription_terms_15: {
        main_title: `Eventuelle tvister mellom parkeringsselskapet og brukeren angående forståelsen av denne avtalen må avgjøres av de ordinære domstoler som anvender dansk lov. Retten i Århus er avtalt som verneting.`,
    },
    Subscription_terms_16: {
        main_title: `Bruker har ikke rett til å fremleie parkeringsboden. Overtredelse av dette anses som brudd, jf. pkt. 13.`,
    },
    Subscription_terms_17: {
        main_title: `Parkeringskompagniet er parkeringsadministrator på vegne av plasseier/plasseiers eiendomsforvalter. Alle henvendelser vedr denne kontrakten skal gjøres til Parkeringskompagniet. Dersom Parkeringskompagniet slutter å være parkeringsadministrator, overføres avtalen til ny parkeringsadministrator.`,
    },
    Subscription_terms_18: {
        main_title: `I forhold til lagring og behandling av personopplysninger, vennligst se den til enhver tid gjeldende datapolitikken, som vises på <a href="https://www.parkingskompagniet.dk/datapolitik/" class="text-pk-blue">www.parkingskompagniet.dk/datapolitik/</a>. Dersom opplysningene skal slettes anses dette som heving av abonnementsparkeringsavtalen. Dette fordi Parkeringskompagniet i disse tilfellene ikke kan opprettholde avtalen uten nevnte opplysninger.`,
    },
    Subscription_terms_footer_heading_1: `MERK at Bruker har plikt til å holde opplysningene registrert i Oparko oppdatert. Det er Bruker skal gi melding til Parkeringskompagniet ved endring av adresse, e-post mv. eller evt registreringsnummer på kjøretøyet som skal brukes i Oparko. Dersom dette ikke overholdes, har Parkeringskompagniet rett til å slette allerede registrerte opplysninger og si opp abonnementsparkeringsavtalen for å overholde gjeldende personopplysningslovgivning.`,
    Subscription_terms_footer_heading_2: `Parkingkompaniet ApS`,
    Footer_contact_info: {
        contact_title: `Kontaktinformasjon`,
        company_name: `Parkingkompaniet ApS`,
        address_line: `Trindsøvej 4, 8000 Aarhus`,
        cvr: `CVR: 37127485`,
        email: `E-post: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>`,
        tel: `Telefon: <a href="tel:+ 45 88 74 31 94" class="text-pk-blue">+ 45 88 74 31 94</a>`,
        website: `<a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a>`,
        updatedAt: `Revidert 16.11.2022`,
    },

    // Act as user
    'Take Over': 'Ta over bruker',

    // Old Terms

    Terms_title: '',
    Terms_heading: '',
    Terms_last: '',

    Terms_1: {
        para_1: '',
        para_2: '',
        para_3: '',
        para_4: '',
        link_1: '',
        link_2: '',
    },

    Terms_2: {
        heading: '',
        para_1: '',
        para_2: '',
        para_3: '',
        link_1: '',
    },

    Terms_3: {
        heading: '',
        para_1: '',
        ul: {
            heading: '',
            list: ['♦ ', '♦ ', '♦ ', '♦ ', '♦ ', '♦ ', '♦ ', '♦ '],
        },
    },

    Terms_4: {
        heading: '',
        para_1: '',
        row_1: {
            heading: '',
            para_1: '',
            para_2: '',
            para_3: '',
        },

        row_2: {
            heading: '',
            para_1: '',
        },

        row_3: {
            heading: '',
            para_1: '',
            para_2: '',
            para_3: '',
            link: '',
        },

        row_4: {
            heading: '',
            link: '',
        },

        row_5: {
            heading: '',
            para_1: '',
        },

        row_6: {
            heading: '',
            para_1: '',
        },

        row_7: {
            heading: '',
            link: '',
        },
    },
    ReceptionUser: {
        countryError: 'Landskode er ikke valgt',
        registrationError: 'Registreringsnummer. har ikke blitt valgt',
        emailError: 'E-post er ikke valgt',
        phoneNumberError: 'Telefonnummer er ikke valgt',
        acceptError: 'Godta vilkår er ikke valgt',
        countryCodeError: 'Landskode er ikke valgt',
    },

    'Custom end date': 'Velge sluttid selv',
    'Max time in min (0 is no limit)': 'Maks tid i minutter (må være over 0)',

    ConfirmPermit: {
        lineOne: 'Det er nå satt opp gjesteparkering kl',
        for: 'til',
        on: 'på',
        at: 'på',
        lineTwo: 'som er gyldig t.o.m',
    },
    'Receive SMS?': 'Motta SMS?',
    'Guest Parking has been created!': 'Gjesteparkering er opprettet!',
    'A Guest parking permit has now been created':
        'En gjesteparkeringstillatelse er nå opprettet',

    // Entities

    Reception: 'Resepsjon',
    HotelReception: 'Hotellresepsjon',
    EmployeeReception: 'Ansattmottak',
    TimesReception: 'Tider mottak',
    HotelAdmin: 'Hotelladministrator',
    ConfirmationReception: 'Bekreftelsesmottak',
    HotelReceptionConfirmation: 'Bekreftelse av hotellresepsjonen',
    StayNTouchReception: 'Stay-N-Touch-mottak',
    APIPartner: 'API-partner',
    SMSConfirmationReception: 'SMS-bekreftelsesmottak',
    Guest_parking_created:
        'En gjesteparkering for {{regNo}} er opprettet, gyldig til og med {{date}}.',
    Enter_registration_number: 'Skriv inn registreringsnummer',
    Confirm: 'Bekrefte',
    Clear_all: 'Fjerne alt',

    View: 'Utsikt',
    Edit: 'Redigere',
    AppFines: 'AppFines',
    AppPermits: 'AppPermits',
    NOT_DEFINED: 'IKKE DEFINERT',
    SELF_TICKETING: 'SELF_BILLETTING',
    COMBI: 'KOMBI',
    P_WARDEN: 'P_WARDEN',
    CAMERA_CONTROL: 'CAMERA_CONTROL',
    Partner: 'Samboer',
    Private: 'Privat',
    Company: 'Selskap',
    'Not Handled': 'Ikke håndtert',
    'Not handled': 'Ikke håndtert',
    Approved: 'Godkjent',
    Rejected: 'Avvist',
    'Other - write a comment in the note':
        'Annet - skriv en kommentar i notatet',
    'Outside Booth': 'Utenfor Booth',
    'No permission to the area': 'Ingen tillatelse til området',
    'Parking disc exceeded': 'Parkeringsskive overskredet',
    'Parking or full stop on prohibited area':
        'Parkering eller punktum på forbudt område',
    'Parking on fire access road': 'Parkering på brannvei',
    'Handicapped parking without permission':
        'Handikapparkering uten tillatelse',
    Deleted: 'Slettet',
    'Guest Parking': 'Gjesteparkering',
    Resident: 'Beboer',
    'Paid ticket': 'Betalt billett',
    'Employee paid ticket': 'Ansatt betalt billett',
    'Employee upper basement': 'Ansatt øvre kjeller',
    'Employee lower basement': 'Ansatt nedre kjeller',
    Permanent: 'Fast',
    License: 'Tillatelse',
    Reminder: 'Påminnelse',
    'Debt collection - Paragraph': 'Inkasso - Stk',
    Ended: 'Endte',
    Foreign: 'Fremmed',
    Paused: 'Pause',
    'Debt collection - Paragraph - installment agreement with demand':
        'Inkasso - Paragraf - avdragsavtale med påkrav',
    'Debt collection - Paragraph - with demand':
        'Inkasso - Paragraf - med påkrav',
    'Debt collection - Liquidation': 'Inkasso - Avvikling',
    'Debt collection - Liquidation - installment agreement with demand':
        'Inkasso - Avvikling - avdragsavtale med påkrav',
    'Complaint Council': 'Klagenemnda',
    All: 'Alle',

    // Subscription
    Next: 'Neste',
    Previous: 'Foregående',
    Subscription: 'Abonnement',
    Submit: 'Sende inn',
    Subscribers: 'Abonnenter',
    'Already subscribed': 'Har allerede abonnert',
    'E.g.': 'F.eks.',
    '12th Star Avenue': 'Stjerneveien 12',
    'Step 1: Which parking lot do you want to park at?':
        'Trinn 1: Hvilken parkeringsplass vil du parkere på?',
    'Step 2: Which license plate does your car have?':
        'Trinn 2: Hvilket registreringsskilt er det på bilen din?',
    'Step 3: When should your subscription start?':
        'Trinn 3: Når skal abonnementet ditt starte?',
    'Step 4: Your monthly price is based on the selected parking lot, and will be:':
        'Trinn 4: Din månedlige pris er basert på valgt parkeringsplass og er:',
    'Step 5: To be allowed to create a subscription for this private parking lot, you must enter a pin code below. Next, click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Trinn 5: For å få lov til å opprette et abonnement på denne private parkeringsplassen må du oppgi en pinkode nedenfor. Klikk deretter på Opprett for å gå til betalingssiden. Når du er ferdig, vil du bli omdirigert tilbake til siden vår, hvor du kan finne ditt abonnement i tabellen.',
    'Step 5:  Click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Trinn 5:  Klikk på Opprett for å gå til betalingssiden. Når du er ferdig, vil du bli sendt tilbake til siden vår, hvor du kan finne ditt abonnement i tabellen.',
    'Below you can below see the available days, times and prices':
        'Du kan nedenfor se tilgjengelige dager, tider og priser',
    'Subscription Info': 'Abonnement Info',
    'Monthly price': 'Månedlig pris',
    'Last recurring payment date': 'Siste tilbakevendende betalingsdato',
    'Car Info': 'Bil Info',
    'Enter registration number': 'Oppgi nummerskilt',
    'Buy monthly subscription': 'Kjøp månedlig abonnement',
    'Buy short ticket': 'Kjøp en enkeltbillett',
    'The price is': 'Prisen er',
    'kr pr. month, but the price on specific parking spots may vary':
        'kr pr. måned, men prisen på spesifikke parkeringsplasser kan variere',
    'Registration number': 'Registreringsnummer',
    'PIN code': 'PIN-kode',
    'Email id already registered with us':
        'E-posten er allerede registrert hos oss',
    'Buy ticket': 'Kjøp billett',
    'Get ticket': 'Få billett',
    'Permit id': 'Tillatelse id',
    'Last payment': 'Siste betaling',
    'Price per period': 'Pris per periode',
    'Stop subscription': 'Stopp abonnementet',
    'Price: 500 kr. per month (normal price is 625 kr. per month - The discount is active for the rest of 2023)':
        'Pris: 500 kr. per måned (normalpris er 625 kr. per måned - Rabatten gjelder ut 2023)',
    'Payment Options': 'Betalingsmuligheter',

    // FAQs

    'How do I change the numberplate on an existing permit?': `Hvordan endrer jeg nummerskiltet på en eksisterende tillatelse?`,
    'How do I extend a permit?': 'Hvordan forlenger jeg tillatelsen?',
    "What do I do if something doesn't work?": `Hva gjør jeg hvis noe ikke fungerer?`,
    'How often do you pay out customer shares?': `Hvor ofte betaler I ut kundeandeler?`,
    'Why am I being paid for a parking fee that is several years old now?': `Hvorfor blir jeg betalt for en kostnad som er flere år gammel nå?`,
    'Status and their meanings': 'Status og deres betydninger',

    answer_5: {
        paragraph: `På 'Tillatelser'-siden skriver du inn nummerskiltet til bilen i søkefeltet og trykker på enter/Søk. I listen nedenfor klikker du på den aktuelle tillatelsen. Bytt til det nye registreringsnummeret i 'Reg.nr.'-feltet. Du sjekker da at parkeringsplass, sluttdato og resten ser riktig ut og klikker på 'Oppdater'.`,
    },
    answer_6: {
        paragraph: `På 'Tillatelser'-siden skriver du inn nummerskiltet til bilen i søkefeltet og trykker på enter/Søk. I listen nedenfor klikker du på den aktuelle tillatelsen. Bytt til det nye registreringsnummeret i 'Reg.nr.'-feltet. Du sjekker da at parkeringsplass, sluttdato og resten ser riktig ut og klikker på 'Oppdater'.`,
    },

    answer_7: {
        paragraph: `Det hjelper ofte å logge ut og inn igjen, så vi anbefaler alltid at du gjør det først. Hvis det fortsatt ikke fungerer, er du alltid velkommen til å sende oss en e-post på service@oparko.com. For at vi skal hjelpe deg raskere er du hjertelig velkommen til å sende skjermbilder av problemet (hvis det finnes) Trenger du hjelp her og nå er telefonen vår åpen mandag, tirsdag og torsdag 11-15 og freda 11 -1. 3. Ring 8874 3194.`,
    },
    answer_8: {
        paragraph: `I samarbeidsavtalen/kontrakten vi har inngått finner du svar på hvor ofte det gjøres oppgjør. Standard er en gang hver tredje måned.`,
    },
    answer_9: {
        paragraph: `Hvor raskt en parkeringsavgift betales etter utstedelse kan variere fra 'umiddelbart' til 'aldri'. Dette betyr at parkeringsavgifter som ble utstedt for måneder eller år siden kan vises på utbetalingen din i dag, fordi de er betalt siden vi sist gjorde opp med deg.`,
    },

    answer_10: {
        paragraph1: `Aktiv: Parkeringsavgiften opprettes i vårt system`,
        paragraph2: `Inndrivelse: Parkeringsavgiften er sendt til inndrivelse hos vår samarbeidspartner og det er sendt brev til eier av bilen`,
        paragraph3: `Betalt: Når parkeringsavgiften er betalt, endres statusen til betalt`,
        paragraph4: `Oppgjort: Kundeandelen av parkeringsavgiften er utbetalt `,
        paragraph5: `Kansellert: Parkeringsavgiften er kansellert`,
        paragraph6: `Beskyttet: Bilen eies av en person som ikke bor i Danmark`,
    },
    // weekdays
    Mon: 'Man',
    Tue: 'Tir',
    Wed: 'Ons',
    Thur: 'Tor',
    Fri: 'Fre',
    Sat: 'Lør',
    Sun: 'Søn',
    Monday: 'Mandag',
    Tuesday: 'Tirsdag',
    Wednesday: 'Onsdag',
    Thursday: 'Torsdag',
    Friday: 'Fredag',
    Saturday: 'Lørdag',
    Sunday: 'Søndag',

    Hour: 'Time',
    hour: 'time',
    hours: 'timer',
    'half-hour': 'halve time',
    'half-hours': 'halve timer',
    Day: 'Dag',
    day: 'dag',
    days: 'dager',
    Week: 'Uge',
    weeks: 'uger',
    Month: 'Måned',
    months: 'måneder',
    'Number of': 'Antall',
    First: 'Første',
    to: 'til',
    Free: 'Gratis',
    'Subscription per month': 'Abonnement per måned',
    'No limit on': 'Ingen begrensning på',
    After: 'Etter',

    // popup box - accepted
    accepted_title: 'Kjøp akseptert',
    accepted_description1: 'Kjøpet av parkeringsbillett gikk gjennom',
    accepted_description2:
        'Hvis en e-post er spesifisert i forrige e-postfelt, vil en bekreftelses-e-post bli sendt til den angitte e-postadressen.',
    accepted_description3: 'Som bekreftelse på tillatelsen kan du bruke:',
    accepted_description4: 'Klipp av debitert bankoverføring',
    accepted_description5: 'Klipp av MobilePay-betaling',
    accepted_description6: 'Sendt bekreftelsesmail',
    accepted_description7:
        'Hvis det er ytterligere spørsmål og/eller tvil angående betaling, vennligst kontakt oss på:',
    accepted_description8: 'Vinduet kan nå lukkes.',

    Ok: 'Ok',
    // popup box - successfull
    successfull_title: 'Velkommen til Oparko!',

    successfull_description: 'Ditt abonnement er nå aktivt.',

    successfull_description1:
        'Du har mottatt all informasjon om abonnementet ditt i din e-post. Du kan se og oppdatere detaljer om abonnementet ditt direkte på plattformen ved å logge inn.',

    successfull_description2:
        'Du kan nå lukke dette vinduet for å få tilgang til kontoen din.',

    declined_title: 'Dessverre var det en feil med betalingen din',

    declined_description:
        'Din abonnementskonto ble fortsatt opprettet, men den er ikke aktiv. Dette betyr at vi ikke vil ta noen betalinger fra deg, og du vil ikke ha en aktiv tillatelse til å parkere bilen din.',

    declined_description1:
        'Du har mottatt all nødvendig informasjon for å logge inn på vår plattform via din e-post. Fra vår plattform kan du finne betalingslenken for å prøve en annen betaling hvis du ønsker.',

    declined_description2:
        'Alternativt kan du bruke lenken nedenfor for å prøve igjen:',

    declined_description3: 'Eller kontakt Oparko Support her:',

    declined_description4:
        'Hvis du har spørsmål om betalingen, kan du kontakte oss på,',

    ticket_service_disclaimer_1:
        'Vær oppmerksom på at det ikke er mulig å stoppe parkeringstillatelsen tidlig. Det er ikke mulig å forlenge parkeringstillatelsen din, ønsker du å beholde den må du kjøpe ny tillatelse når den første har gått ut. Videre er det ikke mulig å annullere en tillatelse når den først er opprettet.',
    ticket_service_disclaimer_2:
        'Vi gjør spesielt oppmerksom på at det er partens fulle ansvar at opplysningene som gis er korrekte. Parkeringskompagniet fraskriver seg samtidig ansvar for eventuelle feil under opprettelsen. Når du kjøper en parkeringsbot godtar du også våre vilkår og betingelser. Du samtykker derfor også til vår personopplysningspolicy.',
    subscription_service_disclaimer:
        'Vi gjør spesielt oppmerksom på at det er partens fulle ansvar at opplysningene som gis er korrekte. Parkeringskompagniet fraskriver seg samtidig ansvar for eventuelle feil under opprettelsen. Når du kjøper et abonnement, godtar du også våre vilkår og betingelser. Du samtykker derfor også til vår personopplysningspolicy',
    subscription_service_disclaimer_2: `Abonnementsparkeringsavtalen gir kun rett til å parkere på området som fremgår av abonnementsparkeringsavtalen, i de oppmerkede parkeringsplassene hvor det ikke fremgår at det er spesielle regler som gjelder for parkering i disse parkeringsplassene. Det gjøres oppmerksom på at abonnementsparkeringsavtalen ikke er en gyldig parkeringstillatelse for parkering i parkeringsboder som er reservert.`,

    // Months
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'Mai',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Okt',
    Nov: 'Nov',
    Dec: 'Des',

    // redirectFromPK
    'Important announcement': 'Viktig oppdatering',
    'Welcome! You have landed here because Parkeringskompagniet has now become':
        'Velkommen! Du har landet her fordi Parkeringsselskapet nå har blitt',
    'We have upgraded to a new management platform to offer you an even better user experience':
        'Vi har oppgradert til en ny administrasjonsplattform for å tilby deg en enda bedre brukeropplevelse.',
    'What should you do now?': 'Hva bør du gjøre nå?',
    'Your existing information is automatically transferred. You just have to log in from the new platform now':
        'Din eksisterende informasjon overføres automatisk. Du må ganske enkelt logge inn på den nye plattformen fra nå av',
    'New Functions': 'Nye funksjoner',
    'The new platform can do everything you are used to, plus much more!':
        'Den nye plattformen kan gjøre alt du er vant til, pluss mye mer!',
    'Do you have questions? Contact us at service@oparko.com':
        'har du spørsmål? Kontakt oss på service@oparko.com',
    'E-mail at': 'E-mail til',
    'Call at': 'Ring til',
    'Phone hours:': 'Telefontider:',
    'Go to new admin platform': 'Gå til ny adminplattform',

    // Pay fine
    'How to appeal': 'How to appeal',
    'Get documentation': 'Get documentation',
    'Complaint form': 'Complaint form',
    'Select a reason': 'Select a reason',
    Appeal: 'Appeal',
    'Submit an appeal': 'Submit an appeal',
    'Pay fine': 'Pay fine',
    'License plate': 'License plate',
    'Fine id': 'Fine id',
    retrieve_fine_documentation_text:
        "Retrieve fine documentation. Note that not all fines will result in documentation. The 'Fine id' is the id that comes with your fine when you receive it in the mail.",
    Subject: 'Subject',
    'Valid permit': 'Valid permit',
    'Type in complaint here': 'Type in complaint here',
    Attachments: 'Attachments',
    'Select files': 'Select files',
    'Select file': 'Select file',
    complaint_form_text:
        'All inquiries regarding parking charges (complaints, payments, reminders or the like) are only received in writing using the form below. It is important that we receive all information in writing.',
    'Fine documentation': 'Fine documentation',
    Price: 'Price',
    Offense: 'Offense',
    Location: 'Location',
    Continue: 'Continue',
    Topic: 'Topic',
    'Why you received a control fee': 'Why you received a control fee',
    'Incorrect Fine ID or Reg no. Please check again!':
        'Feil avgifts-ID eller reg.nr. Vennligst sjekk igjen!',
    pay_fine_documentation_error_message: `Unable to retrieve documentation. Check if 'Fine id' and 'License plate' are correct.`,

    // report pdf
    'The amount is paid to the account details below':
        'Beløpet er betalt til kontodetaljene nedenfor',
    'Parking reading - Subscription (ready)':
        'Parkering lesing - Abonnement (klar)',
    'The Address': 'Adresse',
    'CREDIT NOTE': 'KREDITNOTA',
};

export default norsk;
