import React, { useEffect, useState } from 'react';
import { backofficeOrganisationApi } from 'api/backoffice/organisation';
import { backofficeParkingApi } from 'api/backoffice/parking';
import { backofficeFineAPI } from 'api/backoffice/fine';
import { frontofficeParkingApi } from 'api/frontoffice/parking';
import { frontofficePermitApi } from 'api/frontoffice/permit';
import { frontofficeOrganisationApi } from 'api/frontoffice/organisation';
import { backofficePermitApi } from 'api/backoffice/permit';
import { frontofficeFineApi } from 'api/frontoffice/fine';
import { useAuth } from 'context/authContext';

// interface ApiData {}

const ApiContext = React.createContext({});
export const useApi = () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.useContext(ApiContext) as Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const ApiProvider = (props: any): JSX.Element => {
    const authContext = useAuth();
    // Access search parameters
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [state, setState] = useState<Record<string, any>>({
        ParkingLots: [],
        Organisations: [],
        FineStatusOptions: [],
        PermitTypes: [],
        ParkingLotsForOrganisation: [],
    });

    const getBackOfficeData = async (): Promise<void> => {
        const ParkingLots = await backofficeParkingApi.getListOfParkingLots({
            limit: 1000,
            offset: 0,
            active: true,
            search_term: '',
            sort_col: 'address_name',
            sort_order: 'ASC',
            organisation_ids: '',
            minimal: true,
        });
        const Organisations =
            await backofficeOrganisationApi.getListOfOrganisations({
                limit: 1000,
                offset: 0,
                organisation_type_ids: '',
                show_active: true,
            });
        const FineStatusOptions =
            await backofficeFineAPI.getFineStatusOptions();
        const PermitTypes = await backofficePermitApi.getPermitTypes({
            include_all: true,
        });
        const ParkingLotsForOrganisation =
            await backofficeParkingApi.getAllParkingLots();
        setState({
            ...state,
            ParkingLots: ParkingLots.payload.data,
            Organisations: Organisations.payload.data,
            FineStatusOptions: FineStatusOptions.payload,
            PermitTypes: PermitTypes.payload,
            ParkingLotsForOrganisation: ParkingLotsForOrganisation.payload,
        });
    };

    const getFrontOfficeData = async (): Promise<void> => {
        const ParkingLots = await frontofficeParkingApi.getListOfParkingLots({
            limit: 1000,
            offset: 0,
            show_active: true,
            search_term: '',
            sort_col: 'address_name',
            sort_order: 'ASC',
            organisation_ids: '',
        });
        const PermitTypes = await frontofficePermitApi.getPermitTypes({
            include_all: true,
        });
        const FineStatusOptions = await frontofficeFineApi.getFineStatuses();
        const Organisations =
            await frontofficeOrganisationApi.getListOfOrganisations();
        const ParkingLotsForOrganisation =
            await frontofficeParkingApi.getParkingLotsForUserOrganisation();
        setState({
            ...state,
            ParkingLots: ParkingLots.payload.data,
            Organisations: Organisations.payload.data,
            FineStatusOptions: FineStatusOptions.payload,
            PermitTypes: PermitTypes.payload,
            ParkingLotsForOrganisation: ParkingLotsForOrganisation.payload,
        });
    };

    useEffect(() => {
        if (authContext.isAdmin) {
            getBackOfficeData();
        } else {
            getFrontOfficeData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ApiContext.Provider
            value={{
                ParkingLots: state.ParkingLots,
                Organisations: state.Organisations,
                FineStatusOptions: state.FineStatusOptions,
                PermitTypes: state.PermitTypes,
                ParkingLotsForOrganisation: state.ParkingLotsForOrganisation,
            }}
            {...props}
        />
    );
};

export default ApiProvider;
