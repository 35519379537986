import dansk from 'constants/lang-dansk';
import deutsche from 'constants/lang-deutsche';
import english from 'constants/lang-english';
import norsk from 'constants/lang-norsk';
import svenska from 'constants/lang-svenska';

const languages = ['dansk', 'english', 'deutsche', 'norsk', 'svenska'];

const languageObject = [
    { id: 'dansk', name: 'Dansk' },
    { id: 'english', name: 'English' },
    { id: 'norsk', name: 'Norsk' },
    { id: 'svenska', name: 'Sevenska' },
];

const translationResource = {
    dansk: { translation: dansk },
    english: { translation: english },
    deutsche: { translation: deutsche },
    norsk: { translation: norsk },
    svenska: { translation: svenska },
};

const languageMapper: Record<string, string> = {
    da: 'dansk',
    en: 'english',
};

export { languages, translationResource, languageObject, languageMapper };
