import { apiBase } from 'api/api';
import {
    GetUsersForOrganisationResponse,
    GetListOfOrganisationsResponse,
    GetMyOrganisationResponse,
} from 'api/interface/organisation';

const getMyOrganisation = async (): Promise<GetMyOrganisationResponse> => {
    const res = await apiBase.get(`/user/organisation`);
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.organisation,
    } as GetMyOrganisationResponse;
};

const getUsersForOrganisation = async (
    organisation_ids: Array<number>,
    parking_lot_ids: Array<number>
): Promise<GetUsersForOrganisationResponse> => {
    const ids = organisation_ids.join(',');
    const parking_lots = parking_lot_ids.join(',');
    const res = await apiBase.get(
        `/organisation/users?ids=${ids}&parking_lots=${parking_lots}`
    );

    if (res.status !== 200) {
        return {
            payload: undefined,
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.users,
    } as GetUsersForOrganisationResponse;
};

const getListOfOrganisations =
    async (): Promise<GetListOfOrganisationsResponse> => {
        const res = await apiBase.get(`/parkinglot/organisations`);

        if (res.status !== 200) {
            return {
                error: 'bad request',
                statusCode: res.status,
                payload: {
                    data: [],
                    total: 0,
                },
            };
        }

        return {
            error: null,
            statusCode: res.status,
            payload: {
                data: res.data.organisations,
                total: res.data.total,
            },
        } as GetListOfOrganisationsResponse;
    };

export const frontofficeOrganisationApi = {
    getMyOrganisation,
    getListOfOrganisations,
    getUsersForOrganisation,
};
