import { apiBase } from 'api/api';
import { downloadReport } from 'util/downloadReport';
import { ApiResponse } from 'api/interface/api';
import {
    GetPermitRequest,
    GetPermitResponse,
    BackofficeCreatePermitRequest,
    BackofficeCreatePermitResponse,
    BackofficeUpdatePermitRequest,
    BackofficeUpdatePermitResponse,
    BackofficeGetListOfPermitsRequest,
    GetListOfPermitsResponse,
    GetPermitTypesRequest,
    GetPermitTypesResponse,
    SendReceiptCopyRequest,
    SendReceiptCopyResponse,
    BackofficeGetPermitReportRequest,
    Permit1,
} from 'api/interface/permit';

const generatePermitReport = async (
    req: BackofficeGetPermitReportRequest
): Promise<ApiResponse> => {
    const res = await apiBase.get(
        `/backoffice/statistics/permit/report?from=${req.from}&to=${req.to}&status=${req.status}&type=${req.type}&limit=${req.limit}&offset=${req.offset}&sort_col=${req.sortcol}&sort_order=${req.sortorder}&search_term=${req.search_term}&organisation_ids=${req.organisation_ids}&parkinglot_ids=${req.parkinglot_ids}`,
        {
            responseType: 'blob',
        }
    );

    if (res.status !== 200) {
        if (res.status === 502) {
            return {
                error: 'Unable to generate report. The amount of permits were greater than 50000 - please apply filter to minimize the amout.',
                statusCode: res.status,
            };
        }
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    downloadReport(res);

    return {
        error: null,
        statusCode: res.status,
    } as ApiResponse;
};

const getPermit = async (req: GetPermitRequest): Promise<GetPermitResponse> => {
    const res = await apiBase.get(`/backoffice/permit/${Number(req.id)}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.permit,
    } as GetPermitResponse;
};

const createPermit = async (
    req: BackofficeCreatePermitRequest
): Promise<BackofficeCreatePermitResponse> => {
    const res = await apiBase.post('/backoffice/permit', {
        ...req,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data,
    } as BackofficeCreatePermitResponse;
};

const updatePermit = async (
    req: BackofficeUpdatePermitRequest
): Promise<BackofficeUpdatePermitResponse> => {
    const res = await apiBase.put(`/backoffice/permit`, {
        ...req,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as BackofficeUpdatePermitResponse;
};

const getListOfPermits = async (
    req: BackofficeGetListOfPermitsRequest
): Promise<GetListOfPermitsResponse> => {
    const res = await apiBase.get(
        `/v2/backoffice/permits?from=${req.from}&to=${req.to}&status=${req.status}&limit=${req.limit}&offset=${req.offset}&sort_order=${req.sortorder}&sort_col=${req.sortcol}&search_term=${req.search_term}&parkinglot_ids=${req.parkinglot_ids}&type=${req.type}&organisation_ids=${req.organisation_ids}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            data: [], // Return an empty array for data
            total: 0, // Set total to 0 in case of an error
        } as GetListOfPermitsResponse;
    }

    return {
        error: null,
        statusCode: res.status,
        data: res.data.data.map((item: Permit1) => ({
            permit: {
                id: item.permit.id,
                regno: item.permit.regno,
                type: item.permit.type,
                status: item.permit.status,
                start_date: item.permit.start_date,
                end_date: item.permit.end_date,
            },
            user: {
                username: item.user.username,
            },
            address: {
                address_name: item.address.address_name,
                city: item.address.city,
            },
            organisation: {
                name: item.organisation.name,
            },
        })),
        total: res.data.total,
    } as GetListOfPermitsResponse;
};

const getPermitTypes = async (
    req: GetPermitTypesRequest
): Promise<GetPermitTypesResponse> => {
    const res = await apiBase.get(
        `/backoffice/permit/types?include_all=${req.include_all}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.permit_types,
    } as GetPermitTypesResponse;
};

const sendReceiptCopy = async (
    req: SendReceiptCopyRequest
): Promise<SendReceiptCopyResponse> => {
    const res = await apiBase.post(`/backoffice/tickets/receipt`, {
        id: req.id,
        user_email: req.user_email,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: {},
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.success,
    } as SendReceiptCopyResponse;
};

export const backofficePermitApi = {
    generatePermitReport,
    getPermit,
    createPermit,
    updatePermit,
    getListOfPermits,
    getPermitTypes,
    sendReceiptCopy,
};
