import { AxiosResponse } from 'axios';

export const downloadReport = (res: AxiosResponse) => {
    const disposition = res.headers['content-disposition'];
    const filename = disposition.match(/filename=(.+)/)[1];

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
