import ReactDOM from 'react-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import App from 'App';
import 'index.css';
import reportWebVitals from 'reportWebVitals';
import AuthProvider from 'context/authContext';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationResource } from 'constants/lang';
import HttpsRedirect from 'HttpsRedirect';
import { SidebarProvider } from 'context/mobileSidebarContext';

i18next.use(initReactI18next).init({
    resources: translationResource,
    lng: 'dansk',
    fallbackLng: 'dansk',
    interpolation: { escapeValue: false },
});

ReactDOM.render(
    <HttpsRedirect disabled={false}>
        <AuthProvider>
            <SidebarProvider>
                <App />
            </SidebarProvider>
        </AuthProvider>
    </HttpsRedirect>,
    document.getElementById('root')
);

reportWebVitals();
