import { apiBase } from 'api/api';
import { ApiResponse } from 'api/interface/api';
import moment from 'moment';
import { downloadReport } from 'util/downloadReport';
import {
    GetListOfParkingLotsRequest,
    GetConnectedUsersForParkingLotRequest,
    GetConnectedUsersForParkingLotResponse,
    GetParkingLotRequest,
    GetParkingLotResponseBackoffice,
    UpdateParkingLotRequest,
    UpdateParkingLotResponse,
    DeleteSecondaryImageRequest,
    DeleteSecondaryImageResponse,
    UploadSecondaryImageRequest,
    UploadSecondaryImageResponse,
    UploadProfileImageRequest,
    UploadProfileImageResponse,
    CreateParkingRequest,
    CreateParkingResponse,
    ParkingSpotResponse,
    CreateTicketRuleResponse,
    CreateTicketRuleRequest,
    UpdateTicketRuleRequest,
    UpdateTicketRuleResponse,
    GetParkinglotTicketRulesRequest,
    GetListOfParkingLotsResponse,
    GetListOfParkingsResponse,
    ParkingTicketRulesResponseV2,
    GetTicketRuleByIdRequest,
    GetTicketRuleResponse,
    TicketRulesv2,
} from 'api/interface/parking';

const getListOfParkingLots = async (
    req: GetListOfParkingLotsRequest
): Promise<GetListOfParkingsResponse> => {
    const res = await apiBase.get(
        `/backoffice/parkinglots?limit=${req.limit}&offset=${
            req.offset
        }&organisation_ids=${req.organisation_ids}&show_active=${
            req.active
        }&search_term=${req.search_term}&sort_order=${
            req.sort_order
        }&sort_col=${req.sort_col}&minimal=${
            req.minimal
        }&show_spots_and_groups=${req.showSpotsAndGroups || false}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: {
                total: 0,
                data: [],
            },
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: {
            data: res.data.parking_lots,
            total: res.data.total,
        },
    } as GetListOfParkingsResponse;
};

const getParkingLot = async (
    req: GetParkingLotRequest
): Promise<GetParkingLotResponseBackoffice> => {
    const res = await apiBase.get(`/backoffice/parkinglot/${req.id}`);
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    if (res.data.parking_lot.permit_length === null) {
        res.data.parking_lot.permit_length = 0;
    }
    if (res.data.parking_lot.no_permits === null) {
        res.data.parking_lot.no_permits = 0;
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.parking_lot,
    } as GetParkingLotResponseBackoffice;
};

const updateParkingLot = async ({
    updatedParkingLot,
}: UpdateParkingLotRequest): Promise<UpdateParkingLotResponse> => {
    const res = await apiBase.put(`/backoffice/parkinglot`, {
        ...updatedParkingLot,
    });
    if (res.status !== 200) {
        return {
            error: res.data.message,
            statusCode: res.status,
        };
    }

    return {
        statusCode: res.status,
        error: null,
        payload: res.data.payload,
    } as UpdateParkingLotResponse;
};

const deleteSecondaryImage = async (
    req: DeleteSecondaryImageRequest
): Promise<DeleteSecondaryImageResponse> => {
    const res = await apiBase.post('parkinglot/image/delete', {
        id: req.address_id,
        image: req.fileName,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as DeleteSecondaryImageResponse;
};

const uploadSecondaryImage = async (
    req: UploadSecondaryImageRequest,
    userId: number
): Promise<UploadSecondaryImageResponse> => {
    const data = new FormData();
    data.append('user_id', String(userId));
    data.append('status', String(0));
    data.append('deleted', String(0));
    data.append('address_id', req.address_id.toString());
    for (let i = 0; i < req.arrayFile.length; ++i) {
        data.append('file', req.arrayFile[i]);
        data.append(
            'timestamp',
            moment(new Date()).format('YYYY-MM-DD hh mm ss').toString()
        );
    }

    const res = await apiBase.post('parkinglot/image', data);
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            images: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
        images: res.data.images,
    } as UploadSecondaryImageResponse;
};

const uploadProfileImage = async (
    req: UploadProfileImageRequest
): Promise<UploadProfileImageResponse> => {
    const data = new FormData();
    data.append('id', req.address_id.toString());
    for (let i = 0; i < req.files.length; ++i) {
        data.append('file', req.files[i]);
        data.append(
            'timestamp',
            moment(new Date()).format('YYYY-MM-DD hh mm ss').toString()
        );
    }
    const res = await apiBase.post('parkinglot/top-image', data);
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data,
        image: res.data.image,
    } as UploadProfileImageResponse;
};

const createParking = async (
    req: CreateParkingRequest
): Promise<CreateParkingResponse> => {
    delete req.file;
    delete req.start_date;
    delete req.created_at;
    const res = await apiBase.post('backoffice/parkinglot', req);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: {
            message: res.data.success,
            address_id: res.data.address_id,
        },
    } as CreateParkingResponse;
};

const generateParkingLotReport = async (
    req: GetListOfParkingLotsRequest
): Promise<ApiResponse> => {
    const res = await apiBase.get(
        `/backoffice/statistics/parkinglot/report?organisation_ids=${req.organisation_ids}&show_active=${req.active}&search_term=${req.search_term}&sort_order=${req.sort_order}&sort_col=${req.sort_col}`,
        {
            responseType: 'blob',
        }
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    downloadReport(res);

    return {
        error: null,
        statusCode: res.status,
    } as ApiResponse;
};

const getParkingSpots = async (
    parkingLotId: number
): Promise<ParkingSpotResponse> => {
    const res = await apiBase.get(
        `/backoffice/parkinglot/${parkingLotId}/spots`
    );
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: {
            data: res.data.available_parking_spots,
            total: res.data.available_parking_spots.length,
        },
    } as ParkingSpotResponse;
};

const getConnectedUsersForParkingLot = async (
    req: GetConnectedUsersForParkingLotRequest
): Promise<GetConnectedUsersForParkingLotResponse> => {
    const res = await apiBase.get(`/backoffice/parkinglot/${req.id}/users`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.connected_users,
    };
};

const getParkinglotTicketRules = async (
    req: GetParkinglotTicketRulesRequest
): Promise<ParkingTicketRulesResponseV2> => {
    const res = await apiBase.get(
        `/v2/backoffice/tickets/rules?offset=${req.offset}&limit=${req.limit}&sort_col=${req.sort_col}&sort_order=${req.sort_order}&search_term=${req.search_term}&parkinglot_ids=${req.parkinglot_ids}&organisation_ids=${req.organisation_ids}&type=${req.type}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            data: [],
            total: 0,
        } as ParkingTicketRulesResponseV2;
    }

    const mappedData: ParkingTicketRulesResponseV2[] = res.data.data.map(
        (item: TicketRulesv2) => ({
            ticket: {
                id: item.ticket.id,
                url_id: item.ticket.url_id,
                type: item.ticket.type,
            },
            address: {
                address_name: item.address.address_name,
                city: item.address.city,
            },
        })
    );

    return {
        error: null,
        statusCode: res.status,
        data: mappedData,
        total: res.data.total,
    } as unknown as ParkingTicketRulesResponseV2;
};

const getTicketRulesById = async (
    req: GetTicketRuleByIdRequest
): Promise<GetTicketRuleResponse> => {
    const res = await apiBase.get(`/v2/tickets/rules/${req.ID}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            data: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        data: res.data.data,
    } as GetTicketRuleResponse;
};

const createParkinglotTicketRule = async (
    req: CreateTicketRuleRequest
): Promise<CreateTicketRuleResponse> => {
    const ticket_rules = req.ticket_rules.map((ticketRuleOption) => ({
        ...ticketRuleOption,
    }));

    const res = await apiBase.post(`/v2/backoffice/tickets/rules`, {
        url_id: req.name,
        parking_address_id: req.address_id,
        ticket_rules,
        service_fee: req.service_fee,
        type: req.type,
        external_note: req.external_note,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as CreateTicketRuleResponse;
};

const updateParkinglotTicketRule = async (
    req: UpdateTicketRuleRequest
): Promise<UpdateTicketRuleResponse> => {
    const updatedTicketsRules = req.ticket_rules.map((ticketRuleOption) => {
        return {
            ...ticketRuleOption,
        };
    });
    const res = await apiBase.put(`/v2/backoffice/tickets/rules`, {
        parking_address_id: req.address_id,
        ticket_rules: updatedTicketsRules,
        old_url_id: req.url_id,
        url_id: req.name,
        service_fee: req.service_fee,
        external_note: req.external_note,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as UpdateTicketRuleResponse;
};

const getAllParkingLots = async (): Promise<GetListOfParkingLotsResponse> => {
    const res = await apiBase.get(`/backoffice/parkinglot/all`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.parking_lot,
    } as GetListOfParkingLotsResponse;
};

export const backofficeParkingApi = {
    getListOfParkingLots,
    getParkingLot,
    updateParkingLot,
    createParking,
    uploadSecondaryImage,
    uploadProfileImage,
    generateParkingLotReport,
    deleteSecondaryImage,
    getParkingSpots,
    getConnectedUsersForParkingLot,
    getTicketRulesById,
    getParkinglotTicketRules,
    createParkinglotTicketRule,
    updateParkinglotTicketRule,
    getAllParkingLots,
};
