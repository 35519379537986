import React from 'react';
import Loading from 'assets/images/loading.gif';

export const LoadingScreen = (): JSX.Element => {
    return (
        <div className="flex min-h-screen w-full items-center justify-center bg-opacity-5 bg-white">
            <img src={Loading} width="80" alt="" />
        </div>
    );
};

export default LoadingScreen;
