import { apiBase } from 'api/api';
import {
    GetOverviewStatisticsResponse,
    GetUsersForOrganisationResponse,
    GetListOfOrganisationsRequest,
    GetListOfOrganisationsResponse,
    GetOrganisationRequest,
    GetOrganisationResponse,
    GetParkingLotsForOrganisationResponse,
    CreateOrganizationRequest,
    CreateOrganizationResponse,
    UpdateOrganisationRequest,
    UpdateOrganisationResponse,
} from 'api/interface/organisation';

const getOverviewStatistics =
    async (): Promise<GetOverviewStatisticsResponse> => {
        const organisationsRes = await apiBase.get(
            '/backoffice/organisation-count'
        );
        if (organisationsRes.status !== 200) {
            return {
                statusCode: organisationsRes.status,
                error: 'could not get data',
                organisations: 0,
                parkingLots: 0,
                users: 0,
            } as GetOverviewStatisticsResponse;
        }
        const usersRes = await apiBase.get('/backoffice/user-count');
        if (usersRes.status !== 200) {
            return {
                statusCode: usersRes.status,
                error: 'could not get data',
                organisations: 0,
                parkingLots: 0,
                users: 0,
            } as GetOverviewStatisticsResponse;
        }
        const parkingLotsRes = await apiBase.get(
            `/backoffice/parkinglots-count`
        );
        if (parkingLotsRes.status !== 200) {
            return {
                statusCode: parkingLotsRes.status,
                error: 'could not get data',
                organisations: 0,
                parkingLots: 0,
                users: 0,
            } as GetOverviewStatisticsResponse;
        }
        return {
            statusCode: 200,
            error: null,
            organisations: organisationsRes.data.data,
            users: usersRes.data.data,
            parkingLots: parkingLotsRes.data.data,
        } as GetOverviewStatisticsResponse;
    };

const getUsersForOrganisation = async (
    organisation_ids: Array<number>
): Promise<GetUsersForOrganisationResponse> => {
    const ids = organisation_ids.join(',');

    const res = await apiBase.get(`/backoffice/organisation/users?ids=${ids}`);

    if (res.status !== 200) {
        return {
            payload: undefined,
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.users,
    } as GetUsersForOrganisationResponse;
};

const getListOfOrganisations = async (
    req: GetListOfOrganisationsRequest
): Promise<GetListOfOrganisationsResponse> => {
    const sortOrder = req.sortorder || 'ASC';
    const sortCol = req.sortcol || 'name';
    const search_term = req.search_term || '';
    const organisation_type_ids = req.organisation_type_ids || '';
    const show_active = req.show_active || '';
    const res = await apiBase.get(
        `/backoffice/organisations?limit=${req.limit}&offset=${req.offset}&sort_col=${sortCol}&sort_order=${sortOrder}&organisation_type_ids=${organisation_type_ids}&show_active=${show_active}&search_term=${search_term}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: {
                data: [],
                total: 0,
            },
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: {
            data: res.data.organisations,
            total: res.data.total,
        },
    } as GetListOfOrganisationsResponse;
};

const getOrganisation = async (
    req: GetOrganisationRequest
): Promise<GetOrganisationResponse> => {
    const res = await apiBase.get(`/backoffice/organisation/${req.id}`);
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.organisation,
    } as GetOrganisationResponse;
};

const getParkingLotsForOrganisation = async (req: {
    organisation_id: number;
}): Promise<GetParkingLotsForOrganisationResponse> => {
    const res = await apiBase.get(
        `/backoffice/organisation/parkinglots/${req.organisation_id}`
    );

    if (res.status !== 200) {
        return {
            payload: undefined,
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.parking_lot,
    } as GetParkingLotsForOrganisationResponse;
};

const createOrganization = async (
    req: CreateOrganizationRequest
): Promise<CreateOrganizationResponse> => {
    delete req.organisation_type_id;
    delete req.created_at;
    delete req.updated_at;
    const res = await apiBase.post('backoffice/organisation', {
        ...req,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as CreateOrganizationResponse;
};

const updateOrganisation = async (
    req: UpdateOrganisationRequest
): Promise<UpdateOrganisationResponse> => {
    const res = await apiBase.put(`/backoffice/organisation/${req.id}`, {
        ...req,
    });
    const { status } = res;

    if (status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    const { payload } = res.data && res.data;
    return {
        error: null,
        statusCode: status,
        payload,
    } as UpdateOrganisationResponse;
};

export const backofficeOrganisationApi = {
    getOverviewStatistics,
    getUsersForOrganisation,
    getListOfOrganisations,
    getOrganisation,
    getParkingLotsForOrganisation,
    updateOrganisation,
    createOrganization,
};
