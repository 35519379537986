const svenska = {
    // Generic
    Update: 'Uppdatera',
    Cancel: 'Tillbaka',
    Create: 'Skapa',
    Delete: 'Radera',
    Upload: 'Ladda upp',
    Close: 'Stäng',
    Read: 'Läs',
    'Generate Report': 'Generera rapport',
    'Search the table': 'Sök i tabellen',
    Page: 'Sida',
    of: 'av',
    Images: 'Bilder',
    Dates: 'Datum',
    Date: 'Datum',
    Note: 'Notera',
    Status: 'Status',
    Country: 'Land',
    'Select all': 'Välj alla',
    Allow: 'Tillåt',
    Business: 'Företag',
    Search: 'Sök',
    More: 'Mer',
    Hide: 'Dölj',
    Other: 'Övrig',
    Save: 'Spara',
    Unknown: 'Okänd',
    Choose: 'Välja',
    Website: 'Hemsida',
    more: 'mer',
    'Created date': 'Skapat',
    AS: 'SOM',
    Return: 'Tillbaka',
    Percentage: 'Procent',
    Count: 'Räkna',
    Extra: 'Extra',
    // User
    Username: 'Användarnamn',
    Password: 'Lösenord',
    'Created By': 'Skapat Av',
    'Created by': 'Skapat av',
    'Created Date': 'Skapat datum',
    'Last login web': 'Senaste inloggningswebben',
    'Last login app': 'Senaste inloggningsappen',
    Admin: 'Admin',

    // Menu
    Home: 'Hem',
    Dashboard: 'Dashboard',
    'Parking Lots': 'Parkeringsplatser',
    'Parking lots': 'Parkeringsplatser',
    'Map of Parking Lots': 'Karta över parkeringsplatser',
    Users: 'Användare',
    Permits: 'Tillstånd',
    Fines: 'Böter',
    Customers: 'Kunder',
    Backoffice: 'Backoffice',
    Map: 'Karta',
    Statistics: 'Statistik',
    Subscriptions: 'Prenumerationer',
    'Ticket Service': 'Köp av P-biljett',
    'Buy a permit': 'Köp av P-biljett',
    'Get a permit': 'Få en P-biljett',
    'Private Ticket Service': 'Få en p-billett',
    Reports: 'Rapporterar',
    Rent: 'Hyra',
    Prices: 'Priser',

    // Settings
    Settings: 'Inställningar',
    'User Details': 'Användarinformation',
    Language: 'Språk',
    'Parking Lot': 'Parkeringsplats',
    'Parking lot': 'Parkeringsplats',
    Street: 'Gata',
    'Street Number': 'Gatunummer',
    ZipCode: 'Postnummer',
    City: 'Stad',
    'Notification settings': 'Aviseringsinställningar',
    'WELCOME TO': 'VÄLKOMMEN TILL',

    // Login
    Login: 'Inloggning',
    'Forgot Password': 'Glömt Lösenord',
    Email: 'Email',
    'Reset Password': 'Återställ lösenord',
    'Password reset email sent. Please check your email.':
        'Email om lösenordsåterställning har skickats. Kontrollera din email',
    'Something went wrong': 'Något gick fel',
    'Wrong username or password':
        'Feil ved tilbakestilling av passord. Prøv på nytt',
    'Error resetting password, please retry':
        'Det gick inte att återställa lösenordet, försök igen',
    'Digital parking solution for every need':
        'Digital parkeringslösning för varje behov',

    // Search bar
    'None elected': 'Ingen vald',
    'All selected': 'Alla valda',
    'Only active': 'Endast aktiv',
    'Show cancelled': 'Visa inställt',

    // Frontoffice
    'Latest fines': 'Senaste böter',
    'No data available!': 'Ingen data tillgänglig!',
    'There were no datapoints to show on this graph':
        'Det fanns inga datapunkter att visa på detta diagram',
    'Good fines': 'Goda böter',
    Annulled: 'Annullerad',
    'Could not fetch fines - please refresh and try again':
        'Kunde inte hämta böter - var god uppdatera och försök igen',
    'Something went wrong, could not find any users - Please refresh and try again.':
        'Något gick fel, kunde inte hitta några användare - uppdatera och försök igen.',
    Activated: 'Aktiverad',
    Customer: 'Kund',
    'User list': 'Användarlista',
    'Create User': 'Skapa användare',
    Yes: 'Ja',
    No: 'Nej',
    'Permit list': 'Tillståndslista',
    'Create Permit': 'Skapa tillstånd',
    'Start date': 'Start datum',
    'Start Date': 'Start Datum',
    'End date': 'slutdatum',
    'End Date': 'slutdatum',
    Type: 'Typ',
    'Could not find any parking lots - Please refresh and try again.':
        'Kunde inte hitta några parkeringsplatser - var god uppdatera och försök igen.',
    'Parking Lots List': 'Parkeringsplatslista',
    Address: 'Adress',
    'Parking Map': 'Parkeringskarta',
    'Could not find any hourly data - Please refresh and try again.':
        'Kunde inte hitta några timmarsdata - uppdatera och försök igen.',
    'Something went wrong, could not find any daily data - Please refresh and try again.':
        'Något gick fel, kunde inte hitta några dagliga data - uppdatera och försök igen.',
    'Something went wrong, could not find any look up data - Please refresh and try again.':
        'Något gick fel, kunde inte hitta några uppåtgående data - uppdatera och försök igen.',
    'Hourly visits count': 'Timbesök räknas',
    'Daily visits count': 'Dagliga besök räknas',
    'Fine list': 'Finlista',
    'Could not generate the report - please refresh and try again':
        'Kunde inte generera rapporten - uppdatera och försök igen',
    'Country code': 'Landskod',
    cancelFine: 'Varför avbryter du detta bötesbelopp? (min. 20 tecken)',
    Comments: 'Kommentarer',
    'Cancelled fines': 'Annullerade avgifter',
    'Active fines': 'Aktiva avgifter',
    'Total fines': 'Total fines',
    'Parking Lot list': 'Parkeringsplatsliste',
    'Reg. nr.': 'Reg. nr.',
    'Your Cars': 'Dina bilar',
    'Location Name': 'Platsnamn',
    'Country and reg. no.': 'Country and reg. no.',

    // Backoffice
    'Customer list': 'Kundlista',
    'Something went wrong in searching for customers':
        'Något gick fel när du letar efter kunder',
    'Create Customer': 'Skapa kund',
    Name: 'namn',
    'Create Fine': 'Skapa bra',
    'Generate DMR report': 'Generera DMR-rapporten',
    'Advanced Report': 'Avancerad rapport',
    'Mass change': 'Massförändring',
    'Customers info': 'Kunder information',
    'Accounting info': 'Bokföringsinfo',
    'Create p-lot': 'Skapa p-lot',
    'Organization list': 'Organisationslista',
    Add: 'Lägg till',
    'Parking Lot Info': 'Parkeringsplats Info',
    'Add ruleset': 'Lägg till regeluppsättning',
    'Show Spots and Groups': 'Visa platser och grupper',

    // Modals
    'Send Password': 'Skicka lösenord',
    'Settlement dates': 'Avvecklingsdatum',
    Offence: 'Anfall',
    Contractor: 'Hantverkar',
    'Incident information': 'Incidentinformation',
    'Incident Information': 'Incidentinformation',
    'Leave a comment...': 'Lämna en kommentar...',
    Latitude: 'Latitud',
    'Parking Lot Details': 'Parkeringsplats detaljer',
    'Profile Image': 'Profilbild',
    'Street Name': 'Gatunamn',
    'House.No': 'hus nr',
    'Postal Code': 'postnummer',
    'Parking Terms': 'Parkeringsvillkor',
    'Fine Price': 'bötfäller pris',
    '% for PK': '% för PK',
    'Loading...': 'Läser in...',
    'Could not find the parking lot you were looking for.':
        'Det gick inte att hitta den parkeringsplats du letade efter.',
    'Successfully updated the parking lot!':
        'Uppdaterade parkeringsplatsen framgångsrikt!',
    'Successfully created new parking lot!':
        'Framgångsrikt skapat ny parkeringsplats!',
    'You can now close the modal.': 'Du kan nu stänga modalen.',
    'Geographic coordinate': 'Geografisk koordinat',
    'Camera Fines': 'Kameraavgifter',
    Longitude: 'Longitud',
    'Rules for parking': 'Regler för parkering',
    'Rules for guest parking': 'Regler för gästparkering',
    'Show customer': 'Visa kund',
    'Number of active permits created by one user at the same time':
        'Antal aktiva tillstånd lagrade av en användare samtidigt',
    'Maximum length for created permits in app':
        'Maximal längd för skapade tillstånd i ca',
    'Note for app': 'Anteckning för app',
    'Control Type': 'Kontrolltyp',
    'Camera control': 'Kamerakontroll',
    'Camera Control': 'Kamerakontroll',
    'Guard control': 'Skyddskontroll',
    'Guard Control': 'Skyddskontroll',
    'Self control': 'Självkontroll',
    'Self Ticketing': 'Själv biljettförsäljning',
    'Need for collection': 'Behov av insamling',
    'Parking Spots': 'Parkeringsstånd',
    'Rent?': 'Hyra?',
    Distribution: 'Distribution',
    'Amount of Duty': 'Tullbelopp',
    'Why are you canceling this Fine? - Minimum 20 characters':
        'Varför avbryter du det här böterna? - Minst 20 tecken',
    'Send and Cancel Fine': 'Skicka och avbryta bra',
    'Cancel Fine': 'Avbryt fint',
    'Could not find the fine you were looking for.':
        'Det gick inte att hitta den fina du letade efter.',
    'Could not find the permit you were looking for.':
        'Kunde inte hitta det tillstånd du letade efter.',
    'Belongs to': 'Tillhör',
    'Internal note': 'Interna anteckning',
    'External note': 'Extern anteckning',
    'Could not create the permit - close and try again please':
        'Kunde inte skapa tillståndet - stäng och försök igen tack',
    'Could not update the permit - close and try again please':
        'Kunde inte uppdatera tillståndet - stäng och försök igen tack',
    'User successfully created, you can close the modal again':
        'Användare framgångsrikt skapad, du kan stänga modalen igen',
    'User successfully updated, you can close the modal again':
        'Användare som är framgångsrikt uppdaterad, du kan stänga modalen igen',
    'User credentials': 'Användaruppgifter',
    'Confirm password': 'Bekräfta lösenord',
    'The password matches': 'Lösenordet matchar',
    'The password does not matches': 'Lösenordet matchar inte',
    Permissions: 'Behörighet',
    'Users to manage': 'Användare att hantera',
    'Activate User': 'Aktivera Användare',
    'Access to Website': 'Tillgång till webbplats',
    'Access to App': 'Tilgang til App',
    'Access to API': 'Tilgang til API',
    'Handle Fines': 'Hantera böter',
    'Handle Permits': 'Hantera tillstånd',
    User: 'Användare',
    Permit: 'Tillåta',
    Fine: 'Bra',
    'Update customer': 'Uppdatera kund',
    'Customer Type': 'kundtyp',
    Created: 'Skapad',
    Updated: 'Uppdaterad',
    'Connected Users': 'Anslutna användare',
    'Connected parking lots': 'Tillhörande parkeringsplatser',
    'Total amount of users:': 'Total mängd användare:',
    'Fine successfully updated, you can close the modal again':
        'Fint framgångsrikt uppdaterad, du kan stänga modalen igen',
    'Fine successfully created, you can close the modal again':
        'Fina framgångsrikt skapad, du kan stänga modalen igen',
    'Update Fine': 'Uppdatera bra',
    'New Fine': 'Ny böter',
    Owner: 'Ägare',
    'Parking guard': 'Parkeringsvakt',
    'House number': 'Hus nummer',
    'House nr.': 'Hus nummer',
    'Open Parking Lot': 'Öppen parkeringsplats',
    'Update parking lot': 'Uppdatera parkeringsplats',
    'External Customers': 'Externa kunder',
    'Back to Fine': 'Tillbaka till bra',
    'Update user': 'Uppdatera användaren',
    'Update User': 'Uppdatera Användare',
    'New user': 'Ny användare',
    'Update subscription': 'Uppdatera prenumeration',
    'New subscription': 'Nytt abonnemang',
    'Create subscription': 'Skapa prenumeration',
    'Create Subscription': 'Skapa Prenumeration',
    'Creating subscription...': 'Skapar prenumeration',
    'Please select a company': 'Var god välj ett företag',
    "Customer's parking lots": 'Kundens parkeringsplatser',
    'User Identity': 'Användaridentitet',
    Identity: 'Identitet',
    'User Identities': 'Användaridentiteter',
    'No identities for user': 'Inga identiteter för användare',
    'Fine Details': 'Fina detaljer',
    'No one has commented or changed status yet.':
        'Ingen har kommenterat eller ändrat status än.',
    'Leave a note on the incident': 'Lämna en anteckning på händelsen',
    'Update Fines': 'Uppdatera böter',
    'This will reset the password and email the new password. Do you want to continue?':
        'Dadurch wird das Passwort zurückgesetzt und das neue Passwort per E-Mail gesendet. Möchtest du weiter machen?',
    'Parking Info': 'Parkeringsinformation',
    Organizations: 'Organisationer',
    Organization: 'Organisation',
    'Payout Details': 'Utbetalningsinformation',
    'Bank Registration Number': 'Bankregistreringsnummer',
    'Bank Account Number': 'Bankkontonummer',
    '% for parking guard': '% för parkeringsvakt',
    '% for customer': '% till kund',
    'Charge Distributions': 'Avgiftsfördelningar',
    'There are no Parking Lots': 'Det finns inga parkeringsplatser',
    'There are no Users': 'Det finns inga användare',
    'External Organizations': 'Externa organisationer',
    'Select image': 'Välj bild',
    'Outside booth': 'Utanför bås',
    'No permit to the parking area': 'Inget tillstånd till parkeringsplatsen',
    'P-disc exceeded': 'P-skiva överskriden',
    'Parking or stopping in prohibited area':
        'Parkering eller stopp i förbjudet område',
    'Parking in fire lane': 'Parkering på brandvägen',
    'Handicap booth without permission': 'Handicapbås utan tillstånd',
    "Organization's Parking Lots": 'Organisationens parkeringsplatser',
    'Choose Parking bays': 'Välj Parkeringsplatser',
    'Permanent user': 'Permanent användare',
    'New Permit': 'Nytt tillstånd',
    'Update Permit': 'Uppdatera Tillstånd',
    'Created for user': 'Skapat för användaren',
    Notes: 'Anteckningar',
    'Permit Information': 'Tillåtainformation',
    'Date not assigned': 'Datum ej tilldelat',
    'Note from creation': 'Notering från skapandet',
    'Created at': 'Skapad',
    'Updated at': 'Uppdaterad',
    'Address name': 'Adressnamn',
    'Address Name': 'Adressnamn',
    'Arrival date': 'Ankomstdatum',
    'Arrival Date': 'Ankomstdatum',
    'Depart. Date': 'Avresedatum',
    'Depart. date': 'Avresedatum',
    Camera: 'Kamera',
    'Other - Leave a note': 'Övrigt - Lämna en anteckning',
    'Parking time exceeded': 'Parkeringstiden överskred',
    'Total parking time': 'Total parkeringstid',
    Approve: 'Godkänn',
    Decline: 'Avslå',
    Back: 'Tillbaka',
    'Employee Paid Ticket': 'Employee Paid Ticket',
    Details: 'Detaljer',
    'Street name': 'Gatunamn',
    'Postal code': 'Postnummer',
    'Fine price': 'Avgiftspris',
    'No results found': 'Inga resultat funna',
    'Buffer time in minutes': 'Bufferzeit in Minuten',
    'Active permits': 'Aktiva tillstånd',
    'Duration of permits': 'Behörigheter varaktighet',
    selected: 'valda',
    Distance: 'Avstånd',
    'Organization type': 'Organisationstyp',
    'Organization address': 'Organisation adress',
    'Organization details': 'Organisationsinformation',
    'Create Organization': 'Skapa Organisation',
    'The permit will be made for every parking lot chosen, and based on the users start and end date.':
        'The permit will be made for every parking lot chosen, and based on the users start and end date.',
    'Comment added by': 'kommentar tillagd av',
    'Status changed from': 'Status ändrad från',
    'Status changed by': 'Status endret av',
    'Not defined': 'Inte definierad',
    'Not Defined': 'Inte definierad',
    'Self ticketing': 'Själv biljettförsäljning',
    Combination: 'Kombination',
    'P-guard': 'Trafikvaktmästare',
    'Registered before': 'Tidigare registreringar',
    'You already have a user': 'Du har redan en användare',
    'Login to purchase an extra subscription':
        'Logga in för att köpa en extra prenumeration',
    'Missing payment in min': 'Saknad betalning i min',
    'Open Subscriber': 'Öppna prenumerant',
    'Every permit row is clickable and show data in Permit Modal':
        'Varje tillståndsrad är klickbar och visar data i Permit Modal',
    'Choosing multiple parking lots creates a singular permit for each address selected':
        'Att välja flera parkeringsplatser skapar ett unikt tillstånd för varje vald adress',
    'These rules only affects the permits created from the app, not permits created by admin from Web':
        'Dessa regler påverkar endast tillståndet som skapats från App, inte från skapat av admin från webben',
    '0 = none and empty = unlimited': '0 = none and empty = unlimited',
    VAT: 'CVR',
    // Finance
    DKK: 'DKK',
    pcs: 'st',
    k: 't',
    Revenue: 'Intäkter',
    'Paid Permits': 'Betalande tillstånd',
    'Coming soon': 'Coming soon',
    'Total revenue': 'Totala intäkter',
    'Total paid out': 'Summa utbetalt',
    'Total unsettled': 'Totala utestående',
    'Permit revenue sources in DKK': 'Tillåt intäktskällor i DKK',
    'Number of permits per parking operator':
        'Antal tillstånd per parkeringsoperatör',
    'Number of parking tickets made through each parking operator.':
        'Antal parkeringsböter som utfärdats genom varje parkeringsoperatör. ',
    'You have no permits to show': 'Du har inga tillstånd att visa upp',
    'Revenue by type in DKK': 'Intäkter per typ i DKK',
    'Fines by status': 'Böter efter status',
    'Post collections': 'Efterföljande samlingar',
    'Post collection': 'Efterföljande samlingar',

    'Active: status explained':
        'Aktiva: En böter som har skapats men fortfarande är under process',
    'Cancelled: status explained':
        'Inställt: Den är avbruten, kontrollera dess kommentar eller notering för varför',
    'Paid: status explained':
        'Betalt: Böterna har betalats till Parkingskompagniet',
    'Settled: status explained': 'Avgjord: Böterna har betalats till kunden',
    'Protected: status explained': 'Skyddad: Den är tillfälligt pausad',
    'Debt collection/Collectia: status explained':
        'Indrivning/Collectia: Skuldindrivningen pågår.',

    revenue_by_type_explanation:
        'Intäkterna baseras på de intäkter som genereras från de parkeringsplatser som din organisation äger.',
    'Fines: Every fine that is paid or settled':
        'Böter: Varje böter som betalas eller regleras',
    'Permits: Every permit that is paid':
        'Tillstånd: Varje tillstånd som betalas',
    'Subscriptions: Every payment on all subscriptions':
        'Prenumeration: Varje betalning på alla prenumerationer',
    'Post Collection: Coming soon': 'Efterinsamling: Kommer snart',

    permit_revenue_sources_explanation:
        'Intäktsbeloppet i kr, som har gjorts på tillstånd för respektive organisation.',
    number_of_permits_per_parking_operator_explained:
        'Antalet tillstånd som har utfärdats av varje parkeringsoperatör.',

    fine_status_distribution_explanation:
        'Den procentuella fördelningen av böter.',

    // Statistic
    fee_search: 'Gammal App',
    fine_search: 'App - böter skapad',
    permit_search: 'App - tillstånd skapad',
    scanner_used: 'App - Scanner används',
    'Shows a graph of app events for multiple days, sorted by hours.':
        'Visar en graf över apphändelser under flera dagar, sorterade efter timmar.',
    'Shows a graph of app events, sorted by dates.':
        'Visar en graf över apphändelser, sorterade efter datum.',
    'The use of the old app.': '- Användning av den gamla appen.',
    'Creation of permits in the oparko app.':
        '- Skapande av tillstånd i oparko-appen.',
    'Creation of Fines in the oparko app':
        '- Skapande av böter i oparko-appen.',
    'Scanning of license plates in the oparko app.':
        '- Skanning av registreringsskyltar i oparko-appen.',
    'Permits and Subscriptions per month':
        'Tillstånd och prenumerationer per månad',
    'Occupancy by permits': 'Beläggning efter tillstånd',
    'Total parking spots': 'Totalt antal parkeringsplatser',
    'Permits: Shows the amount of permits by period. If more than one week is chosen, the sum of all weeks will be shown on a give period on a weekday.':
        'Tillstånd: Visar antalet tillstånd per period. Om mer än en vecka väljs visas summan av alla veckor för en given period på en veckodag.',
    'Total parking spots: Shows the total amount of parking spots':
        'Totalt antal parkeringsplatser: Visar det totala antalet parkeringsplatser',
    'Lowest avg for the days in the period.':
        '- Lägsta genomsnitt för dagarna under perioden.',
    'Highest avg for the days in the period.':
        '- Högsta genomsnitt för dagarna under perioden.',
    'Avg for the days in the period.':
        '- Genomsnitt för dagarna under perioden.',
    'Average for the chosen period': 'Genomsnitt för den valda perioden',
    // Date Picker
    Between: 'Mellan',
    From: 'Från',
    To: 'Till',
    Unit: 'Enhet',
    Max: 'Max antal',
    Min: 'Minsta antal',
    Prior: 'Före',
    Custom: 'Anpassa',
    Today: 'I dag',
    'Since Yesterday': 'Efter gårdagen',
    'This Week': 'Denna vecka',
    Anytime: 'Närsomhelst',
    'Last month': 'Förra md',
    'Last 3 months': 'Senaste 3 mdr',
    'Last 12 months': 'Senaste 12 mdr',
    Ticket_Rule: 'Biljettregel',
    Rule_sets: 'Regelset',
    InformationGuide: 'Informationsguide',
    RuleSetsDescription:
        "Under 'Regelset' kan du definiera unika biljettregler. Du kan skapa så många du behöver:",
    PickDays: 'Välj vilka dagar reglerna gäller för',
    PickTime: 'Välj tid på dagen',
    PickUnit:
        'Välj enheten följt av “minsta” och “största” antal timmar/dagar som kan köpas med denna regel',

    // Table Values
    company: 'företag',
    private: 'privat',
    Inactive: 'Inaktiv',
    Active: 'Aktiva',
    Paid: 'Betalt',
    Settled: 'Avgjord',
    Hidden: 'Inställt',
    Protected: 'Skyddad',
    'Guest parking': 'Gästparkering',
    Ticket: 'Biljett',
    'Paid Ticket': 'Betald biljett',
    'Only for backoffice': 'Endast för backoffice',
    'Editable permit': 'Redigerbart tillstånd',
    Employee: 'Anställd',
    'Permanent permit': 'Permanent tillstånd',
    'Debt collection': 'Indrivning',
    Collectia: 'Collectia',
    'Active - handled manually': 'Aktiv - hanteras manuellt',
    'Active - System': 'Aktivt - System',
    // Validation
    'Issues: ': `Problem: `,
    'no Name chosen, ': 'inget Namn valt, ',
    'no Organization chosen, ': 'ingen Organisation vald, ',
    'no Street Name chosen, ': 'inget Gatunamn valt, ',
    'no Street Number chosen, ': 'inget Gatunummer valt, ',
    'no Postal Code chosen, ': 'inget Postnummer valt, ',
    'no City chosen, ': 'ingen Stad vald, ',
    'no Latitude chosen, ': 'ingen Latitud vald, ',
    'Latitude must be above -90, ': 'Latitud måste vara över -90, ',
    'Latitude must be below 90, ': 'Latitud måste vara under 90, ',
    'no Longitude chosen, ': 'ingen Longitud vald, ',
    'Longitude must be above -90, ': 'Longitud måste vara över -90, ',
    'Longitude must be below 90, ': 'Longitud måste vara under 90, ',
    'no type chosen, ': 'ingen Typ vald, ',
    'parking terms do not accept more than 255 character':
        'parkeringsvillkoren accepterar inte mer än 255 tecken',

    Reset: 'Återställa',
    'Accept our terms': 'Acceptera våra villkor',
    'Enter your registration no.': 'Ange ditt registreringsnummer.',
    'phone number': 'telefonnummer',
    email: 'e-post',
    'then press ok': 'tryck sedan på ok',
    'Your permit ends': 'Ditt tillstånd upphör',
    'by today 23:59': 'senast 23:59 idag',
    after: 'efter',
    'minutes from the create Parking': 'minuter från skapa parkering',
    'Reg. no.': 'Reg. Nej.',
    'Phone number': 'Telefonnummer',
    Subscription_percentage_not_within_range: `Prenumerationsprocent '% för PK' måste ligga inom intervallet 0% till 100%`,
    Permit_percentage_not_within_range: `Tillåta '% för PK' måste ligga inom intervallet 0% till 100%`,
    Camera_control_percentage_not_within_range: `Kamerakontroll '% för PK' måste ligga inom intervallet 0% till 100%`,
    Selfticketing_percentage_not_within_range: `Själv biljettförsäljning '% för PK' måste ligga inom intervallet 0% till 100%`,
    Guard_control_percentage_not_within_range: `Skyddskontroll '% för PK' måste ligga inom intervallet 0% till 100%`,

    // Ticket Service validation
    'must be a positive integer': 'måste vara ett positivt heltal',
    'Reg. no. have not been specified': 'Reg. nr. har inte angetts',
    'Email has not been specified': 'E-mail har inte angetts',
    'Terms and conditions must be accepted':
        'Regler och villkor måste accepteras',
    Issues: 'Problem',

    // Terms
    Terms_titles: 'Villkor',
    Terms_para_1:
        'Vi lagrar och överför användarinformation krypterad. De angivna personuppgifterna registreras enligt beskrivning i vår datapolicy. Genom att acceptera dessa villkor samtycker du också till att vara bekant med och att ha accepterat dessa villkor för användning av Parkeringskompagniets produkter. För särskilda regler om Oparko abonnemangsparkering, se nedan under "Oparko Prenumerationsparkering". Parkeringsföretaget kan komma att samla in information och upprätta statistik och rapporter för att förbättra och leverera den bästa produkten och tjänsten till Användaren.',
    Terms_main_point_1: {
        main_title: 'Ansökan',
        sub_point_1:
            'Dessa allmänna villkor ("Allmänna villkor") gäller tillhandahållande av tjänster från Parkeringskompagniet ApS, CVR-nr 37127385, Trindsøvej 4, 8200 Aarhus, ("Parkeringskompagniet"), till användare ("Användare") i Danmark .',
        sub_point_2:
            'NOTERA! Användning av Parkeringskompagniets tjänster kräver att du är registrerad ägare eller användare av fordonet eller att du på annat sätt har tillstånd att använda och registrera fordonet.',
        sub_point_3: {
            sub_point_3_title:
                'Alla tjänster tillhandahålls i enlighet med tillämplig lag och i enlighet med:',
            sub_point_3_1: 'eventuella individuellt överenskomna villkor, och',
            sub_point_3_2: 'dessa allmänna villkor.',
            sub_point_3_3:
                'Vid inkonsekvenser mellan ovanstående bestämmelser har de företräde i ovanstående ordning.',
        },
        sub_point_4:
            'I avsnitt 5.1.4, 6.4 och 7.7 finns särskilda bestämmelser om korrekt och giltig anteckning av registreringsnummer och riktnummer.',
        sub_point_5:
            'Punkt 5.3. indeholder særlige bestemmelser om anvendelsen af Oparko-charge.',
        sub_point_6:
            'Genom att registrera sig hos Oparko (se punkt 4) samtycker Användaren till att omfattas av dessa Allmänna Villkor. Ett bindande avtal ("Avtalet") ingås när Parkeringsföretaget har bekräftat Användarens registrering och Användaren får tillgång till Parkeringsföretagets Tjänster.',
        sub_point_7:
            'Dessa Allmänna Villkor utgör Avtalet mellan Parkeringsföretaget och Användaren.',
        sub_point_8:
            'Dessa Allmänna Villkor gäller för alla transaktioner och registreringar via Parkeringskompagniet, inklusive *service2* och Oparko Abonnementparkering.',
    },
    Terms_main_point_2: {
        main_title: 'Definitioner',
        sub_point_1: {
            sub_point_1_title:
                'I dessa Allmänna Villkor och i samband med Tjänsterna gäller följande definitioner:',
            sub_point_1_1:
                'Med "Oparko" menas den digitala parkeringstjänst som kan användas i samband med betalning för parkering, via Appen och hemsidan;',
            sub_point_1_2:
                '"App" betyder Parkeringsföretagets mobiltelefonapplikation;',
            sub_point_1_3:
                '"Oparko System" är Oparkos digitala system för aktuell parkering, som Användaren får tillgång till via Appen och/eller via Webbplatsen;',
            sub_point_1_4:
                'För "Hemsida", se Parkeringskompagniets hemsida <a class="text-pk-blue" href="https://parkeringskompagniet.dk/">https://parkeringskompagniet.dk/</a>.',
            sub_point_1_5:
                '"Parkeringstjänst" är Användarens förmåga att hantera parkeringsplatser i Appen;',
            sub_point_1_6:
                'En "Parkeringsplats" är ett fysiskt område som matchar en parkeringsplats markerad på översiktskartan (en parkeringsplats betyder inte en markerad parkeringsplats);',
            sub_point_1_7:
                '"Parter" och "Parter" betyder Parkeringsföretaget och/eller Användaren;',
            sub_point_1_8:
                '"Användare" används i dessa Allmänna Villkor för den parkeringsplats som använder Parkeringsföretaget i samband med betalning för parkering.',
            sub_point_1_9:
                '"Partner" betyder partners som Parkeringskompagniet samarbetar med;',
            sub_point_1_10:
                '"Tjänst(er)" har den betydelse som anges i paragraf 3.1.1.',
            sub_point_1_11: '"Parkeringsavgiften" är priset för parkering.',
            sub_point_1_12:
                'Med "aktivt fordon" avses ett fordon som användaren har valt att automatiskt betala för i samband med parkering på så kallade ANPR-parkeringsplatser. Betalning sker via den betalningsmetod som Användaren har angivit som föredragen.',
            sub_point_1_13:
                '"ANPR" står för Automatic Number Plate Registration, vilket innebär att fordonets registreringsskylt läses av automatiskt och registreringsnumret registreras i parkeringssystemet. Parkeringsplatser med ANPR-system är markerade med kamera i Oparko och det framgår av skyltningen på parkeringen att nummerskylten går att läsa.',
            sub_point_1_14:
                'Med "Oparko Charge" avses Parkeringskompagniets egna och tillhörande system och appar från 3:e part som kan användas i samband med laddning av el- och hybridbilar. Det är både laddstationer, betalsystem och appar.',
        },
        sub_point_2:
            'Definitioner kan ges på andra ställen i dessa allmänna villkor.',
    },
    Terms_main_point_3: {
        main_title: 'Allmänna villkor för tjänsterna',
        sub_point_1: {
            sub_point_1_title: 'I allmänhet',
            sub_point_1_1:
                'Parkeringsföretaget erbjuder ett digitalt system för parkering ("Oparko"), där Parkeringsföretaget, (i samarbete med andra Partners,) gör det möjligt för Användaren att hantera parkering av fordon ("Parkeringstjänst") och laddning av elfordon. Parkeringstjänsten och de andra tjänster som när som helst tillhandahålls av Parkeringsföretaget kallas tillsammans för "Tjänsterna".',
        },
        sub_point_2:
            'För en detaljerad beskrivning av tjänsterna, vänligen se informationen som tillhandahålls när som helst på webbplatsen och <a href="https://parkeringskompagniet.dk/" class="text-pk-blue">https://parkeringskompagniet.dk/.</a>',
    },
    Terms_main_point_4_new: {
        main_title: 'Insamling och behandling av personuppgifter',
        sub_point_1: {
            sub_point_title: 'Insamling och behandling av personuppgifter',
            sub_point_1_1:
                'När Användaren använder Parkeringsföretagets tjänster samtycker Användaren samtidigt till att Parkeringsföretaget samlar in och behandlar de personuppgifter och personuppgifter som Användaren lämnar i enlighet med Parkeringsföretagets gällande datapolicy (<a href="https://parkeringskompagniet.dk/datapolitik/" class="text-pk-blue">https://parkeringskompagniet.dk/datapolitik/</a>).',
            sub_point_1_2:
                'Personuppgifter lagras endast om de lämnas frivilligt och endast så länge det är relevant för Parkeringsföretaget. Uppgifterna i fråga lagras på Parkeringsföretagets interna nätverk och kan endast nås med särskilda rättigheter.',
        },
        sub_point_2: {
            sub_point_title: 'Användarens rättigheter',
            sub_point_2_1:
                'Användaren har rätt att få insyn i de personuppgifter som lagras om honom av Parkeringskompagniet, samt att få informationen i fråga rättad.',
            sub_point_2_2:
                'Användaren har rätt att få sina uppgifter raderade från Parkeringsbolagets system.',
            sub_point_2_3:
                'Användaren har även de rättigheter som tilldelats individer av EU:s allmänna dataskyddsförordning.',
        },
        sub_point_3: {
            sub_point_title: 'Användarens skyldigheter',
            sub_point_3_1:
                'När användaren lämnar uppgifter om någon annan garanterar användaren att denne har rätt att lämna uppgifterna till Parkeringsföretaget.',
        },
        sub_point_4: {
            sub_point_title: 'Överföring av data',
            sub_point_4_1:
                'Användaren accepterar att om Parkeringsföretaget anser det nödvändigt att flytta användarens information till en annan plattform, skapad med samma syfte som ingår i detta avtal, kommer detta att ske utan föregående meddelande och i enlighet med gällande dataskyddslagstiftning.',
        },
        sub_point_5: {
            sub_point_title: 'Offentliggörande av information',
            sub_point_5_1:
                'Användarens uppgifter lämnas endast vidare om de är nödvändiga för den tjänst som tillhandahålls av Parkeringskompagniet.',
        },
    },
    Terms_main_point_5_new: {
        main_title: 'Särskilda villkor för användning av Oparko',
        sub_point_1: {
            sub_point_1_title: 'Villkor',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Parkeringstjänsten kan endast användas på parkeringsplatser och i parkeringsområden/zoner (var och en en "Parkeringsplats"), som:',
                sub_point_1_1_1:
                    'där Parkeringskompagniets skyltning är uppsatt,',
                sub_point_1_1_2:
                    'och har varuautomater med Oparko-etiketter, eller på annat sätt bär Oparko-märken som indikerar att Oparko kan användas.',
            },
            sub_point_1_2:
                'När Parkeringstjänsten används måste Användaren initiera en parkering genom att aktivera antingen; Appen; eller ett anslutet och godkänt betalningssystem',
            sub_point_1_3:
                'För att säkerställa att parkering har påbörjats korrekt ska Användaren kontrollera att en bekräftelse på korrekt och giltig påbörjad parkering inkommit via',
            sub_point_1_3_1: 'Appen, om appen används; eller',
            sub_point_1_3_2: 'E-post bekräftelse',
            sub_point_1_4:
                'En parkering är endast giltig om Appen har bekräftat parkeringen och visar en pågående korrekt registrerad parkering/betalning.',
            sub_point_1_5:
                'Vid användning av Parkeringstjänsten ska Användaren i detta sammanhang korrekt ange det parkerade fordonets registreringsnummer och riktnummer för den aktuella Parkeringsplatsen.',
            sub_point_1_6:
                'Det är Användarens ansvar att fordonets registreringsnummer och riktnummer anges korrekt. Fel vid registrering och anmälan medför en kontrollavgift.',
            sub_point_1_7:
                'Användaren ska se till att riktnumret överensstämmer med riktnumret för den aktuella parkeringen eller E-laddstället, vilket framgår av Oparko-skylten på parkeringen eller i närheten av Parkeringens maskiner/E-laddställ. Användaren ansvarar för att ange rätt riktnummer och registreringsnummer. Oparko tar inget ansvar i samband med felaktigt angivna registreringsnummer och/eller riktnummer.',
            sub_point_1_8:
                'Vid parkering på parkeringsplatser där fordonets registreringsskylt avläses ska riktnummer inte anges. Det registreringsnummer som används för parkering måste anges korrekt.',
            sub_point_1_9:
                'Det är ett giltighetsvillkor för parkeringsregistreringen att registreringsnummer och riktnummer är korrekt angivna.',
            sub_point_1_10: {
                sub_point_1_10_title:
                    'Registrering av parkering i Oparko kan göras på något av följande sätt:',
                sub_point_1_10_1:
                    'Vid manuell registrering ska Användaren ange önskad parkeringstid, och i samband med detta informeras om priset för önskad parkering. Vid Användarens bekräftelse av den angivna registreringen reserveras det angivna beloppet. När Användaren stoppar parkeringen ändras det reserverade beloppet så att endast den registrerade parkeringstiden betalas.',
                sub_point_1_10_2: {
                    sub_point_1_10_2_title:
                        'Vid automatisk registrering (vid parkering i ANPR-platser) måste Användaren ha aktiverat fordonet under "fordon" innan parkering. Vid parkering på en ANPR parkeringsplats börjar parkeringen vid infarten och slutar och avgörs vid utfarten. Det betalas för använd parkeringstid.',
                    sub_point_1_10_2_1:
                        'När Användaren aktiverar fordonet samtycker Användaren till att automatisk betalning kommer att göras via den föredragna betalningsmetoden vid parkering på en ANPR-parkeringsplats.',
                },
            },
            sub_point_1_11:
                'Oparko kan inte hållas ansvarigt för precisionen av Användarens telefons GPS-position, och Oparko kan inte hållas ansvarigt för mobiltäckning/signal på/på parkeringsplatsen. Användaren ansvarar för den angivna positionen som motsvarar positionen för användarens parkerade fordon.',
            sub_point_1_12:
                'Information om parkering gjord av Användaren kommer att vara tillgänglig för Användaren via "Parkering", och sådan information kommer i allmänhet att vara tillgänglig i minst 12 månader.',
        },
        sub_point_2: {
            sub_point_1_title: 'I allmänhet',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Parkeringstjänsten gör det möjligt för Användaren att starta en parkering och vid manuell start ange den provisoriska parkeringstiden, stoppa och avsluta den påbörjade parkeringen. Vidare är det möjligt för Användaren att förlänga den provisoriskt angivna parkeringstiden.',
                sub_point_1_2_1:
                    'Påminnelse: Det är möjligt att få en påminnelse innan den manuellt initierade parkeringen löper ut, förutsatt att push-aviseringsbehörigheter har beviljats ​​i appen. Det är brukarens ansvar att se till att parkeringen genomförs korrekt oavsett om påminnelsen inte framkommer.',
                sub_point_1_2_2: {
                    sub_point_1_2_2_title:
                        'Stoppa parkering: Om du lämnar parkeringen innan den valda parkeringstiden går ut är det möjligt att aktivt stoppa den pågående parkeringen, varvid du endast betalar för den period som parkeringen varit aktiv. Användaren rekommenderas att alltid vara uppmärksam på parkeringspriset som framgår av skyltningen och/eller betalautomaten. Beroende på prisstrukturen på parkeringen kommer det på de flesta parkeringar att vara möjligt att stoppa parkering så att man inte betalar för "oanvänd tid". OBS! Vissa parkeringsplatser kräver betalning pr påbörjad tidsperiod och ev i förväg, vilket innebär att i händelse av att sådan parkering stoppas debiteras det förhandsgodkända beloppet oavsett hållplats. Om parkering inte stoppas återbetalas inte betalning för "överskjutande tid".',
                    sub_point_1_2_2_1:
                        'Med övertid avses den tid under vilken det finns en giltig registrering av parkering på parkeringen men under vilken fordonet inte har stått på parkeringen.',
                },
                sub_point_1_2_3:
                    'Förlängning: Förlängning av en parkering görs genom att trycka på "förläng" och sedan ange ny sluttid för parkeringen, då godkänns beloppet. Efter detta kommer nedräkningen av parkeringsperioden för fordonet att uppdateras baserat på den förlängda parkeringstiden. Förlängning innebär att en ny parkering initieras när den ursprungliga parkeringen löper ut.',
                sub_point_1_2_4:
                    'Om det inte är möjligt att göra ett köp vid en given tidpunkt med hjälp av Oparko-mjukvaran ansvarar Användaren för att betala för parkeringen på annat sätt, t.ex. genom att köpa en giltig P-biljett i betalautomat och i övrigt följa uppsatta regler och villkor för parkering. Orsaker till att Oparko inte kan användas inkluderar, men är inte begränsade till, till exempel: dålig/ingen internetåtkomst, serveravbrott på grund av underhåll, uppdateringar etc.',
            },
            sub_point_1_2:
                'Om Användaren inte var föraren av fordonet vid parkeringstillfället, men användaren har angett fordonet som aktivt, har användaren accepterat att betalning sker automatiskt via den föredragna betalningsmetoden. Den interna relationen mellan Användaren och tredje part ligger utanför Oparkos kontroll.',
            sub_point_1_3: {
                sub_point_1_3_title:
                    'Om besiktningsavgift påförs det parkerade fordonet, för parkering i strid med de regler och villkor som framgår av skyltningen på parkeringen, är föraren skyldig att betala sådan besiktningsavgift, oavsett vem som har betalat för parkeringen. .',
                sub_point_1_3_1:
                    '"Kontrollavgift" används i detta sammanhang som en samlingsbeteckning för resp besiktningsavgift, besiktningsavgift, parkeringsböter mm.',
            },
            sub_point_1_4:
                'För det fall parkeringen genomförs med ett fordon där den registrerade ägaren/användaren är ett företag, och fordonet är besiktningsavgift, är det Användarens ansvar att på ett tillfredsställande sätt dokumentera att den utförda parkeringen inte har skett i nöjessyfte. syften. Om Användaren inte kan uppfylla denna bevisbörda anses den registrerade ägaren/användaren vara rätt gäldenär och i detta fall, vid försenad betalning, utöver eventuella påminnelser, kommer ett ersättningsbelopp på DKK 310,00 att debiteras i enligt yrkande nr 105 av 2013-01-31 2 § mom. 1.',
            sub_point_1_5:
                'Om Användaren inte var föraren av fordonet vid parkeringstillfället, samtycker Användaren härmed till att behöva avslöja vem föraren var. Vid ofullständiga eller felaktiga uppgifter ska besiktningsavgiften, samt ev Påminnelseavgifter, i sin helhet, dras mot Användaren.',
            sub_point_1_6:
                'Användarens betalning av aktuell parkeringsavgift (inklusive moms) bestäms utifrån gällande taxor ("Parkeringsavgiften") i samband med att Användaren avslutar en parkeringsplats, alternativt påbörjar en fast parkeringstid genom Parkeringstjänsten.',
            sub_point_1_7:
                'Användaren kan inte göra anspråk på nedsättning av kontrollavgiften på grundval av genomförd betalning i Oparko. Detta villkor gäller oavsett om besiktningsavgiften påförs på grund av Användarens felanmälan i Oparko eller Användarens parkering i strid med övriga uppsatta regler och villkor på parkeringen.',
            sub_point_1_8:
                'Användaren har inte heller rätt till återbetalning av betalning som gjorts via Oparko, såvida inte avgiften beror på ett fel från Oparkos sida, som Användaren inte haft eller inte kunnat påverka.',
        },
        sub_point_3: {
            sub_point_1_title: 'Oparko avgiftsvillkor',
            sub_point_1_1:
                'Betalning för fordonsladdning kan göras via appar i förhållande till de laddstationer som är uppsatta på sajten. Betalning för parkering ska göras separat genom Oparko (se punkt 5).',
            sub_point_1_2:
                'BEMÆRK: Ved parkering i parkeringsbåse reserveret til el-køretøjer under opladning, skal opladningen være registreret i den benyttede app, inden parkering og opladning påbegyndes. Desuden skal parkering være registreret i Oparko-appen, som angivet i punkt 5.',
            sub_point_1_3:
                'Den benyttede app skal anvendes til betaling for opladning via opstillede ladestandere.',
            sub_point_1_4:
                'Ved brug af Oparko i forbindelse med benyttelse af opstillet ladestander, giver Brugeren meddelelse til Parkeringskompagniet om, at opladning af parkeret køretøj påbegyndes, den foreløbige ladetid, afslutning af opladning og hvis nødvendigt, om forlænget ladetid.',
            sub_point_1_6: {
                sub_point_1_6_title:
                    'Vid betalning och användning av installerat laddställ betalas en debiteringsavgift.',
                sub_point_1_6_1: 'Debiteringsavgiften är rörlig.',
                sub_point_1_6_2:
                    'Betalning för debiteringsavgiften görs separat och kravet framgår av den app som används innan registrering och betalning genomförs.',
            },
            sub_point_1_7:
                'Vid registrering gäller även övriga villkor för användning av Oparko-appen, inklusive att korrekt och giltig registrering och betalning kräver korrekt inmatning av riktnummer och registreringsnummer för det parkerade fordonet. Det är användarens ansvar att de uppgifter som anges är korrekta och kontrollerbara och att betalning kan genomföras.',
            sub_point_1_8: {
                sub_point_1_8_title:
                    'Riktnummer som ska användas i samband med betalning för laddning och parkering framgår av skyltningen som är uppsatt utanför stånden som är reserverade för elfordon. Det är användarens ansvar att ange rätt riktnummer.',
                sub_point_1_8_1:
                    'Vid betalning till ett riktnummer kopplat till en Oparko-laddningsstation, men utan att använda en parkeringsplats reserverad för Oparko-charge, kommer ingen återbetalning av betalningen att ske.',
            },
            sub_point_1_9: {
                sub_point_1_9_title:
                    'Det är användarens ansvar att hela tiden iaktta de skyltade regler och villkor som framgår av parkeringen. Vid inkonsekvenser mellan skyltningen och dessa villkor har skyltningens ordalydelse företräde. Om parkering inte utförs i enlighet med uppsatta regler och villkor kan en besiktningsavgift tas ut, som i efterhand inte kan avbokas.',
                sub_point_1_9_1:
                    'Parkering i bås reserverade för elfordon kan endast ske mot betalning och fordonet ska debiteras för hela den tid parkeringsbåset används. Om fordonet inte laddas och/eller parkeringen inte är registrerad i Oparko debiteras en besiktningsavgift som inte kan avbokas.',
                sub_point_1_9_2:
                    'Om problem uppstår i samband med användningen av Oparko-laddstationen ska användaren kontakta Parkeringskompagniet på det telefonnummer som anges på laddstationen.',
            },
            sub_point_1_10:
                'Information som Användaren har registrerat i samband med registrering och betalning sker på samma sätt som nämnts i avsnitt 4.',
        },
    },
    Terms_main_point_6: {
        main_title:
            'Tillgång till Tjänsterna, registrering av information m.m.',
        main_text:
            'Tillgång till Tjänsterna, skapande och betalning för parkering via Oparko kan göras genom att Användaren skapas som användare. Användning av Oparko kräver att ett giltigt betalningsmedel är kopplat till Användarens Oparko-app. De betalningsmedel som Användaren giltigt kan associera som betalningsmedel är; Bankkort Det är ett giltighetsvillkor att det finns täckning på tillhörande och valda betalningsmedel.',
        sub_point_1: {
            sub_point_1_title:
                'Vid skapande och senare användning av Oparko är det en förutsättning att Användaren tillhandahåller korrekt och giltig information:',
            sub_point_1_1: {
                sub_point_1_1_1: 'namn',
                sub_point_1_1_2: 'E-postadress',
                sub_point_1_1_3: 'Mobilnummer',
                sub_point_1_1_4: 'Lösenord',
                sub_point_1_1_5: 'Registreringsnummer, på minst ett fordon',
                sub_point_1_1_6: {
                    sub_point_1_1_6_title: 'Betalningssätt',
                    sub_point_1_1_6_1: {
                        sub_point_1_1_6_1_title:
                            'Grupper av anställda med tillgång till den registrerade informationen:',
                        sub_point_1_1_6_1_1:
                            'Underleverantörer (medan de utför support)',
                        sub_point_1_1_6_1_2:
                            'Parkeringsföretagets anställda (samtidigt som de tillhandahåller support i samband med användarförfrågningar)',
                    },
                },
            },
        },
        sub_point_2:
            'Parkering initieras via appen eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>',
        sub_point_3: {
            sub_point_3_title:
                'För att kunna använda Oparko måste en giltig betalningsmetod vara ansluten och vald i appen eller på <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a> innan parkeringen registreras.',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'OBS: Det går inte att ändra betalningsmedel i samband med en pågående parkering varför det är Användarens ansvar att rätt och giltigt betalningsmedel väljs innan parkering.',
            },
        },
        sub_point_4: {
            sub_point_4_title:
                'Instruktionerna som visas på skärmen måste följas:',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'För att Användaren ska använda Oparko för att betala parkering är det ett giltighetsvillkor att Användaren registrerar ett korrekt och giltigt registreringsnummer (nummerskylt), riktnummer, betalningsmedel och parkeringstid.',
                sub_point_1_1_2:
                    'Det måste kontrolleras att registrerat registreringsnummer och riktnummer är korrekt angivet av Användaren.',
                sub_point_1_1_3:
                    'Oparko förbehåller sig rätten att avslå en ansökan om skapande och betalning för parkering.',
                sub_point_1_1_4:
                    'Det förtydligas att giltig betalning för parkeringen endast har erlagts om registreringsnumret på det parkerade fordonet har angetts korrekt och att det angivna riktnumret stämmer överens med det riktnummer som framgår av Oparko-skylten gällande den aktuella parkeringsplatsen. , där fordonet finns.',
            },
        },
        sub_point_5:
            'Om parkeringen inte är giltigt registrerad riskerar fordonet att debiteras en besiktningsavgift.',
        sub_point_6:
            'Vid lyckat köp/bokning skickas en bekräftelse via e-post till den e-postadress som Användaren har angett.',
        sub_point_7:
            'Vid köp genom Oparko ställs en ospecificerad parkeringsplats till förfogande på vald parkering/parkeringsplats och under vald period. Parkeringskompagniet kan inte hållas ansvarigt om det inte finns några lediga parkeringsplatser (då detta inte är en bokning). Parkering bör därför inte köpas innan fordonet är ordentligt parkerat på parkeringen.',
        sub_point_8:
            'Användaren har inte rätt till återbetalning av oanvänd parkering. Se punkt 5.2.1.2.',
    },

    Terms_main_point_7: {
        main_title: 'Användarens skyldigheter och ansvar',
        sub_point_1: {
            sub_point_1_1: `Användaren ansvarar alltid för att ge korrekt information om Användaren och att relevanta fordon är korrekt registrerade hos Oparko. Användaren måste logga in på "Mitt konto" direkt efter skapande/registrering för att säkerställa att den registrerade informationen är korrekt. Oparko kan aldrig vara eller bli ansvarig för felaktiga registreringar/anmälningar, oavsett registreringssätt.`,
            sub_point_1_2: `Användaren ansvarar för att löpande se till att betalningssättet som registreras och används via Oparko inte är spärrat, är giltigt och att det finns tillräcklig täckning på det registrerade betalningssättet. Vid utgången av ett registrerat betalningsmedel måste Användaren förse Oparko med information (genom att uppdatera Appen under "betalningsmetoder") om ett nytt betalningsmedel. Uppgifterna om ett nytt betalningsmedel ska lämnas senast i slutet av den kalendermånad som föregår den kalendermånad då det registrerade betalningsmedlet inte längre är användbart.`,
            sub_point_1_3: `Säker och korrekt lagring av inloggningsinformationen är Användarens ansvar.`,
            sub_point_1_4: `Vidare är det Användarens ansvar att inloggningsuppgifterna inte lämnas vidare eller på annat sätt göras tillgängliga för obehöriga.`,
            sub_point_1_5: {
                sub_point_1_5_title: `Det är enbart Användarens ansvar att Användarens Oparko-konto används och endast används av rätt personer.`,
                sub_point_1_5_1: {
                    sub_point_1_5_1_title: `Användaren måste omedelbart informera Oparko (t.ex. genom att kontakta Oparkos kundtjänst eller genom att uppdatera informationen i "Mitt konto") om:`,
                    sub_point_1_5_1_1: `Användaren har anledning att tro att en obehörig person har fått tillgång till eller kunskap om inloggningsinformationen;`,
                    sub_point_1_5_1_2: `registrerad information om Användaren har ändrats och/eller bör uppdateras; eller`,
                    sub_point_1_5_1_3: `Användaren har inte längre ett registrerat fordon eller behöver inte längre betala för parkering med ett registrerat fordon,`,
                },
                sub_point_1_5_2: `Informationen ska initialt tillhandahållas genom att Användaren själv uppdaterar informationen i Oparko. Det är Användarens ansvar att inga fordon registreras som Användaren inte behöver betala för. Oparko kan därigenom vidta nödvändiga försiktighetsåtgärder, till exempel att ändra/blockera inloggningsuppgifterna och/eller Användarens konto eller be Användaren att uppdatera Användarens registrerade information. Användaren är även skyldig att informera Oparko om andra frågor av betydelse för Avtalet och/eller leveransen av Tjänsterna.`,
                sub_point_1_5_3: `Användaren ansvarar för att Appen alltid är uppdaterad. Rätt funktionalitet hos mobiltelefoner eller annan teknisk utrustning är alltid Användarens ansvar. Det är Användarens ansvar att media som används är kompatibla med Tjänsterna och appen. Tillämpliga funktionskrav för tjänsterna och appen kan när som helst erhållas genom att kontakta kundtjänst.`,
            },
            sub_point_1_6: `Användaren är skyldig att alltid följa gällande lagar och förordningar samt de regler och villkor som framgår av skyltningen vid den aktuella parkeringen där Användaren parkerar sitt fordon.`,
            sub_point_1_7: `Användaren ansvarar för att parkeringen påbörjas korrekt genom att ange det parkerade fordonets registreringsnummer och riktnummer som gäller för den parkering som används. Användaren ansvarar för att det riktnummer som Användaren uppger är korrekt, även om riktnumret föreslås i Appens platsfunktion. OBS: Om korrekt registreringsnummer och/eller riktnummer inte anges har ingen giltig betalning skett för parkeringen varför det parkerade fordonet riskerar att debiteras en besiktningsavgift.`,
            sub_point_1_8: `Användaren ansvarar för att en påbörjad parkering genomförs. Om Användaren inte anger en provisorisk sluttid eller en total parkeringstid när parkeringen börjar, är Användaren ansvarig för att avsluta parkeringen manuellt. Att avsluta parkeringen kan göras via Appen, eller genom att kontakta Oparko med hjälp av kontaktuppgifterna som finns på Appen, hemsidan och Oparkoskyltarna.`,
            sub_point_1_9: {
                sub_point_1_9_title: `Användaren ansvarar för betalning av eventuella inspektionsavgifter eller avgifter för felaktig parkering (anmäld eller inkasserad i samband med överträdelser av parkeringsrestriktioner).`,
                sub_point_1_9_2: `Om det parkerade fordonet är företagsregistrerat anses parkeringen vara utförd i arbetssammanhang, varför Bolaget i denna situation kommer att anses vara rätt gäldenär.`,
            },
            sub_point_1_10: `Användaren har möjlighet att se parkeringshistoriken för de parkeringsplatser som användaren har betalat för via Oparko.`,
            sub_point_1_11: `All parkering köpt/reserverad via Oparko sker på egen risk, om inte annat uttryckligen anges i dessa villkor.`,
            sub_point_1_12: `Parkeringskompagniet tar inget ansvar i samband med olyckor, stöld, skador på personer, fordon, utrustning och effekter, i samband med att fordonet står uppställt på en parkering. Parkeringskompagniet kan inte hållas ansvarigt för någon form av indirekt förlust eller följdskada.`,
        },
    },
    Terms_main_point_8: {
        main_title: 'Tillgång till klagomål',
        sub_point_1: `Vid bristfällig tjänst eller felaktig debitering ska Användaren omedelbart och senast 30 dagar efter att tjänsten i fråga levererades eller efter det att Användaren konstaterat eller borde ha konstaterat den aktuella felaktiga debiteringen lämna in ett skriftligt klagomål till Parkeringskompagniet kundservice. I reklamationen ska bristen eller felet tydligt framgå. Användaren ska ge skälig hjälp till Parkeringsföretaget i samband med eventuella utredningar i samband med reklamationen.`,
        sub_point_2: `Klagomål om påförda kontrollavgifter ska göras till emittenten av den/de påförda kontrollavgiften.`,
    },
    Terms_main_point_9: {
        main_title: 'Villkor och priser',
        sub_point_1: `Användaren ansvarar när som helst för alla betalningar som görs via Oparko.`,
        sub_point_2: `Betalningar görs genom att Användaren registrerar ett giltigt betalningsmedel i Appen. Betalning kommer att debiteras via det betalningsmedel som registrerats av Användaren.
        När ett fordon är markerat som aktivt har Användaren accepterat att betalning sker automatiskt via det angivna föredragna betalningsmedlet.
        Administratör kommer att faktureras för betalningar där Erhvervsparkering är registrerat som betalningsmedel. Användaren väljer och godkänner betalningssätt i samband med ansökan om registrering.
        Det är Användarens ansvar att ange korrekt och giltigt betalningsmedel innan parkering påbörjas.
        Felaktig användning av kommersiell parkering är enbart en fråga mellan administratören och användaren.`,
        sub_point_3: `Vid försenad betalning debiteras eventuella påminnelser enligt gällande lagstiftning.`,
        sub_point_4: `Alla priser är angivna i danska kronor och inkluderar moms. Priset som ska betalas för parkeringen beror på vald parkeringsplats, vald parkeringsperiod och om en pågående parkering förlängs.`,
        sub_point_5: `Vid skapande av parkeringsplats och vid tillbyggnad anges hur mycket den aktuella parkeringen eller tillbyggnaden kostar och detta belopp ska godkännas innan parkeringsplatsen skapas och/eller utökas.`,
        sub_point_6: `I vissa fall kommer en driftavgift att tas ut utöver timpriset för parkering. Detta kommer alltid att visas på appen eller webbplatsen vid köp av tillstånd.`,
        sub_point_7: `Parkeringskompagniet förbehåller sig rätten att ändra avgifter och priser.`,
        sub_point_8: `OBS: Oparko förbehåller sig rätten att uppdatera och ändra innehållet i Appen samt webbplatsen utan föregående meddelande.`,
    },
    Terms_main_point_10: {
        main_title: 'Ångerrätt',
        sub_point_1: `Enligt den danska konsumentavtalslagen (lag nr 1457 av 17 december 2013) har Användaren rätt att frånträda avtalet genom att meddela Oparko kundtjänst inom 14 dagar efter godkännande av registreringen.`,
        sub_point_2: `Användaren samtycker till att köplagen (lag nr 1853 av 2021-09-24) normal ångerrätt på 14 dagar endast gäller för parkering som ännu inte har trätt i kraft (giltigt) vid avbeställningstillfället. Om Användaren sålunda beställer parkering med giltighetstid från samma dag (omgående) upphör även ångerrätten samma dag (omgående) och den i övrigt gällande 14 dagars ångerrätten frånsägs därmed av Användaren. Vid Användarens köp av parkering med giltighetstidens början senare än 14 dagar från tecknandet av abonnemangsavtalet har Användaren 14 dagars ångerrätt.`,
        sub_point_3: {
            sub_point_3_title: `För att kunna utnyttja ångerrätten måste ångerbeskedet vara Oparko tillhanda senast den 14:e dagen den dag då betalningen registreras hos Oparko.`,
            sub_point_3_1: `Om tidsfristen går ut på en allmän helgdag, lördag, grundlagsdag, julafton eller nyårsafton förlängs fristen till följande vardag.`,
            sub_point_3_2: `För att utöva ångerrätten måste Användaren skicka ett e-postmeddelande till Parkeringsföretaget där Användaren skriver att köpet/bokningen är makulerad (se avsnitt 12.2.8.).`,
        },
        sub_point_4: `Observera att inträde på parkeringen/auktorisera köpet i appen eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a> , anses accepterande av påbörjande av parkering. Om du sedan ångrar ditt köp i appen eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a> blir din återbetalning reduceras med en proportionell del av totalpriset för parkeringen under den köpta perioden, baserat på antalet dagar/timmar/minuter fordonet har stått parkerat tills Oparko fått registreringen av utövandet av ångerrätten.`,
    },
    Terms_main_point_11: {
        main_title: 'Villkor för avbokning',
        sub_point_1: `Användaren kan välja att stänga Användarens konto i Appen, eller genom att stänga Användarens konto via hemsidan, varvid Användarens Oparko-konto ogiltigförklaras, så att Användaren inte längre har möjlighet att betala för parkering via Oparko.`,
        sub_point_2: `Obetalda ärenden kommer att debiteras i enlighet med ingånget avtal, även om Användaren stänger Oparko-kontot.`,
    },
    Terms_main_point_12: {
        main_title: 'Varaktighet, förtida uppsägning m.m.',
        sub_point_1: `Detta avtal är giltigt från Oparkos godkännande av Användaren och tills det sägs upp i enlighet med villkoren i avtalet eller tills annat skriftligen avtalats.`,
        sub_point_2: {
            sub_point_2_title: `Oparko har rätt att omedelbart stänga av Användarens åtkomst till Tjänsterna, avsluta Användarens konto och/eller säga upp Avtalet med omedelbar verkan, om`,
            sub_point_2_1: `Användaren bryter väsentligt mot sina skyldigheter enligt detta avtal;`,
            sub_point_2_2: `Användaren uppfyller inte, eller det finns rimlig anledning att anta att Användaren inte kommer att fullgöra sina betalningsförpliktelser gentemot Oparko,`,
            sub_point_2_3: `Användaren kan, enligt Oparkos rimliga bedömning, förväntas bli oförmögen att betala;`,
            sub_point_2_4: `Användaren använder Oparko Services i strid med avtalet eller som på annat sätt skadar eller negativt påverkar Oparko eller någon tredje part;`,
            sub_point_2_5: `Användaren har vid upprepade tillfällen parkerat ett fordon i strid med tillämpliga lagar, regler och/eller föreskrifter specificerade av den relevanta parkeringsleverantören;`,
            sub_point_2_6: `Användaren har lämnat felaktig, ofullständig eller vilseledande information, eller`,
            sub_point_2_7: `Oparko, baserat på en samlad bedömning, finner det troligt att Användaren kan vara inblandad i eller involverad i brottslig verksamhet.`,
            sub_point_2_8: `Uppsägning av avtalet måste göras skriftligen till <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a> eller via formuläret på webbplatsen: <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">https://parkeringskompagniet.dk/kontakt/</a>`,
        },
        sub_point_3: `Uppsägning av Avtalet (av vilken anledning som helst) påverkar inte de rättigheter och/eller skyldigheter som en Part har ådragit sig gentemot den andra Parten före datumet för Avtalets uppsägning.`,
    },
    Terms_main_point_13: {
        main_title: 'Oparko ansvar',
        sub_point_1: `Oparkos totala skadeståndsansvar får under inga omständigheter överstiga ett belopp motsvarande DKK. 38 000,00, om inte Oparko har handlat uppsåtligen eller har visat grov vårdslöshet.`,
        sub_point_2: `Parkeringskompagniet ansvarar inte för olyckor, stöld, personskador, skador på fordon, utrustning eller andra effekter monterade på eller i fordonet.`,
        sub_point_3: `Parkeringskompagniet ansvarar inte för vare sig indirekt förlust eller följdskador. Parkeringskompagniet och Oparko är inte skadeståndsskyldiga i förhållande till Användarens förhållande till ev tredje part`,
        sub_point_4: {
            sub_point_4_title: `Parkeringskompagniet ansvarar inte för skador eller förluster som Administratören och/eller Användaren åsamkas till följd av:`,
            sub_point_4_1: `Parkering på en parkeringsplats;`,
            sub_point_4_2: `Användarens fel eller försummelse, inklusive om Användaren inte har startat eller avslutat en parkering korrekt (oavsett om man valt att få en påminnelse eller ett pushmeddelande från Oparko), eller om Användaren har angett felaktigt riktnummer i förhållande till till en Parkeringsplats och/eller registreringsnummer, i början av en parkering (oavsett om Användaren har angett ett sådant riktnummer manuellt eller detta har föreslagits via lokaliseringsfunktionen i Appen);`,
            sub_point_4_3: `att Användaren inte har uppfyllt sina skyldigheter enligt detta Avtal och användarvillkor för Oparko eller att Användaren på annat sätt inte har följt Oparkos instruktioner;`,
            sub_point_4_4: `att Användaren har parkerat fordonet i strid med gällande lagar, regler och/eller föreskrifter som anges på den uppsatta skylten på den aktuella parkeringsplatsen där Användaren parkerar fordonet;`,
            sub_point_4_5: `fel eller otillräcklig funktionalitet hos Användarens mobiltelefon eller annan teknisk utrustning (såsom att utrustningen stängs av eller att batteriet tar slut);`,
            sub_point_4_6: `avbrott, avbrott eller förseningar i telefon-, Internet- eller andra kommunikationsnätverk, eller teleoperatörers handlingar eller försummelser, som påverkar tjänstens funktion eller tillgänglighet;`,
            sub_point_4_7: `fel eller defekt i en teknisk funktion som anger priset på den aktuella parkeringsavgiften;`,
            sub_point_4_8: `att Tjänsten har avbrutits av Oparko på grund av saker som visar sig vara felaktiga, men som Oparko hade anledning att tro var korrekta vid tidpunkten för avbrottet och som motiverade avbrottet;`,
            sub_point_4_9: `avbrott eller otillräcklig tillgång till en eller flera tjänster som Oparko inte rimligen kunde ha förutsett;`,
            sub_point_4_10: `att en Parkeringsplats föreslagen av Oparko inte är tillgänglig när Användaren anländer dit;`,
            sub_point_4_11: `obehörig användning av inloggningsinformation och/eller tjänster; eller`,
            sub_point_4_12: `force majeure (se punkt 16).`,
        },
        sub_point_5: `Oparko ansvarar inte under några omständigheter för indirekta skador såsom förlust av drift och förlust av data. Oparko ansvarar inte för skadestånd i relation till Administratörens och/eller Användarens förhållande till tredje part.`,
    },
    Terms_main_point_14: {
        main_title: `Användarens skadeståndsansvar`,
        sub_point_1: `Användaren ska hålla Parkeringsföretaget skadeslöst för ersättningskrav eller förluster till följd av Användarens fel eller försummelse, Användarens underlåtenhet att uppfylla sina skyldigheter enligt detta Avtal eller på annat sätt underlåtenhet att följa Oparkos instruktioner. Användaren måste också hålla Oparko skadeslös för anspråk från tredje part som är en direkt eller indirekt följd av Användarens användning av en Tjänst.`,
    },
    Terms_main_point_15: {
        main_title: `Immateriella rättigheter`,
        sub_point_1: `Användaren accepterar att mjukvara aldrig kan testas i alla möjliga situationer och att avvikelser från överenskommen funktionalitet samt oförutsedda fel och avbrott kan förekomma. Oparko är allmänt tillgänglig 24 timmar om dygnet, med undantag för planerade avbrott för uppgraderingar, ändringar och underhåll. Rätten att uppdatera Oparko med nya funktioner eller på annat sätt göra ändringar, till exempel för att anpassa systemet till ny teknik, nya säkerhetsstandarder eller nya administrativa rutiner etc. förbehålls av Parkeringskompagniet. Parkeringskompagniet tar inget ansvar för eventuella skador eller förluster i samband med bristande tillgång till Oparko, Oparko är ur drift eller innehåller fel.`,
        sub_point_2: `Alla upphovsrätter (inklusive rättigheter till källkoder, datorprogram, algoritmer och objektkoder) och andra immateriella rättigheter (inklusive patenträttigheter och varumärken) avseende Oparko och dess innehåll ägs eller licensieras av Parkeringskompagniet eller dess leverantörer och partners. Administratören eller användaren tilldelas inte, i enlighet med detta avtal, någon av dessa rättigheter. Det är inte tillåtet att använda Oparko eller dess innehåll för kommersiella ändamål. Användaren har ingen rätt att kopiera, distribuera, sälja, publicera, överföra, låna ut, underlicensiera, modifiera eller på annat sätt göra sig av med programvaran som finns i Oparko. Användaren har ingen rätt att demontera, bakåtkonstruera, dekompilera eller på annat sätt försöka komma åt programvarans källkod.`,
        sub_point_3: `Att all information och data som överförs till Oparko är fri från skadliga element, källkoder eller skadlig kod (såsom virus, maskar och trojanska hästar) måste säkras och garanteras av Användaren. Information som Användaren laddar upp till Mina Sidor eller gör tillgänglig via Appen får inte göra intrång i tredje parts immateriella rättigheter eller på annat sätt strida mot lagar, förordningar eller andras rättigheter. Det är Användarens ansvar att tredje parts eller andras immateriella rättigheter inte kränks.`,
        sub_point_4: `Om inte Oparko har agerat grovt oaktsamt eller avsiktligt, är Oparko inte ansvarigt för att någon information görs tillgänglig för tredje part. Oparko använder vanliga säkerhetsåtgärder för att skydda data`,
    },
    Terms_main_point_16: {
        main_title: `Force majeure`,
        sub_point_1: `Oparko ansvarar inte för skador eller ansvar för haverier eller förseningar i implementeringen av villkoren i detta avtal som ett resultat av omständigheter utanför Oparkos rimliga kontroll.`,
    },
    Terms_main_point_17: {
        main_title: `Sekretess`,
        sub_point_1: `Användaren accepterar att Oparko rapporterar missbruk av tjänsterna, olaglig verksamhet, olagligt eller olämpligt beteende och/eller misstankar om detta till polisen eller andra behöriga myndigheter.`,
    },
    Terms_main_point_18: {
        main_title: `Lagval och tvister`,
        sub_point_1: `Detta avtal styrs av och måste tolkas i enlighet med dansk lag. Alla tvister, meningsskiljaktigheter eller anspråk som uppstår på grundval av eller i samband med detta avtal, eller något brott, uppsägning eller ogiltighet därav, måste i princip lösas genom frivillig överenskommelse mellan parterna. Om överenskommelse inte kan uppnås i första hand kommer en diskussion att föras mellan en representant vald av Användaren och Parkeringsföretaget. Om parterna inte kan komma överens kan tvisten handläggas av de danska domstolarna med tingsrätten i Århus som första instans. Denna klausul 19.1 gäller efter uppsägning eller utgång av detta avtal.`,
        sub_point_2: `Om en bestämmelse i detta avtal åsidosätts som ogiltig ska avtalet i övrigt bibehållas, och den ogiltiga bestämmelsen ersättas med en giltig bestämmelse, så långt det är möjligt.`,
        sub_point_3: `Parkeringskompagniet förbehåller sig rätten att ändra dessa allmänna villkor för användning av Parkeringskompagniet. Om detta inträffar ska Parkeringsföretaget omedelbart informera om detta och göra en ny version av de allmänna villkoren för användning av Oparko tillgänglig. Om Användaren sedan fortsätter att använda tjänsterna anses detta som ett godkännande av dessa ändringar. Oavsett detta förbehåller sig Parkeringskompagniet rätten att ändra priser och avgifter/avgifter till följd av höjningar av driftskostnader, andra kostnader samt höjningar av avgifter och avgifter m.m. De vid alla tillfällen gällande allmänna villkoren finns i appen eller på www.parkeringskompagniet.dk under rubriken "Kundservice".`,
    },
    Subscription_terms_heading: `PRENUMERATIONSVILLKOR`,
    Subscription_terms_1: {
        main_title: `När du skapar och använder Oparko Prenumerationsparkering accepterar du, förutom nedanstående, även de allmänna villkoren för användning av Oparko. Dessa villkor visas i Oparko-appen och på <a href="https://www.parkeringskompagniet.dk/oparko" class="text-pk-blue">www.parkeringskompagniet.dk/oparko</a>`,
        sub_point_1: `Genom att ingå avtal om abonnemangsparkering erhåller Användaren ett tidsbegränsat digitalt parkeringstillstånd.`,
    },
    Subscription_terms_2: {
        main_title: `Abonnemangsparkeringsavtalet gäller för valt antal abonnemangsparkeringsavtal, vilket ger dig rätt att parkera på samma antal parkeringsplatser vid vald parkering. För abonnemangsparkering är det ett giltighetsvillkor att fordonet är korrekt registrerat i Oparko. Korrekt och giltig registrering kräver att registreringsnumret är korrekt registrerat och att fordonet är korrekt registrerat på abonnemangsparkeringsprodukten. Det registrerade fordonet får parkera i parkeringsplatser som inte annars är reserverade. Detta gäller dygnet runt, alla dagar i veckan.`,
        sub_point_1: `Prenumerationsparkering kommer att synas under aktiv parkering, hyrda parkeringsplatser.`,
        sub_point_2: `Det är ett giltigt villkor för korrekt och giltig registrering att det registrerade fordonet har valts ut som favoritbil och visas korrekt registrerat på abonnemangsparkeringsprodukten under hyrda parkeringsplatser.`,
    },
    Subscription_terms_3: {
        main_title: `Abonnemangsparkeringsavtalet gäller för användning av en markerad parkeringsplats enligt "först till kvarn". Det är Med abonnemangsparkeringsavtalet erhåller användaren endast "flytande rätt" till en markerad parkeringsplats som inte är reserverad på annat sätt. För det fall att det inte finns tillgänglig markerad parkeringsplats kan Parkeringskompagniet inte hållas ansvarigt för detta, liksom brukaren inte har rätt till nedsättning av avgiften.`,
        sub_point_1: `Det faktum att Användaren inte använder abonnemangsparkering under hela den valda perioden innebär inte att Användaren har rätt till nedsättning av ersättningen.`,
    },
    Subscription_terms_4: {
        main_title: `Abonnemangsparkeringsavtalet träder i kraft från och med det datum som Användaren valt och löper under angiven tid. Abonnemangsparkeringsavtalet kan sägas upp omedelbart av Parkeringskompagniet utan ytterligare motivering. Användaren kan avboka tidigast i slutet av en köpt period. Uppsägning sker om Användaren inte förnyar eller förlänger Abonnemangsparkeringsavtalet. I det fall Användaren har valt automatisk förnyelse måste Användaren stoppa automatisk förnyelse för att kunna säga upp abonnemangsparkeringsavtalet.`,
        sub_point_1: `Användaren kan endast säga upp abonnemangsparkeringsavtalet när som helst fram till slutet av den valda perioden. I så fall kommer inga pengar att dras från Användarens betalkort förrän ett nytt abonnemangsparkeringsavtal skapas.`,
        sub_point_2: `Abonnemangsparkeringen gäller från valt datum och för vald period. Ett abonnemangsparkeringsavtal gäller därför inte nödvändigtvis från den 1:a till den 1:a i en månad, om inte Användaren har valt detta.`,
    },
    Subscription_terms_5: {
        main_title: `Användaren ansvarar hela tiden för att det finns ett giltigt abonnemangsavtal på fordonet som står uppställt på parkeringen. Det är också Användarens eget ansvar att tillhörande fordon registreras korrekt och adekvat på profilen. En besiktningsavgift kan tas ut om ett parkerat fordon inte har blivit korrekt och adekvat registrerat. Kontrollavgiften kan i efterhand inte efterges.`,
    },
    Subscription_terms_6: {
        main_title: `Innehållet i abonnemangsparkeringsavtalet, priser och eventuella särskilda villkor för Produktpaketen kommer alltid att anges på Webbplatsen och i Appen. Ersättningen betalas i förskott. Första gången vid ingående av abonnemangsparkeringsavtalet.`,
        sub_point_1: `I vissa fall kommer användaren att förses med en unik produktkod, som måste användas vid registreringen. I samband med att den unika produktkoden erhålls informeras Användaren om hur användaren ska bete sig i samband med betalningsskyldigheten och hur registrering av den unika produktkoden ska ske.`,
        sub_point_2: `För det fall att abonnemangsparkeringsavtalet antingen sägs upp, upphävs, ångras eller att den levererade unika produktkoden sägs upp eller spärras har Parkeringskompagniet rätt att använda de registrerade uppgifterna, inklusive användarens e-postadress mm. i form av pt. 17., för att göra en anmälan om detta.`,
    },
    Subscription_terms_7: {
        main_title: `Pristalsersättningen justeras varje år den 1 januari utan vidare varsel, där den justeras med nettoprisindex för oktober. föregående år, dock med minst 3 % (avrundat till närmaste hela krona) i förhållande till ersättningsutbetalningen föregående år. Indexjustering av ersättningen sker med utgångspunkt i den ersättning som gäller närmast före justeringen, oavsett om denna ersättning kan ha uppkommit genom justering på annat sätt än genom indexjustering.`,
        sub_point_1: `Utöver ovanstående indexreglering förbehåller sig Parkeringskompagniet rätten att ändra priser och avgifter/avgifter till följd av höjningar av driftskostnader, andra kostnader och höjningar av avgifter och avgifter m.m. Sådana ändringar träder i kraft tidigast i slutet av innevarande månad + 1 månad efter att användaren har informerats om sådana ändringar.`,
    },
    Subscription_terms_8: {
        main_title: `Parkeringskompagniet reserverar sig för prisfel, prisändringar och uppdateringsfel.`,
    },
    Subscription_terms_9: {
        main_title: `Om Användaren har valt automatisk förnyelse är det Användarens eget ansvar att "stoppa" den automatiska förnyelsen genom att säga upp Abonnemangsparkeringsavtalet. Att betalning stoppas är inte att betrakta som en uppsägning. Betalning uppbärs tills Abonnemangsparkeringsavtalet sägs upp.`,
    },
    Subscription_terms_10: {
        main_title: `All trafik och parkering sker på användarens egen risk. Parkeringskompagniet ansvarar inte för stöld eller skada som kan orsakas av brukarens fordon, oavsett orsaken till detta.`,
    },
    Subscription_terms_11: {
        main_title: `Abonnemangsparkeringsavtalet får inte utan skriftligt medgivande från Parkeringskompagniet användas till annat än att parkera det korrekt registrerade fordonet, med en registrerad totalvikt på max. 3 500 kg.`,
    },
    Subscription_terms_12: {
        main_title: `Med detta Abonnemangsparkeringsavtal görs användaren uttryckligen medveten om att ingående av detta Abonnemangsparkeringsavtal inte ger användaren rätt att avbryta pålagda kontrollavgifter. Abonnemangsparkeringsavtalet gäller först från det att registreringsnumret är korrekt registrerat. Abonnemangsparkeringsavtalet gäller endast för det fordon som är korrekt registrerat.`,
        sub_point_1: `Abonnemangsparkeringsavtalet ger endast rätt att parkera på det område som framgår av abonnemangsparkeringsavtalet, på de markerade parkeringsplatserna där det inte framgår att det finns särskilda regler som gäller för parkering i dessa parkeringsplatser. Uppmärksamhet uppmärksammas på att abonnemangsparkeringsavtalet inte är ett giltigt parkeringstillstånd för parkering i parkeringsplatser som är reserverade.`,
    },
    Subscription_terms_13: {
        main_title: `Vid användarens väsentliga avtalsbrott kan Parkeringsföretaget säga upp Abonnemangsparkeringsavtalet med omedelbar verkan. Väsentligt brott innefattar, men är inte begränsat till, utebliven betalning av den överenskomna ersättningen, brott mot bestämmelserna i detta avtal samt eventuella brott mot parkeringsbestämmelserna. Parkeringsföretaget äger vid väsentlig överträdelse rätt att påföra besiktningsavgift och eventuellt ta bort eller beslagta brukarens fordon tills förfallna avgifter och kostnader är betalda. Användaren har inte tillgång till kvittning även om det råder/föreligger meningsskiljaktigheter om kravet.`,
        sub_point_1: `Parkeringskompagniet kan skriftligen säga upp abonnemangsparkeringsavtalet med 1 månads varsel för det fall underhåll, ombyggnad, renovering mm måste utföras. förhållanden som gör att Parkeringskompagniet permanent inte längre kan använda parkeringsplatsen.`,
    },
    Subscription_terms_14: {
        main_title: `Enligt den danska konsumentavtalslagen (lag nr 1457 av den 17 december 2013) har Användaren rätt att frånträda Avtalet genom att meddela Parkeringskompagniets kundtjänst inom 14 dagar efter godkännande av registreringen. För att utnyttja ångerrätten ska avbeställningsbeskedet vara Parkeringskompagniet tillhanda senast den 14:e dagen då betalningen registreras hos Oparko.`,
        sub_point_1: `Om tidsfristen går ut på en allmän helgdag, lördag, grundlagsdag, julafton eller nyårsafton förlängs fristen till följande vardag`,
        sub_point_2: `För att utöva ångerrätten måste Användaren skicka ett e-postmeddelande eller ett brev till oss där Användaren skriver att köpet/reservationen är makulerad. Var uppmärksam på att inträde på parkeringen/auktorisera köpet i appen eller www.parkeringskompagniet.dk anses acceptera att parkeringen har påbörjats och ett godkännande av att 14 dagars ångerrätt frånsägs. Om du sedan ångrar ditt köp i appen eller www.parkeringskompagniet.dk reduceras din återbetalning med en proportionell del av totalpriset för parkeringen under den köpta perioden, baserat på antalet dagar/timmar/minuter fordonet har ställts tills Parkeringskompagniet tagit emot registreringen om utnyttjande av ångerrätten`,
    },
    Subscription_terms_15: {
        main_title: `Eventuella tvister mellan Parkeringsföretaget och Användaren angående förståelsen av detta avtal måste avgöras av ordinarie domstolar som tillämpar dansk lag. Domstolen i Århus har kommit överens om att vara behörig.`,
    },
    Subscription_terms_16: {
        main_title: `Användaren har inte rätt att hyra ut parkeringsbåset i andra hand. Överträdelse av detta anses vara brott, jfr punkt 13.`,
    },
    Subscription_terms_17: {
        main_title: `Parkeringskompagniet är parkeringsförvaltare på uppdrag av platsägaren/platsägarens fastighetsförvaltare. Alla förfrågningar ang detta avtal ska göras till Parkeringskompagniet. Om Parkeringskompagniet upphör att vara parkeringsadministratör övergår avtalet till en ny parkeringsadministratör.`,
    },
    Subscription_terms_18: {
        main_title: `När det gäller lagring och behandling av personuppgifter, vänligen hänvisa till den vid var tid gällande datapolicyn som finns på <a href="www.parkingskompagniet.dk/datapolitik/" class="text-pk-blue">www.parkingskompagniet.dk/datapolitik/</a>. Om uppgifterna ska raderas anses detta som uppsägning av abonnemangsparkeringsavtalet. Detta eftersom Parkeringskompagniet i dessa fall inte kan upprätthålla avtalet utan nämnda uppgifter.`,
    },
    Subscription_terms_footer_heading_1: `OBS att Användaren har en skyldighet att hålla informationen som registreras i Oparko uppdaterad. Det är Användaren ska meddela Parkeringskompagniet vid ändring av adress, e-post m.m. eller möjligen registreringsnummer för fordonet som ska användas i Oparko. Om detta inte efterlevs har Parkeringsföretaget rätt att radera redan registrerade uppgifter och säga upp abonnemangsparkeringsavtalet för att följa gällande personuppgiftslagstiftning.`,
    Subscription_terms_footer_heading_2: `Parkingkompaniet ApS`,
    Footer_contact_info: {
        contact_title: `Kontaktinformation`,
        company_name: `Parkingkompaniet ApS`,
        address_line: `Trindsøvej 4, 8000 Aarhus`,
        cvr: `CVR: 37127485`,
        email: `E-post: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>`,
        tel: `Tel.nr: <a href="tel:+ 45 88 74 31 94" class="text-pk-blue">+ 45 88 74 31 94</a>`,
        website: `<a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a>`,
        updatedAt: `Reviderad on 16.11.2022`,
    },

    // Act as user
    'Take Over': 'Ta över användare',

    // Old Terms

    Terms_title: '',
    Terms_heading: '',
    Terms_last: '',

    Terms_1: {
        para_1: '',
        para_2: '',
        para_3: '',
        para_4: '',
        link_1: '',
        link_2: '',
    },

    Terms_2: {
        heading: '',
        para_1: '',
        para_2: '',
        para_3: '',
        link_1: '',
    },

    Terms_3: {
        heading: '',
        para_1: '',
        ul: {
            heading: '',
            list: ['♦ ', '♦ ', '♦ ', '♦ ', '♦ ', '♦ ', '♦ ', '♦ '],
        },
    },

    Terms_4: {
        heading: '',
        para_1: '',
        row_1: {
            heading: '',
            para_1: '',
            para_2: '',
            para_3: '',
        },

        row_2: {
            heading: '',
            para_1: '',
        },

        row_3: {
            heading: '',
            para_1: '',
            para_2: '',
            para_3: '',
            link: '',
        },

        row_4: {
            heading: '',
            link: '',
        },

        row_5: {
            heading: '',
            para_1: '',
        },

        row_6: {
            heading: '',
            para_1: '',
        },

        row_7: {
            heading: '',
            link: '',
        },
    },
    ReceptionUser: {
        countryError: 'Landskod har inte valts',
        registrationError: 'Registreringsnummer. inte har valts',
        emailError: 'E-post har inte valts',
        phoneNumberError: 'Telefonnummer har inte valts',
        acceptError: 'Godkännandevillkor har inte valts',
        countryCodeError: 'Landskod har inte valts',
    },

    'Custom end date': 'Välj sluttiden själv',
    'Max time in min (0 is no limit)': 'Maxtid i minuter (måste vara över 0)',

    ConfirmPermit: {
        lineOne: 'En gästparkering har nu anlagts kl',
        for: 'för',
        on: 'på',
        at: 'på',
        lineTwo: 'som gäller t.o.m',
    },
    'Receive SMS?': 'Ta emot SMS?',
    'Guest Parking has been created!': 'Gästparkering har skapats!',
    'A Guest parking permit has now been created':
        'Ett gästparkeringstillstånd har nu skapats',

    // Entities
    Reception: 'Reception',
    HotelReception: 'Hotel receptionen',
    EmployeeReception: 'Anställd Mottagning',
    TimesReception: 'Times reception',
    HotelAdmin: 'Hotelladministratör',
    ConfirmationReception: 'Bekräftelsemottagning',
    HotelReceptionConfirmation: 'Hotellreceptionsbekräftelse',
    StayNTouchReception: 'Stay-N-Touch-mottagning',
    APIPartner: 'API-partner',
    SMSConfirmationReception: 'SMS-bekräftelsemottagning',
    Guest_parking_created:
        'En gästparkering för {{regNo}} har skapats, giltig till och med {{date}}.',
    Enter_registration_number: 'Ange registreringsnummer',
    Confirm: 'Bekräfta',
    Clear_all: 'Rensa allt',

    View: 'Se',
    Edit: 'Redigera',
    AppFines: 'AppFines',
    AppPermits: 'AppPermits',
    NOT_DEFINED: 'INTE DEFINIERAD',
    SELF_TICKETING: 'SELF_TICKETING',
    COMBI: 'KOMBI',
    P_WARDEN: 'P_WARDEN',
    CAMERA_CONTROL: 'CAMERA_CONTROL',
    Partner: 'Partner',
    Private: 'Privat',
    Company: 'Företag',
    'Not Handled': 'Ej hanterad',
    'Not handled': 'Ej hanterad',
    Approved: 'Godkänd',
    Rejected: 'avvisade',
    Deleted: 'raderade',
    Resident: 'Bosatt',
    Permanent: 'Permanent',
    License: 'Licens',
    Reminder: 'Påminnelse',
    Ended: 'Avslutade',
    Foreign: 'Utländsk',
    Paused: 'Pausad',
    All: 'Allt',
    'Other - write a comment in the note':
        'Övrigt - skriv en kommentar i lappen',
    'Outside Booth': 'Utanför montern',
    'No permission to the area': 'Inget tillstånd till området',
    'Parking disc exceeded': 'Parkeringsskivan har överskridits',
    'Parking or full stop on prohibited area':
        'Parkering eller punkt pa forbjudet omrade',
    'Parking on fire access road': 'Parkering vid brandtillfartsväg',
    'Handicapped parking without permission':
        'Handikappparkering utan tillstand',
    'Guest Parking': 'Gästparkering',
    'Paid ticket': 'Betald biljett',
    'Employee paid ticket': 'Anställd betald biljett',
    'Employee upper basement': 'Anställd övre källaren',
    'Employee lower basement': 'Anställd lägre källare',
    'Debt collection - Paragraph': 'Inkasso - Stycke',
    'Debt collection - Paragraph - installment agreement with demand':
        'Inkasso - Paragraf - avbetalningsavtal med krav',
    'Debt collection - Paragraph - with demand':
        'Inkasso - Paragraf - med efterkrav',
    'Debt collection - Liquidation': 'Inkasso - Likvidation',
    'Debt collection - Liquidation - installment agreement with demand':
        'Inkasso - Likvidation - avbetalningsavtal med krav',
    'Complaint Council': 'besvarsnämnd',

    // Subscription
    Next: 'Nästa',
    Previous: 'Föregående',
    Subscription: 'Prenumeration',
    Submit: 'Skicka in',
    Subscribers: 'Prenumeranter',
    'Already subscribed': 'Prenumererar redan',
    'E.g.': 'T.ex.',
    '12th Star Avenue': 'Stjärnväg 12',
    'Step 1: Which parking lot do you want to park at?':
        'Steg 1: Vilken parkeringsplats vill du parkera på?',
    'Step 2: Which license plate does your car have?':
        'Steg 2: Vilken registreringsskylt finns på din bil?',
    'Step 3: When should your subscription start?':
        'Steg 3: När ska ditt abonnemang börja?',
    'Step 4: Your monthly price is based on the selected parking lot, and will be:':
        'Steg 4: Ditt månatliga pris baseras på den valda parkeringsplatsen och är:',
    'Step 5: To be allowed to create a subscription for this private parking lot, you must enter a pin code below. Next, click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Steg 5: För att få skapa ett abonnemang för denna privata parkeringsplats måste du ange en pinkod nedan. Klicka sedan på Skapa för att gå till betalningssidan. När du är klar kommer du att omdirigeras tillbaka till vår sida, där du kan hitta din prenumeration i tabellen.',
    'Step 5:  Click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Steg 5:  Klicka på Skapa för att gå till betalningssidan. När du är klar skickas du tillbaka till vår sida, där du kan hitta din prenumeration i tabellen.',
    'Below you can below see the available days, times and prices':
        'Du kan nedan se tillgängliga dagar, tider och priser',
    'Subscription Info': 'Prenumeration Info',
    'Monthly price': 'Månadspris',
    'Last recurring payment date': 'Sista återkommande betalningsdatum',
    'Car Info': 'Bilinfo',
    'Enter registration number': 'Ange nummerskylt',
    'Buy monthly subscription': 'Köp månadsabonnemang',
    'Buy short ticket': 'Köp en enkelbiljett',
    'The price is': 'Priset är',
    'kr pr. month, but the price on specific parking spots may vary':
        'kr pr. månad, men priset på specifika parkeringsplatser kan variera',
    'Registration number': 'Registreringsnummer',
    'PIN code': 'PIN-kod',
    'Email id already registered with us':
        'E-postadressen är redan registrerad hos oss',
    'Buy ticket': 'Köp biljett',
    'Get ticket': 'Få biljett',
    'Permit id': 'Tillstånd id',
    'Last payment': 'Sista betalningen',
    'Price per period': 'Pris per period',
    'Stop subscription': 'Sluta prenumerera',
    'Price: 500 kr. per month (normal price is 625 kr. per month - The discount is active for the rest of 2023)':
        'Pris: 500 kr. per månad (normalt pris är 625 kr. per månad) - Rabatten är aktiv under resten av 2023)',
    'Payment Options': 'Betalningsmöjligheter',

    // FAQs
    'How do I change the numberplate on an existing permit?': `Hur ändrar jag nummerskylten på ett befintligt tillstånd?`,
    'How do I extend a permit?': 'Hur förlänger jag ett tillstånd?',
    "What do I do if something doesn't work?": `Vad gör jag om något inte fungerar?`,
    'How often do you pay out customer shares?': `Hur ofta betalar ni ut kundandelar?`,
    'Why am I being paid for a parking fee that is several years old now?': `Varför får jag betalt för en avgift som är flera år gammal nu?`,
    'Status and their meanings': 'Status och deras betydelser',

    answer_5: {
        paragraph: `På sidan 'Tillstånd' skriver du in bilens nummerskylt i sökfältet och trycker på enter/Sök. Klicka på relevant behörighet i listan nedan. Byt till det nya registreringsnumret i fältet 'Reg.nr'. Du kontrollerar sedan att parkeringsplats, slutdatum och resten ser rätt ut och klickar på 'Uppdatera'.`,
    },
    answer_6: {
        paragraph: `På sidan 'Tillstånd' skriver du in bilens nummerskylt i sökfältet och trycker på enter/Sök. Klicka på relevant behörighet i listan nedan. Byt till det nya registreringsnumret i fältet 'Reg.nr'. Du kontrollerar sedan att parkeringsplats, slutdatum och resten ser rätt ut och klickar på 'Uppdatera'.`,
    },
    answer_7: {
        paragraph: `Det hjälper ofta att logga ut och in igen, så vi rekommenderar alltid att du gör det först. Om det fortfarande inte fungerar är du alltid välkommen att skriva ett mejl till oss på service@oparko.com. För att vi ska kunna hjälpa dig snabbare är du varmt välkommen att skicka skärmdumpar på problemet (om det finns) Behöver du hjälp här och nu är vår telefon öppen måndag, tisdag och torsdag 11-15 samt fredag 11- 13. Ring 8874 3194.`,
    },
    answer_8: {
        paragraph: `I det samarbetsavtal/kontrakt vi har ingått hittar du svar på hur ofta avräkningar görs. Standard är en gång var tredje månad.`,
    },
    answer_9: {
        paragraph: `Hur snabbt en parkeringsavgift betalas efter utfärdandet kan variera från 'omedelbart' till 'aldrig'. Det betyder att parkeringsavgifter som utfärdades för månader eller år sedan kan komma att visas på din utbetalning idag, eftersom de har betalats sedan vi senast gjorde upp med dig.`,
    },
    answer_10: {
        paragraph1: `Aktiva: Parkeringsavgiften skapas i vårt system`,
        paragraph2: `Återhämtning: Parkeringsavgiften har skickats på hämtning av vår affärspartner och ett brev har skickats till bilens ägare`,
        paragraph3: `Betalt: När parkeringsavgiften är betald ändras dens status till betald`,
        paragraph4: `Avgjort: Kundandelen av parkeringsavgiften har betalats ut `,
        paragraph5: `Inställt: Parkeringsavgiften är inställd`,
        paragraph6: `Skyddad: Bilen ägs av en person som inte är bosatt i Danmark`,
    },

    // weekdays
    Mon: 'Mån',
    Tue: 'Tis',
    Wed: 'Ons',
    Thur: 'Tor',
    Fri: 'Fre',
    Sat: 'Lör',
    Sun: 'Sön',
    Monday: 'Måndag',
    Tuesday: 'Tisdag',
    Wednesday: 'Onsdag',
    Thursday: 'Torsdag',
    Friday: 'Fredag',
    Saturday: 'Lördag',
    Sunday: 'Söndag',

    Hour: 'Timme',
    hour: 'timme',
    hours: 'timmar',
    'half-hour': 'halvtimme',
    'half-hours': 'halvtimmar',
    Day: 'Dag',
    day: 'dag',
    days: 'dage',
    Week: 'Week',
    weeks: 'weeks',
    Month: 'Month',
    months: 'months',
    'Number of': 'Antal',
    First: 'Först',
    to: 'till',
    Free: 'Gratis',
    'Subscription per month': 'Prenumeration per månad',
    'No limit on': 'Ingen gräns på',
    After: 'Efter',

    // popup box - accepted
    accepted_title: 'Köp accepterat',
    accepted_description1: 'Ditt köp av parkeringsbiljett gick igenom',
    accepted_description2:
        'Om ett e-postmeddelande har angetts i föregående e-postfält kommer ett bekräftelsemail att skickas till den angivna e-postadressen.',
    accepted_description3: 'Som bekräftelse på tillståndet kan du använda:',
    accepted_description4: 'Klipp av debiterad banköverföring',
    accepted_description5: 'Klippning av MobilePay-betalning',
    accepted_description6: 'Skickade bekräftelsemail',
    accepted_description7:
        'Om det finns ytterligare frågor och/eller tvivel angående betalning, vänligen kontakta oss på:',
    accepted_description8: 'önstret kan nu stängas.',

    Ok: 'Ok',
    // popup box - successfull
    successfull_title: 'Välkommen till Oparko!',

    successfull_description: 'Din prenumeration är nu aktiv.',

    successfull_description1:
        'Du har fått all information om din prenumeration via e-post. Du kan visa och uppdatera uppgifter om din prenumeration direkt på plattformen genom att logga in.',

    successfull_description2:
        'Du kan nu stänga det här fönstret för att få åtkomst till ditt konto.',

    declined_title: 'Tyvärr var det ett fel med din betalning',

    declined_description:
        'Ditt prenumerationskonto har skapats, men det är inte aktivt. Det innebär att vi inte kommer att ta några betalningar från dig, och du kommer inte att ha ett aktivt tillstånd att parkera din bil.',

    declined_description1:
        'Du har fått all nödvändig information för att logga in på vår plattform via din e-post. På vår plattform kan du hitta betalningslänken för att försöka göra en annan betalning om du önskar.',

    declined_description2:
        'Alternativt kan du använda länken nedan för att försöka igen:',

    declined_description3: 'Eller kontakta Oparko Support här:',

    declined_description4:
        'Om du har några frågor om betalningen, kan du kontakta oss på',

    ticket_service_disclaimer_1:
        'Observera att det inte är möjligt att stoppa ditt parkeringstillstånd i förtid. Det går inte att förlänga ditt parkeringstillstånd, vill du behålla det måste du köpa ett nytt tillstånd när det första har gått ut. Vidare är det inte möjligt att återkalla ett tillstånd när det väl har skapats.',
    ticket_service_disclaimer_2:
        'Vi uppmärksammar särskilt att det är partens fulla ansvar att lämnade uppgifter är korrekta. Parkeringskompagniet frånsäger sig samtidigt ansvar för eventuella fel vid tillkomsten. När du köper en parkeringsbot godkänner du även våra villkor. Du samtycker därför även till vår personuppgiftspolicy.',
    subscription_service_disclaimer:
        'Vi uppmärksammar särskilt att det är partens fulla ansvar att lämnade uppgifter är korrekta. Parkeringskompagniet frånsäger sig samtidigt ansvar för eventuella fel vid tillkomsten. När du köper ett abonnemang godkänner du även våra villkor. Du samtycker därför även till vår personuppgiftspolicy',
    subscription_service_disclaimer_2: `Abonnemangsparkeringsavtalet ger endast rätt att parkera på det område som framgår av abonnemangsparkeringsavtalet, på de markerade parkeringsplatserna där det inte framgår att det finns särskilda regler som gäller för parkering i dessa parkeringsplatser. Uppmärksamhet uppmärksammas på att abonnemangsparkeringsavtalet inte är ett giltigt parkeringstillstånd för parkering i parkeringsplatser som är reserverade.`,

    // Months
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'Maj',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Okt',
    Nov: 'Nov',
    Dec: 'Dec',

    // redirectFromPK
    'Important announcement': 'Viktig uppdatering',
    'Welcome! You have landed here because Parkeringskompagniet has now become':
        'Välkommen! Du har landat här för att Parkeringskompagniet nu har blivit',
    'We have upgraded to a new management platform to offer you an even better user experience':
        'Vi har uppgraderat till en ny hanteringsplattform för att erbjuda dig en ännu bättre användarupplevelse.',
    'What should you do now?': 'Vad ska du göra nu?',
    'Your existing information is automatically transferred. You just have to log in from the new platform now':
        'Din befintliga information överförs automatiskt. Du behöver helt enkelt logga in på den nya plattformen från och med nu',
    'New Functions': 'Nya egenskaper',
    'The new platform can do everything you are used to, plus much more!':
        'Den nya plattformen kan göra allt du är van vid, plus mycket mer!',
    'Do you have questions? Contact us at service@oparko.com':
        'Har du frågor? Kontakta oss på service@oparko.com',
    'E-mail at': 'E-mail till',
    'Call at': 'Ring till',
    'Phone hours:': 'Telefontider:',
    'Go to new admin platform': 'Gå till ny adminplattform',

    // Pay fine
    'How to appeal': 'How to appeal',
    'Get documentation': 'Get documentation',
    'Complaint form': 'Complaint form',
    'Select a reason': 'Select a reason',
    Appeal: 'Appeal',
    'Submit an appeal': 'Submit an appeal',
    'Pay fine': 'Pay fine',
    'License plate': 'License plate',
    'Fine id': 'Fine id',
    retrieve_fine_documentation_text:
        "Retrieve fine documentation. Note that not all fines will result in documentation. The 'Fine id' is the id that comes with your fine when you receive it in the mail.",
    Subject: 'Subject',
    'Valid permit': 'Valid permit',
    'Type in complaint here': 'Type in complaint here',
    Attachments: 'Attachments',
    'Select files': 'Select files',
    'Select file': 'Select file',
    complaint_form_text:
        'All inquiries regarding parking charges (complaints, payments, reminders or the like) are only received in writing using the form below. It is important that we receive all information in writing.',
    'Fine documentation': 'Fine documentation',
    Price: 'Price',
    Offense: 'Offense',
    Location: 'Location',
    Continue: 'Continue',
    Topic: 'Topic',
    'Why you received a control fee': 'Why you received a control fee',
    'Incorrect Fine ID or Reg no. Please check again!':
        'Felaktigt Avgifts-ID eller Regnr. Försök igen tack!',
    pay_fine_documentation_error_message: `Unable to retrieve documentation. Check if 'Fine id' and 'License plate' are correct.`,

    // report pdf
    'The amount is paid to the account details below':
        'Beloppet betalas till kontouppgifterna nedan',
    'Parking reading - Subscription (ready)':
        'Parkeringsläsning - Prenumeration (klar)',
    'The Address': 'Adressen',
    'CREDIT NOTE': 'KREDITNOT',
};

export default svenska;
