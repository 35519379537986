import { apiBase } from 'api/api';
import { downloadReport } from 'util/downloadReport';
import { ApiResponse } from 'api/interface/api';
import {
    GetPermitRequest,
    GetPermitResponse,
    GetListOfPermitsRequest,
    GetListOfPermitsResponse,
    GetPermitTypesRequest,
    GetPermitTypesResponse,
    CreatePermitRequest,
    CreatePermitResponse,
    UpdatePermitRequest,
    UpdatePermitResponse,
    Permit1,
} from 'api/interface/permit';

const getPermit = async (req: GetPermitRequest): Promise<GetPermitResponse> => {
    const res = await apiBase.get(`/permit/${Number(req.id)}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.permit,
    } as GetPermitResponse;
};

const getListOfPermits = async (
    req: GetListOfPermitsRequest
): Promise<GetListOfPermitsResponse> => {
    const res = await apiBase.get(
        `/v2/permits?from=${req.from}&to=${req.to}&status=${req.status}&limit=${req.limit}&offset=${req.offset}&sort_order=${req.sortorder}&sort_col=${req.sortcol}&search_term=${req.search_term}&parkinglot_ids=${req.parkinglot_ids}&type=${req.type}&organisation_ids=${req.organisation_ids}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            data: [],
            total: 0,
        } as GetListOfPermitsResponse;
    }

    return {
        error: null,
        statusCode: res.status,
        data: res.data.data.map((item: Permit1) => ({
            permit: {
                id: item.permit.id,
                regno: item.permit.regno,
                type: item.permit.type,
                status: item.permit.status,
                note: item.permit.note,
                start_date: item.permit.start_date,
                end_date: item.permit.end_date,
            },
            user: {
                username: item.user.username,
            },
            address: {
                address_name: item.address.address_name,
                city: item.address.city,
            },
            organisation: {
                name: item.organisation.name,
            },
        })),
        total: res.data.total,
    } as GetListOfPermitsResponse;
};

const createPermit = async (
    req: CreatePermitRequest
): Promise<CreatePermitResponse> => {
    const res = await apiBase.post('/permit', {
        ...req,
    });

    if (res.status !== 200) {
        return {
            error: res.data.error.message,
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data,
    } as CreatePermitResponse;
};

const updatePermit = async (
    req: UpdatePermitRequest
): Promise<UpdatePermitResponse> => {
    const res = await apiBase.put(`/permit/${req.id}`, {
        ...req,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as UpdatePermitResponse;
};

const generatePermitReport = async (
    req: GetListOfPermitsRequest
): Promise<ApiResponse> => {
    const res = await apiBase.get(
        `/permit/report?from=${req.from}&to=${req.to}&status=${req.status}&sort_order=${req.sortorder}&sort_col=${req.sortcol}&search_term=${req.search_term}&parkinglot_ids=${req.parkinglot_ids}&show_active=${req.show_active}&type=${req.type}&organisation_ids=${req.organisation_ids}`,
        {
            responseType: 'blob',
        }
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    downloadReport(res);

    return {
        error: null,
        statusCode: res.status,
    } as ApiResponse;
};

const getPermitTypes = async (
    req: GetPermitTypesRequest
): Promise<GetPermitTypesResponse> => {
    const res = await apiBase.get(
        `/permit/types?include_all=${req.include_all}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.permit_types,
    } as GetPermitTypesResponse;
};

export const frontofficePermitApi = {
    getPermit,
    getListOfPermits,
    createPermit,
    updatePermit,
    generatePermitReport,
    getPermitTypes,
};
