import { apiBase } from 'api/api';
import { downloadReport } from 'util/downloadReport';
import { ApiResponse } from 'api/interface/api';
import {
    SaveCommentForFineRequest,
    SaveCommentForFineResponse,
    GetListOfFinesRequest,
    GetListOfFinesResponse,
    GetFinesWithFilterParamsResponse,
    GetDailyFinesStatisticsParamsRequest,
    GetDailyFinesStatisticsParamsResponse,
    getFineRequest,
    getFineResponse,
    CancelFineRequest,
    CancelFineResponse,
    GetFineStatusesResponse,
    GetFinesWithFilterParamsRequest,
    Fine1,
} from 'api/interface/fine';
import {
    GetListOfParkingLotsRequest,
    GetListOfParkingsResponse,
} from 'api/interface/parking';

const getListOfParkingLots = async (
    req: GetListOfParkingLotsRequest
): Promise<GetListOfParkingsResponse> => {
    const res = await apiBase.get(
        `/parkinglots?limit=${req.limit}&offset=${
            req.offset
        }&organisation_ids=${req.organisation_ids}&show_active=${
            req.active
        }&search_term=${req.search_term}&sort_order=${
            req.sort_order
        }&sort_col=${req.sort_col}&minimal=${
            req.minimal
        }&show_spots_and_groups=${req.showSpotsAndGroups || false}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: {
                total: 0,
                data: [],
            },
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: {
            data: res.data.parking_lots,
            total: res.data.total,
        },
    } as GetListOfParkingsResponse;
};

const getListOfFines = async (
    req: GetListOfFinesRequest
): Promise<GetListOfFinesResponse> => {
    const res = await apiBase.get(
        `/v2/fines?from=${req.from}&to=${req.to}&status=${req.status}&limit=${req.limit}&offset=${req.offset}&sort_order=${req.sortorder}&sort_col=${req.sortcol}&organisation_ids=${req.organisation_ids}&search_term=${req.search_term}&parkinglot_ids=${req.parkinglot_ids}&show_cancelled_fines=${req.show_cancelled_fines}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            data: [], // Return an empty array for data
            total: 0, // Set total to 0 in case of an error
        } as GetListOfFinesResponse;
    }

    return {
        error: null,
        statusCode: res.status,
        data: res.data.data.map((item: Fine1) => ({
            fine: {
                id: item.fine.id,
                qr_code: item.fine.qr_code,
                reg_no: item.fine.reg_no,
                country_code: item.fine.country_code,
                created_at: item.fine.created_at,
                status: item.fine.status,
            },
            address: {
                id: item.address.id,
                address_name: item.address.address_name,
                city: item.address.city,
            },
            user: {
                username: item.user.username,
            },
            organisation: {
                name: item.organisation.name,
            },
        })),
        total: res.data.total, // Assuming `total` is returned as a root-level field in the response
    } as GetListOfFinesResponse;
};

const getDailyFinesStatistics = async (
    req: GetDailyFinesStatisticsParamsRequest
): Promise<GetDailyFinesStatisticsParamsResponse> => {
    const res = await apiBase.get(
        `/fine/statistics/daily?from=${req.from}&to=${req.to}`
    );
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: {},
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.result.keys,
    } as GetDailyFinesStatisticsParamsResponse;
};

const getFinesWithFilterParams = async (
    req: GetFinesWithFilterParamsRequest
): Promise<GetFinesWithFilterParamsResponse> => {
    const res = await apiBase.get(
        `/fines?from=${req.from}&to=${req.to}&status=${req.status}&limit=${req.limit}&offset=${req.offset}&sort_col=${req.sortcol}&sort_order=${req.sortorder}`
    );

    if (res.status !== 200) {
        return {
            payload: [],
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.fines,
    } as GetFinesWithFilterParamsResponse;
};

const getFine = async (req: getFineRequest): Promise<getFineResponse> => {
    const res = await apiBase.get(`/fine/${req.id}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.fine,
    } as getFineResponse;
};

const generateFineReport = async (
    req: GetListOfFinesRequest
): Promise<ApiResponse> => {
    const res = await apiBase.get(
        `/fine/generate-report?from=${req.from}&to=${req.to}&status=${req.status}&sort_order=${req.sortorder}&sort_col=${req.sortcol}&search_term=${req.search_term}&organisation_ids=${req.organisation_ids}&parkinglot_ids=${req.parkinglot_ids}&show_cancelled_fines=${req.show_cancelled_fines}`,
        {
            responseType: 'blob',
            timeout: 14000,
        }
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    downloadReport(res);

    return {
        error: null,
        statusCode: res.status,
    } as ApiResponse;
};

const saveCommentForFine = async (
    comment: SaveCommentForFineRequest[]
): Promise<SaveCommentForFineResponse> => {
    const res = await apiBase.post('/fine/comment', {
        comment,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as SaveCommentForFineResponse;
};

const cancelFine = async (
    req: CancelFineRequest
): Promise<CancelFineResponse> => {
    const res = await apiBase.post('/fine/cancel', {
        ...req,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as CancelFineResponse;
};

const getFineStatuses = async (): Promise<GetFineStatusesResponse> => {
    const res = await apiBase.get('/fine/status');
    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as GetFineStatusesResponse;
};

export const frontofficeFineApi = {
    getListOfParkingLots,
    getListOfFines,
    getFinesWithFilterParams,
    getFine,
    generateFineReport,
    saveCommentForFine,
    cancelFine,
    getDailyFinesStatistics,
    getFineStatuses,
};
