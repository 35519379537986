import { apiBase } from 'api/api';
import moment from 'moment';
import { downloadReport } from 'util/downloadReport';
import { ApiResponse } from 'api/interface/api';
import {
    CreateFineRequest,
    CreateFineResponse,
    UpdateFineRequest,
    UpdateFineResponse,
    UpdateFinesRequest,
    UpdateFinesResponse,
    GetFineRequest,
    GetFineResponse,
    SaveCommentForFineRequest,
    SaveCommentForFineResponse,
    SaveCommentForMultipleFinesRequest,
    SaveCommentForMultipleFinesResponse,
    GetListOfFinesRequest,
    GetListOfFinesResponse,
    BackofficeGetFinesWithFilterParamsRequest,
    BackofficeGetFinesWithFilterParamsResponse,
    GetFineStatusesResponse,
    SetPaidFineMailRequest,
    Fine1,
} from 'api/interface/fine';

const createFine = async (
    req: CreateFineRequest,
    userId: number
): Promise<CreateFineResponse> => {
    const data = new FormData();
    data.append('user_id', String(userId));
    data.append('status', req.payload.status.toString());
    data.append('country_code', req.payload.country_code.toString());
    data.append('wrong_doing_id', req.payload.wrong_doing_id.toString());
    data.append('note', req.payload.note.toString());
    data.append('internal_comment', req.payload.internal_comment.toString());
    data.append('reg_no', req.payload.reg_no.toString().trim());
    data.append('parkinglot_id', req.payload.parkinglot_id.toString());
    data.append('latitude', req.payload.latitude.toString());
    data.append('longitude', req.payload.longitude.toString());
    data.append('fine_origin_id', req.payload.fine_origin_id.toString());
    if (req.payload.comment.length > 0) {
        for (let i = 0; i < req.payload.comment.length; i++) {
            data.append('comment', JSON.stringify(req.payload.comment[i]));
        }
    }
    if (req.payload.start_date) {
        data.append('start_date', moment(req.payload.start_date).toISOString());
    }
    if (req.payload.end_date) {
        data.append('end_date', moment(req.payload.end_date).toISOString());
    }
    if (req.payload.arrayFile.length > 0) {
        req.payload.arrayFile.forEach((file) => {
            data.append('file', file);
            data.append(
                'timestamp',
                moment(file.lastModified)
                    .format('YYYY-MM-DD hh mm ss')
                    .toString()
            );
        });
    }
    const res = await apiBase.post('/backoffice/fine', data);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as CreateFineResponse;
};

const updateFine = async (
    req: UpdateFineRequest
): Promise<UpdateFineResponse> => {
    const res = await apiBase.put(`/backoffice/fine/${req.payload.id}`, {
        ...req.payload,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as UpdateFineResponse;
};

const updateFines = async (
    payload: UpdateFinesRequest
): Promise<UpdateFinesResponse> => {
    const res = await apiBase.put(`/backoffice/fines`, {
        ...payload,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as UpdateFinesResponse;
};

const getFine = async (req: GetFineRequest): Promise<GetFineResponse> => {
    const res = await apiBase.get(`/backoffice/fine/${req.id}`);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data.fine,
    } as GetFineResponse;
};

const saveCommentForFine = async (
    comment: SaveCommentForFineRequest[]
): Promise<SaveCommentForFineResponse> => {
    const res = await apiBase.post('/backoffice/fine/comment', {
        comment,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as SaveCommentForFineResponse;
};

const saveCommentForMultipleFines = async (
    Comments: SaveCommentForMultipleFinesRequest
): Promise<SaveCommentForMultipleFinesResponse> => {
    const res = await apiBase.post('/backoffice/fines/comment', {
        Comments,
    });

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as SaveCommentForMultipleFinesResponse;
};

const getListOfFines = async (
    req: GetListOfFinesRequest
): Promise<GetListOfFinesResponse> => {
    const res = await apiBase.get(
        `/v2/backoffice/fines?from=${req.from}&to=${req.to}&status=${req.status}&limit=${req.limit}&offset=${req.offset}&sort_order=${req.sortorder}&sort_col=${req.sortcol}&search_term=${req.search_term}&organisation_ids=${req.organisation_ids}&parkinglot_ids=${req.parkinglot_ids}`
    );

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            data: [], // Return an empty array for data
            total: 0, // Set total to 0 in case of an error
        } as GetListOfFinesResponse;
    }

    return {
        error: null,
        statusCode: res.status,
        data: res.data.data.map((item: Fine1) => ({
            fine: {
                id: item.fine.id,
                qr_code: item.fine.qr_code,
                reg_no: item.fine.reg_no,
                country_code: item.fine.country_code,
                created_at: item.fine.created_at,
                status: item.fine.status,
            },
            address: {
                id: item.address.id,
                address_name: item.address.address_name,
                city: item.address.city,
            },
            user: {
                username: item.user.username,
            },
            organisation: {
                name: item.organisation.name,
            },
        })),
        total: res.data.total, // Assuming `total` is returned as a root-level field in the response
    } as GetListOfFinesResponse;
};

const generateFineReport = async (
    req: GetListOfFinesRequest
): Promise<ApiResponse> => {
    const res = await apiBase.get(`/backoffice/fine/generate-report`, {
        params: req,
        paramsSerializer: (params) => {
            return new URLSearchParams(params).toString();
        },
        responseType: 'blob',
    });

    if (res.status !== 200) {
        if (res.status === 502) {
            return {
                error: 'Unable to generate report. The amount of permits were greater than 50000 - please apply filter to minimize the amount.',
                statusCode: res.status,
            };
        }
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    downloadReport(res);

    return {
        error: null,
        statusCode: res.status,
    } as ApiResponse;
};

const getFinesWithFilterParams = async (
    req: BackofficeGetFinesWithFilterParamsRequest
): Promise<BackofficeGetFinesWithFilterParamsResponse> => {
    const res = await apiBase.get(`/backoffice/statistics/fine_activity`, {
        params: req,
        paramsSerializer: (params) => {
            return new URLSearchParams(params).toString();
        },
        // paramsSerializer: {
        //     indexes: null, // no brackets at all
        // },
    });

    // const res = await apiBase.get(
    //     `/backoffice/statistics/fine_activity?from=${req.from}&to=${
    //         req.to
    //     }&status=${req.status}&limit=${req.limit}&offset=${req.offset}${
    //         req.organisation_ids
    //             ? `&organisation_ids=${req.organisation_ids}`
    //             : ''
    //     }&parkinglot_ids=${req.parkinglot_ids}&search_term=${req.search_term}`
    // );

    if (res.status !== 200) {
        return {
            payload: [],
            error: 'bad request',
            statusCode: res.status,
        };
    }

    return {
        error: null,
        statusCode: res.status,
        payload: res.data,
    } as BackofficeGetFinesWithFilterParamsResponse;
};

const getFineStatusOptions = async (): Promise<GetFineStatusesResponse> => {
    const res = await apiBase.get(`backoffice/fine/status`);
    if (res?.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
            payload: [],
        };
    }
    return {
        error: null,
        statusCode: res.status,
        payload: res.data.payload,
    } as GetFineStatusesResponse;
};

const sendPaidFineMail = async (
    req: SetPaidFineMailRequest
): Promise<ApiResponse> => {
    const res = await apiBase.post('backoffice/fine/resend-paid', req);

    if (res.status !== 200) {
        return {
            error: 'bad request',
            statusCode: res.status,
        };
    }
    return {
        error: null,
        statusCode: res.status,
    } as ApiResponse;
};

export const backofficeFineAPI = {
    updateFine,
    updateFines,
    getFine,
    createFine,
    saveCommentForFine,
    getListOfFines,
    getFinesWithFilterParams,
    generateFineReport,
    saveCommentForMultipleFines,
    getFineStatusOptions,
    sendPaidFineMail,
};
