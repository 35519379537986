import React, { createContext, useState } from 'react';

interface SidebarContextProps {
    currentOpenMenuItem: string[];
    setCurrentOpenItem: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SidebarContext = createContext<SidebarContextProps>({
    currentOpenMenuItem: [],
    setCurrentOpenItem: () => {},
});

export const SidebarProvider: React.FC = ({ children }) => {
    const [currentOpenMenuItem, setCurrentOpenItem] = useState<string[]>([]);

    return (
        <SidebarContext.Provider
            value={{
                currentOpenMenuItem,
                setCurrentOpenItem,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};
